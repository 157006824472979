import React, { Component } from 'react';
import './login.scss';
import LoadingAnimation from '../../components/loadinganimation/login';
import Cookies from 'universal-cookie';
import ErrorAlert from '../../components/alertmessages';
import Config from '../../data/SiteConfig';
import { isMobile } from 'react-device-detect';
import sHelpers from '../../modules/helpers/statichelpers';
import Slider from "react-slick";
import Header from '../../components/Header';
const homeslidersettings = {
    autoplay: true,
    dots: true,
    infinite: true,
    arrows: false,
    speed: 300,
    slidesToShow: 1,
    adaptiveHeight: false
};
//import { createBrowserHistory } from 'history';
//import jumpTo from '../../modules/navigation';
//const history = createBrowserHistory();

// Image Part
//const location = history.location;

let tableNo = '';
let serviceType = '';
let orgnationId = '';
let propertyId = '';
let outletCode = '';
let token = '';
let exmainGuestMobileNo = '';
let outletDetails = null;
let olDetail = {};
let validateOTP = false;
let validatePIN = true;
let pageSession = '';
let statsId = '';
// eslint-disable-next-line
let groupSession = false;
let sortedSliderImages='';
let urlPattern = '';
class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            mobileNo: '',
            orgId: 0,
            propertyId: '',
            outletCode: '',
            tableNo: '',
            serviceType: '',
            alertMessage: '',
            msgType: '',
            msgShow: false,
            guestName: '',
            sliderImages: [
                {
                    altName: "",
                    sortOrder: 0,
                    bannerType: 0,
                    height: 0,
                    imageName: "",
                    imagePathName: "",
                    primaryImageType: "",
                    secondaryImageType: "",
                    bannerName: "Slide 1",
                    primaryImageUrl: "/img/slider/login-df-lucid.jpg",
                    secondaryImageUrl: "",
                    status: 0,
                    width: 0
                }
            ],
            loggedIn: false,
            tableCheckedIn:false,
        };
    }

    componentWillMount() {


        // sessionStorage.removeItem("cartList");
        // sessionStorage.removeItem("taxCartList");
        // sessionStorage.removeItem("modifierCartList");
        // sessionStorage.removeItem("chargeCartList");
        // sessionStorage.removeItem("paymentInitiatedId");
        // sessionStorage.removeItem("paymentStatus");
        // sessionStorage.removeItem("vendorId");
        // sessionStorage.removeItem("vendorName");
        // sessionStorage.removeItem("order-instruction");

        // sessionStorage.removeItem("getCharges");
        // sessionStorage.removeItem("getTaxes");
        // sessionStorage.removeItem("getMenuGroups");
        // sessionStorage.removeItem("getModifierGroups");
        // sessionStorage.removeItem("getmenuItems");
        // sessionStorage.removeItem('otpReferenceId');
        // // sessionStorage.removeItem('user-outletDetails');
        // sessionStorage.removeItem('user-table-verified');
        // sessionStorage.removeItem("ebillUrl");
        // sessionStorage.removeItem("isPay");
        // sessionStorage.removeItem("posBillId");


        orgnationId = sessionStorage.getItem('user-orgnationid');
        propertyId = sessionStorage.getItem('user-propertyid');
        outletCode = sessionStorage.getItem('user-outletcode');
        tableNo = sessionStorage.getItem('user-tableno');
        serviceType = sessionStorage.getItem('user-servicetype');
        token = sessionStorage.getItem('user-token');
        statsId = sessionStorage.getItem('user-statsid');
        exmainGuestMobileNo = sessionStorage.getItem('main-guest-no') == null ? '' : sessionStorage.getItem('main-guest-no');
        outletDetails = sessionStorage.getItem('user-outletDetails');
        // Get PageSession
        pageSession = sessionStorage.getItem('login-page-session') == null ? 1 : parseInt(sessionStorage.getItem('login-page-session'));
        groupSession = sessionStorage.getItem('group-session') == null ? false : sessionStorage.getItem('group-session');
        urlPattern =   sessionStorage.getItem('url-pattern');

        if (Config.IsMobileAccess) {
            if (!isMobile) {
                this.props.history.push('/deviceerror' + urlPattern);
                return false;
                //////console.log('mobile');
            }
        }

        const sessionDt = sessionStorage.getItem("u-l-v-url-dt");
        if (!sessionDt) {
            this.props.history.push('/sessionerror' + urlPattern);
            return false;
        }
        if (!urlPattern) {        
            this.props.history.push('/qrcodeerror' + Config.defaultUrlPattern);
            return false;
          }
        //  this.props.history.push('/' + urlPattern);
        if (tableNo === undefined || tableNo === "" || tableNo === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (serviceType === undefined || serviceType === "" || serviceType === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (orgnationId === undefined || orgnationId === "" || orgnationId === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (propertyId === undefined || propertyId === "" || propertyId === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (outletCode === undefined || outletCode === "" || outletCode === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (token === undefined || token === "" || token === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (outletDetails === undefined || outletDetails === "" || outletDetails === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        else {
            olDetail = JSON.parse(outletDetails);
        }

        if (statsId === undefined || statsId === "" || statsId === null) {
            statsId = "0"
        }

        if (olDetail.enableGuestAppOrders === true) this.handleValidateTableStatus();

        this.getCMSDetails();
    }


    handleValidateTableStatus() {
        //  ////console.log(groupSession.toString());
        if (serviceType === "FINE-DINE") { // Table Check In      

            // let uParam = '?OutletId=' + propertyId + outletCode + '&ServiceType=' + serviceType;
            let postObjGuest = {
                OutletId: propertyId + outletCode,
                TableNumber: tableNo
            };

            fetch(Config.basePOSUrl + 'GetGuestTableStatus', {
                method: 'POST',
                headers: {
                    Authorization: "Bearer " + token,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(postObjGuest)
            })
                .then(res => res.json())
                .then(response => {
                    ////console.log("responsetable");
                    ////console.log(response);
                    if (response.errorCode === 0) {
                        this.setState({tableCheckedIn: response.response.guestTableStatus.isCheckedIn});
                        if (response.response.guestTableStatus.isCheckedIn) {
                            sessionStorage.setItem('main-guest-mask-no', response.response.guestTableStatus.maskMobileNo);
                            sessionStorage.setItem('main-guest-no', response.response.guestTableStatus.mainGuestMobileNo);
                            sessionStorage.setItem('main-guest-name', response.response.guestTableStatus.mainGuestName);
                            sessionStorage.setItem('ex-main-guest-no', response.response.guestTableStatus.mainGuestMobileNo);


                            if (olDetail.enableGroupOrdering) {
                                // group pin remove commented by dasprakash on 27-09-2023 jira-1493

                                // if (groupSession.toString() === "false") {
                                //     setTimeout(() => {
                                //         this.setState({ loading: false });
                                //         this.props.history.push('/jointable');
                                //     }, 500);
                                // }
                                // 
                                // group pin remove added by dasprakash on 27-09-2023 jira-1493
                                sessionStorage.setItem('group-session', true);
                                groupSession = true;
                                // 
                            }
                        } // End Check In  
                       // Else Condition Added by vijay on 01-02-2024 -JIra id -2078 -Testing Points -3
                        else{

                            sessionStorage.setItem('main-guest-mask-no', response.response.guestTableStatus.maskMobileNo);
                            sessionStorage.setItem('main-guest-no', response.response.guestTableStatus.mainGuestMobileNo);
                            sessionStorage.setItem('main-guest-name', response.response.guestTableStatus.mainGuestName);
                            sessionStorage.setItem('ex-main-guest-no', response.response.guestTableStatus.mainGuestMobileNo);
                        }
                    }
                })
                .catch(err => {
                    sessionStorage.removeItem("user-token");
                    sessionStorage.removeItem("user-mobileno");
                    this.showMessage('Table Status - Failed to fetch - ' + err, "danger");
                    ////console.log('Table Status - Failed to fetch - ' + err);
                });

        } // End Service Type - Fine Dine
    }

    handlechange = event => {

        const val = event.target.value;
        // const max = 10
        //const maxLength = max.toString().length - 1

        //const newVal = val < max ? val : parseInt(val.toString().substring(0, maxLength))
        let mNo = val.substr(0, 10);
        //////console.log(mNo);
        this.setState({ mobileNo: mNo });

        // ////console.log(mNo);
    };

    handleUpdateContactDetail() {
        let notes = {
            guestname: sessionStorage.getItem("guest-name") === null ? ' ' : sessionStorage.getItem("guest-name"),
            modetype: olDetail != null ? olDetail.enableGuestAppOrders === true ? 1 : 0 : 0
        }

        let posturlObj = {
            organizationId: parseInt(orgnationId),
            propertyId: propertyId,
            outletCode: outletCode,
            ServiceMode: serviceType,
            ContactNo: this.state.mobileNo,
            StatsId: parseInt(statsId),
            numofMenuAccess: 1,
            username: Config.linkUserName,
            password: Config.linkPassword,
            notes: JSON.stringify(notes)
        }
        // Update Contact Detail
        fetch(Config.baseAPIUrl + 'ClickURLDetail', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(posturlObj)
        })
            .then(res => res.json())
            .then(response => {
                ////console.log("responseddddddddddddd");
                ////console.log(response);
                // group pin added by dasprakash on 1-10-2023 jira-1490
                if (olDetail.validateGuestMobileNoWithOTP === true || olDetail.checkInWithTablePin === true) {
                    this.handleOnlyGroup();
                } else {
                    ////console.log("fdsfsfsf")
                    this.redirectToHome();
                }
                // this.redirectToHome();

            })
            .catch(err => {
                ////console.log(err);
                this.redirectToHome();
                //this.showMessage("CMS - Failed to fetch", "danger");
            });
    }

    handleOnlyGroup() {
        ////console.log('goup');
        setTimeout(() => {
            this.setState({ loading: false });

            this.props.history.push('/loginverification' + urlPattern,
                { mobileNo: this.state.mobileNo, validateOTP: validateOTP, validatePIN: validatePIN });
        }, Config.minMsgTimeout);
    }

    handleClick = (e) => {
        e.preventDefault();
        // group pin added by daspraksh on 28-09-2023 jira-1409
        const Details = JSON.parse(outletDetails);
        // ////console.log(olDetail.validateGuestMobileNoWithOTP); // Remove the quotes around the property name
        ////console.log(olDetail.enableGroupOrdering); // Remove the quotes around the property name
        // 

        this.setState({ loading: true });
        if (this.state.mobileNo === "") {
            this.showMessage("Please enter the valid mobile no.", "info");
            return false;
        }
        // group pin added by daspraksh on 28-09-2023 jira-1409
        if (Details.guestNameMandatory === true) {
            if (document.getElementById('input-guest-name').value === "") {
                this.showMessage("Please enter the name.", "info");
                return false;
            }
        }
        // 


        sessionStorage.setItem("guest-name", document.getElementById('input-guest-name').value === null ? '' : document.getElementById('input-guest-name').value);
        exmainGuestMobileNo = sessionStorage.getItem('main-guest-no') == null ? '' : sessionStorage.getItem('main-guest-no');
        ////console.log("olDetail.enableGroupOrdering");
        ////console.log(exmainGuestMobileNo);
        // Group Order Switch is off
        if (!olDetail.enableGroupOrdering) {
            if (serviceType === "FINE-DINE") { // Table Check In
                if(this.state.tableCheckedIn) {
                if (exmainGuestMobileNo !== '') {
                    if (exmainGuestMobileNo !== '0000000000') { // first time entry
                        if (exmainGuestMobileNo !== this.state.mobileNo) {
                            this.showMessage("Table is already checked in, Please scan some other table", "danger");
                            ////console.log("Table is already checked in, Please scan some other table", "danger");
                            setTimeout(() => {
                                this.setState({ loading: true });
                                // Redirect to Page
                                if (pageSession === Config.pageSession.Cart) {
                                    setTimeout(() => {
                                        ////console.log("mccc")
                                        this.setState({ loading: false });
                                        this.props.history.push('/cart' + urlPattern);
                                    }, 2000);
                                    return false;
                                }
                                else if (pageSession === Config.pageSession.PayBill) {
                                    setTimeout(() => {
                                        this.setState({ loading: false });
                                        this.props.history.push('/mybills' + urlPattern);
                                    }, Config.minMsgTimeout);
                                    return false;
                                }
                                else if (pageSession === Config.pageSession.MyOrders) {
                                    setTimeout(() => {
                                        this.setState({ loading: false });
                                        this.props.history.push('/myorders' + urlPattern);
                                    }, Config.minMsgTimeout);
                                    return false;
                                }
                                else if (pageSession === Config.pageSession.ChooseAddress) {
                                    setTimeout(() => {
                                        this.setState({ loading: false });
                                        this.props.history.push('/cart' + urlPattern);
                                    }, Config.minMsgTimeout);
                                    return false;
                                }
                                else {
                                    setTimeout(() => {
                                        this.setState({ loading: false });
                                        this.props.history.push('/home' + urlPattern);
                                    }, Config.minMsgTimeout);
                                    return false;
                                }

                            }, Config.averageMsgTimeout);
                            return false;
                        }

                    }
                }
            }
            }
        }
        // without verification  
        ////console.log("exmainGuestMobileNo")
        ////console.log(exmainGuestMobileNo)
        ////console.log("olDetail.validateGuestMobileNoWithOTP")
        ////console.log(olDetail.validateGuestMobileNoWithOTP)
        ////console.log("olDetail.checkInWithTablePin")
        ////console.log(olDetail.checkInWithTablePin)

        if (!olDetail.validateGuestMobileNoWithOTP && !olDetail.checkInWithTablePin) {
            if (exmainGuestMobileNo !== '') {
                if (exmainGuestMobileNo === this.state.mobileNo) {
                    ////console.log("dfsdfssdfd")
                    this.handleUpdateContactDetail();
                    // this.redirectToHome();
                    return false;
                }
                else {
                    validateOTP = false;
                    validatePIN = false

                    // group pin commented by dasprakash on 28-09-2023 jira-1490
                    // if (groupSession.toString() === "true") this.handleOnlyGroup();
                    // else this.handleUpdateContactDetail();

                    // group pin added by dasprakash on 28-09-2023 jira-1490
                    this.handleUpdateContactDetail()


                    //this.showMessage("Table is already checked in, Please scan some other table", "danger");
                    // this.setState({ loading: true });
                    // setTimeout(() => {
                    //     this.setState({ loading: false });
                    //     this.props.history.push('/' + urlPattern);
                    // }, 3000);
                    return false;
                }
            }
            else {
                ////console.log("else for")
                this.handleUpdateContactDetail();
                // this.redirectToHome();
                return false;
            }
        }

        else if (olDetail.validateGuestMobileNoWithOTP && olDetail.checkInWithTablePin) {
               // Added by vijay on 02-01-2023 Jira id -2078 - Allow to login if same guest scan the same table
            if (exmainGuestMobileNo !== '') {
                if (exmainGuestMobileNo === this.state.mobileNo) {                   
                    this.handleRedirectToWithoutVerification();                   
                    return false;
                }
            }

            validateOTP = true;
            validatePIN = true
        }
        else if (olDetail.validateGuestMobileNoWithOTP && !olDetail.checkInWithTablePin) {
           // Added by vijay on 02-01-2023 Jira id -2078 - Allow to login if same guest scan the same table
            if (exmainGuestMobileNo !== '') {
                if (exmainGuestMobileNo === this.state.mobileNo) {                   
                    this.handleUpdateContactDetail();                  
                    return false;
                }
            }
           
            validateOTP = true;
            validatePIN = false
        }
        else {

           // Added by vijay on 02-01-2023 Jira id -2078 - Allow to login if same guest scan the same table
            if (exmainGuestMobileNo !== '') {
                if (exmainGuestMobileNo === this.state.mobileNo) {                
                    this.handleUpdateContactDetail();              
                    return false;
                }
            }
            validateOTP = false;
            validatePIN = true
        }
        
        ////console.log(validateOTP)
        if (validateOTP) {
            let postObj = {
                MobileNoCountryCode: "91",
                MobileNo: this.state.mobileNo,
                GuestName: sessionStorage.getItem("guest-name") === null ? ' ' : sessionStorage.getItem("guest-name"),
                PropertyId: propertyId,
                OutletId: propertyId + outletCode
            }

            // ////console.log(postObj);
            fetch(Config.baseGuestUrl + 'GuestLogin', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify(postObj)
            })
                .then(res => res.json())
                .then(response => {                 
                    ////console.log(response);
                    let cookie = new Cookies();
                    if (response.errorCode === 0) {
                        //this.showMessage(response.message, "danger");
                        sessionStorage.setItem('otpReferenceId', response.response.otpReferenceId);
                        sessionStorage.setItem('otp-exp-duration', response.response.otpExpiryDuration);
                        cookie.set('user', response);
                        cookie.set('gmno', this.state.mobileNo);
                        ////console.log("majaf")
                        if (this.state.mobileNo !== exmainGuestMobileNo) {
                            setTimeout(() => {
                                this.setState({ loading: false });
                                this.props.history.push('/loginverification' + urlPattern,
                                    { mobileNo: this.state.mobileNo, validateOTP: validateOTP, validatePIN: validatePIN });
                            }, Config.minMsgTimeout);
                        }
                        else {
                            ////console.log("magic")
                            this.handleUpdateContactDetail();
                        }
                    }
                    else if (response.errorCode === 4) // Redirect to Verification - Already Sent
                    {
                        if (this.state.mobileNo !== exmainGuestMobileNo) {
                            this.showMessage(response.message, "danger");
                            sessionStorage.setItem('otpReferenceId', response.response.otpReferenceId);
                            sessionStorage.setItem('otp-exp-duration', response.response.otpExpiryDuration);
                            cookie.set('user', response);
                            cookie.set('gmno', this.state.mobileNo);

                            setTimeout(() => {
                                this.setState({ loading: false });
                                ////console.log("err");

                                this.props.history.push('/loginverification' + urlPattern,
                                    { mobileNo: this.state.mobileNo, validateOTP: validateOTP, validatePIN: validatePIN });
                            }, Config.minMsgTimeout);
                        }
                        else {
                            this.handleUpdateContactDetail();
                        }
                    }
                    else {
                        cookie.remove('user');
                        cookie.remove('gmno');
                        cookie.remove('otpReferenceId');
                        sessionStorage.removeItem('otp-exp-duration');
                        sessionStorage.removeItem('otpReferenceId');
                        this.showMessage(response.message, "danger");
                    }
                })
                .catch(err => {
                    //  ////console.log(err);
                    this.showMessage("An error occured", "danger");
                });
        }
        else {                 
            ////console.log("validatePIN");
            setTimeout(() => {
                this.setState({ loading: false });
                this.props.history.push('/loginverification' + urlPattern,
                    { mobileNo: this.state.mobileNo, validateOTP: validateOTP, validatePIN: validatePIN });
            }, Config.minMsgTimeout);
        }

        // this.props.submitAction(mobileno).then(res => {
        //     jumpTo('/loginverification')
        //     return res
        // })
        // .catch(error => {
        //     ////console.log(error.response.data.error.message)
        //     alert(error.response.data.error.message)
        //     return error
        // })
        //history.push('/loginverification');       
        // alert('msg');
        ///this.setState({loading : true});    
    };

    redirectToHome(exGuestLoggedIn = false) {
        if (serviceType === "FINE-DINE") { // Table Check In
            //sessionStorage.setItem('group-pin', '');

            // digital Menu
            // added by dasprakash on 07-10-2023 jira-1490
            if (olDetail.enableGuestAppOrders === false && olDetail.enableDigitalMenu === true) {
                sessionStorage.setItem('user-table-verified', true);
                sessionStorage.setItem('user-digitalMenu', true);
                // sessionStorage.setItem('user-mobileno', this.state.mobileNo);
                sessionStorage.setItem('user-loggedin', true);

                setTimeout(() => {
                    // this.props.history.push('/emhome');
                    this.props.history.push('/home' + urlPattern);
                }, 1000);
                return false;
            }
            //Condition added by vijay on 01-02-2024 Jira id-2078 - Allow to login if same guest scan the same table
            if (!exGuestLoggedIn){
            let postObj = {
                OutletId: propertyId + outletCode,
                GuestName: sessionStorage.getItem("guest-name") === null ? ' ' : sessionStorage.getItem("guest-name"),
                MobileNo: this.state.mobileNo,
                TableNumber: tableNo,
                GroupCode: ''
            }        
            fetch(Config.basePOSUrl + 'GuestCheckIn', {
                method: 'POST',
                headers: {
                    Authorization: "Bearer " + token,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(postObj)
            })
                .then(res => res.json())
                .then(response => {
                   
                    if (response.errorCode === 0) {
                        sessionStorage.setItem('user-table-verified', true);
                        sessionStorage.removeItem('otpReferenceId');
                        sessionStorage.setItem('user-mobileno', this.state.mobileNo);
                        // group pin commented by dasprakash on 05-10-2023 jira-1490
                        // sessionStorage.setItem('main-guest-no', this.state.mobileNo);
                        sessionStorage.setItem('user-loggedin', true);
                        // Temperary hard code for table pin switch value as false
                        olDetail.checkInWithTablePin = false;

                        // digital Menu
                        // commented by dasprakash on 07-10-2023 jira-1490
                        // if (olDetail.enableGuestAppOrders === false && olDetail.enableDigitalMenu === true) {
                        //     sessionStorage.setItem('user-table-verified', true);
                        //     sessionStorage.setItem('user-digitalMenu', true);
                        //     setTimeout(() => {
                        //         // this.props.history.push('/emhome');
                        //         this.props.history.push('/home');
                        //     }, 1000);
                        //     return false;
                        // }

                        // set Logged in Date                                                                    
                        let loggedIn = sHelpers.getFormattedDate(new Date());
                        sessionStorage.setItem('user-loggedin-date', loggedIn);


                        this.getGuestDetails();

                        // // Redirect to Page
                        // if (pageSession === Config.pageSession.Cart) {
                        //     setTimeout(() => {
                        //         this.setState({ loading: false });
                        //         this.props.history.push('/cart', { onClick: true });
                        //     }, 2000);
                        //     return false;
                        // }
                        // else if (pageSession === Config.pageSession.PayBill) {
                        //     setTimeout(() => {
                        //         this.setState({ loading: false });
                        //         this.props.history.push('/mybills');
                        //     }, 2000);
                        //     return false;
                        // }
                        // else if (pageSession === Config.pageSession.MyOrders) {
                        //     setTimeout(() => {
                        //         this.setState({ loading: false });
                        //         this.props.history.push('/myorders');
                        //     }, 2000);
                        //     return false;
                        // }
                        // else if (pageSession === Config.pageSession.ChooseAddress) {
                        //     setTimeout(() => {
                        //         this.setState({ loading: false });
                        //         this.props.history.push('/cart');
                        //     }, 2000);
                        //     return false;
                        // }
                        // else {
                        //     setTimeout(() => {
                        //         this.setState({ loading: false });
                        //         this.props.history.push('/home');
                        //     }, 2000);
                        //     return false;
                        // }
                    }
                    else {
                        this.showMessage(response.message, "danger");
                        this.setState({ loading: true });
                        sessionStorage.removeItem("login-page-session"); // Cart Session
                        sessionStorage.removeItem("on-click-mode"); // Cart Session
                        if (pageSession === Config.pageSession.Cart) {
                            setTimeout(() => {
                                this.setState({ loading: false });
                                this.props.history.push('/cart' + urlPattern);
                            }, Config.minMsgTimeout);
                            return false;
                        }
                        else if (pageSession === Config.pageSession.PayBill) {
                            setTimeout(() => {
                                this.setState({ loading: false });
                                this.props.history.push('/items' + urlPattern);
                            }, Config.minMsgTimeout);
                            return false;
                        }
                        else if (pageSession === Config.pageSession.MyOrders) {
                            setTimeout(() => {
                                this.setState({ loading: false });
                                this.props.history.push('/myorders' + urlPattern);
                            }, Config.minMsgTimeout);
                            return false;
                        }
                        else if (pageSession === Config.pageSession.ChooseAddress) {
                            setTimeout(() => {
                                this.setState({ loading: false });
                                this.props.history.push('/cart' + urlPattern);
                            }, Config.minMsgTimeout);
                            return false;
                        }
                        else {
                            setTimeout(() => {
                                this.setState({ loading: false });
                                this.props.history.push('/home' + urlPattern);
                            }, Config.minMsgTimeout);
                            return false;
                        }
                    }
                })
                .catch(err => {
                    // sessionStorage.removeItem("user-token");
                    //sessionStorage.removeItem("user-mobileno");
                    ////console.log("catch")
                    this.showMessage('Guest Check IN  - Failed to fetch - ' + err, "danger");
                });
            }
            else // if already ex main guest logged in
            {
                        sessionStorage.setItem('user-table-verified', true);
                        sessionStorage.removeItem('otpReferenceId');
                        sessionStorage.setItem('user-mobileno', this.state.mobileNo);                      
                        sessionStorage.setItem('user-loggedin', true);
                        // Temperary hard code for table pin switch value as false
                        olDetail.checkInWithTablePin = false;                      

                        // set Logged in Date                                                                    
                        let loggedIn = sHelpers.getFormattedDate(new Date());
                        sessionStorage.setItem('user-loggedin-date', loggedIn);

                        this.getGuestDetails();
                       
                    
            }

        } // End Service Type - Fine Dine
       
       
        else {
            sessionStorage.setItem('user-table-verified', true);
            sessionStorage.removeItem('otpReferenceId');
            sessionStorage.setItem('user-loggedin', true);

            sessionStorage.setItem('user-mobileno', this.state.mobileNo);
            sessionStorage.setItem('main-guest-no', this.state.mobileNo);

            // Temperary hard code for table pin switch value as false
            olDetail.checkInWithTablePin = false;

            // digital Menu
            if (olDetail.enableGuestAppOrders === false && olDetail.enableDigitalMenu === true) {
                sessionStorage.setItem('user-table-verified', true);
                sessionStorage.setItem('user-digitalMenu', true);
                setTimeout(() => {
                    //this.props.history.push('/emhome');
                    this.props.history.push('/home' + urlPattern);
                }, 1000);
                return false;
            }

            this.getGuestDetails();

            // // Redirect to Page
            // if (pageSession === Config.pageSession.Cart) {
            //     setTimeout(() => {
            //         this.setState({ loading: false });
            //         this.props.history.push('/cart', { onClick: true });
            //     }, 2000);
            //     return false;
            // }
            // else if (pageSession === Config.pageSession.PayBill) {
            //     setTimeout(() => {
            //         this.setState({ loading: false });
            //         this.props.history.push('/mybills');
            //     }, 2000);
            //     return false;
            // }
            // else if (pageSession === Config.pageSession.MyOrders) {
            //     setTimeout(() => {
            //         this.setState({ loading: false });
            //         this.props.history.push('/myorders');
            //     }, 2000);
            //     return false;
            // }
            // else if (pageSession === Config.pageSession.ChooseAddress) {
            //     setTimeout(() => {
            //         this.setState({ loading: false });
            //         this.props.history.push('/cart');
            //     }, 2000);
            //     return false;
            // }
            // else {
            //     setTimeout(() => {
            //         this.setState({ loading: false });
            //         this.props.history.push('/home');
            //     }, 2000);
            //     return false;
            // }
        }
    }

    getGuestDetails() {
        let mobileNumber = sessionStorage.getItem('user-mobileno');
        fetch(Config.baseGuestUrl + 'GetGuestDetails?GuestMobileNo=' + mobileNumber, {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                'content-type': 'application/json'
            },
        })
            .then(res => res.json())
            .then(response => {
                ////console.log("responsedas");
                ////console.log(response);
                if (response.errorCode === 0) {
                    sessionStorage.setItem('user-GuestDetails', JSON.stringify(response.response.guestDetails));
                    let selectedAddress = {
                        addressId: response.response.guestDetails.guestAddress.addressId,
                        addressLine1: response.response.guestDetails.guestAddress.addressLine1,
                        addressLine2: response.response.guestDetails.guestAddress.addressLine2,
                        addressLine3: response.response.guestDetails.guestAddress.addressLine3,
                        addressType: response.response.guestDetails.guestAddress.addressType,
                        addressTypeId: 1,//response.response.guestDetails.addressTypeId,
                        area: response.response.guestDetails.guestAddress.area,
                        city: response.response.guestDetails.guestAddress.city,
                        landmark: response.response.guestDetails.guestAddress.landmark,
                        zipCode: response.response.guestDetails.guestAddress.zipCode
                    }
                    sessionStorage.setItem('user-CurrentAddress', JSON.stringify(selectedAddress));
                }
                else {
                    this.showMessage(response.message, "danger");
                    sessionStorage.removeItem('user-GuestDetails');
                }

                this.setState({ loading: true });
                // Redirect to page               
                if (pageSession === Config.pageSession.Cart) {
                    setTimeout(() => {
                        ////console.log("cart redi");
                        this.setState({ loading: false });
                        this.props.history.push('/cart' + urlPattern, { onClick: true });
                    }, 2000);
                    return false;
                }
                else if (pageSession === Config.pageSession.PayBill) {
                    setTimeout(() => {
                        this.setState({ loading: false });
                        this.props.history.push('/mybills' + urlPattern);
                    }, 2000);
                    return false;
                }
                else if (pageSession === Config.pageSession.MyOrders) {
                    setTimeout(() => {
                        this.setState({ loading: false });
                        this.props.history.push('/myorders' + urlPattern);
                    }, 2000);
                    return false;
                }
                else if (pageSession === Config.pageSession.ChooseAddress) {
                    setTimeout(() => {
                        this.setState({ loading: false });
                        this.props.history.push('/cart' + urlPattern);
                    }, 2000);
                    return false;
                }
                else {
                    setTimeout(() => {
                        ////console.log("cart home");
                        this.setState({ loading: false });
                        this.props.history.push('/home' + urlPattern);
                    }, 2000);
                    return false;
                }
            })
            .catch(err => {
                this.showMessage('Guest Details  - Failed to fetch - ' + err, "danger");
            });
    }

    getCMSDetails() {

        let postobj = {
            organizationId: parseInt(orgnationId),
            propertyId: propertyId,
            outletCode: outletCode,
            ServiceMode: serviceType,
            ApplicationCode: Config.applicationCode,
            PageId: Config.pageIds.login, // Default Login
            PageType: Config.pageType.login, // Login           
            RequestIp: "",
            username: Config.cmsUserName,
            password: Config.cmsPassword
        }

        fetch(Config.baseAPIUrl + 'PageDetails', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(postobj)
        })
            .then(res => res.json())
            .then(response => {
                // ////console.log(response);
                if (response.isSuccessful) {

                    // Slider                   
                    if (response.banners.filter(i => i.bannerType === Config.bannerImageType.Slider) !== null && response.banners.filter(i => i.bannerType === Config.bannerImageType.Slider).length > 0){
                       // this.setState({ sliderImages: response.banners.filter(i => i.bannerType === Config.bannerImageType.Slider) });
                       sortedSliderImages = response.banners.filter(i => i.bannerType === Config.bannerImageType.Slider).slice().sort((a, b) => a.sortOrder - b.sortOrder);
                       this.setState({ sliderImages: sortedSliderImages });
                    }
                       

                    // if (response.page != null) {
                    //     if (response.page.preview !== '')
                    //         this.setState({ pageContent: response.page.content, pagePreview: response.page.preview });

                    // }
                }

            })
            .catch(err => {
                ////console.log(err);
                //this.showMessage("CMS - Failed to fetch", "danger");
            });
    }

 // Added by vijay on 01-02-2024 Jira id -2078 -Allow to login if same guest scan the same table
  handleRedirectToWithoutVerification()
 {
      let notes = {
          guestname: sessionStorage.getItem("guest-name") === null ? ' ' : sessionStorage.getItem("guest-name"),
          modetype: olDetail != null ? olDetail.enableGuestAppOrders === true ? 1 : 0 : 0
      }

      let posturlObj = {
          organizationId: parseInt(orgnationId),
          propertyId: propertyId,
          outletCode: outletCode,
          ServiceMode: serviceType,
          ContactNo: this.state.mobileNo,
          StatsId: parseInt(statsId),
          numofMenuAccess: 1,
          username: Config.linkUserName,
          password: Config.linkPassword,
          notes: JSON.stringify(notes)
      }
      // Update Contact Detail
      fetch(Config.baseAPIUrl + 'ClickURLDetail', {
          method: 'POST',
          headers: {
              'content-type': 'application/json'
          },
          body: JSON.stringify(posturlObj)
      })
          .then(res => res.json())
          .then(response => {
       
              console.log( "Login Page API" ,response);                          
       
                  this.redirectToHome(true);
            
          
          })
          .catch(err => {
              //////console.log(err);
              this.redirectToHome(true);
              //this.showMessage("CMS - Failed to fetch", "danger");
          });
  }

    showMessage(msg, type) {
        window.scrollTo(0, 0);
        this.setState({ msgShow: true });
        this.setState({ msgType: type });
        this.setState({ alertMessage: msg });
        this.setState({ loading: false });
        setTimeout(() => {
            this.setState({ msgShow: false });
        }, Config.minMsgTimeout);
    };
    render() {
        const { loading } = this.state;


        return (
            <div >
                {loading && <LoadingAnimation />}
                <div id="login-content">
                    {this.state.msgShow && <ErrorAlert alertMsg={this.state.alertMessage} msgType={this.state.msgType} msgShow={this.state.msgShow} />}
                    <Header loggedIn={this.state.loggedIn} />
                    <div className="content-wrap">
                        {/* <div className="img-hero">
                            <div>
                                <img src="../assets/theme1/img/login-bg.png" alt="slider" />
                            </div>
                        </div> */}
                        <div style={{ marginTop: "50px", padding: "8px" }}>
                            <Slider {...homeslidersettings} className="img-hero">
                                {this.state.sliderImages.map(img =>
                                    <div key={"slider-img-" + img.sort}>
                                        <img src={img.primaryImageUrl} alt="lucid-slider-contact-less-order" />
                                    </div>
                                )}
                            </Slider>
                        </div>
                        <div className="login-section-home login-panel">
                            <div className="heading-section">
                                <div><img className="img-center" src="/img/user-img.png" alt="lucid-contactless-app-user-login" /></div>

                                <div className="sa-title popcat">LOGIN</div>
                                <div className="login-text">Enter your valid mobile number to continue</div>
                                <div className="clear"></div>
                                <form className="form" action="##" method="post" id="setting">
                                    <div className="form-group">
                                        <div className="col-xs-12">
                                            <input type="number" value={this.state.mobileNo} onChange={(e) => this.handlechange(e)} className="form-control login-input" name="input-mobile-no" id="input-mobile-no" placeholder="Phone Number" maxLength="10" autoFocus autoComplete="off" ref={(input) => { this.nameInput = input; }} />
                                        </div>
                                        <div className="col-xs-12">
                                            <input type="test" style={{ "marginTop": "10px", "marginBottom": "10px" }} className="form-control login-input" name="input-guest-name" id="input-guest-name" placeholder="Name" maxLength="50" autoComplete="off" />
                                        </div>

                                    </div>
                                    <div className="submit-btn">
                                        <div className="col-12">
                                            <button className="theme-button  login-send-btn" onClick={(e) => this.handleClick(e)} id="input-btn-send-otp" disabled={loading}>Login</button>
                                        </div>
                                    </div>
                                </form>
                                <div className="clear"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Login;