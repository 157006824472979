import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import sidemenulogo from './home-logo.png';

const menuItems = [
  { menuId: 1, menuName: 'Home', iconsName: 'fas fa-home', routePath: "/home" },
  { menuId: 2, menuName: 'My Orders', iconsName: 'fa fa-shopping-cart sidebar-icon', routePath: "/myorders" },
  // { menuId: 2, menuName: 'My Bills', iconsName: 'fa fa-money', routePath: "/mybills" },
  // { menuId: 3, menuName: 'Contact Us', iconsName: 'fa fa-phone sidebar-icon', routePath: "/home" },
  { menuId: 4, menuName: 'Logout', iconsName: 'fa fa-sign-out-alt', routePath: "/logout" }
];

//const config = require('../../data/SiteConfig');

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: "Receipts"
    }
  }
 
  _handleClick(name) {
    this.setState({ active: name });
  }
  render() {
    const activeStyle = { color: '#007bff' };
    return (
      <div>
        <nav id="sidebarleft" className="sidenav">
          <div id="dismiss">
            <i className="fas fa-times"></i>
          </div>
          <div className="sidebar-header">
            <img className="sidebar-logo" src={sidemenulogo} alt="lucid-contactless-food-order" />
          </div>
          {this.props.loggedIn &&
            <div className="sdprofile">
              <div className="sdp-left">
                <img src="/img/user-img.png" alt="profile" />
              </div>
              <div className="sdp-right">
                <div className="sd-name">{sessionStorage.getItem("user-mobileno")}</div>
                {/* <div className="sd-status">LU-001</div> */}
              </div>
            </div>
          }
          {(this.props.loggedIn && this.props.clmenu === true) &&
            <ul className="list-unstyled components">
              {
                menuItems.map(mnuItem =>
                  <li key={mnuItem.menuId} className="mnuclk side-menu-li">
                    <NavLink
                      style={this.state.active === mnuItem.menuName ? activeStyle : {}}
                      onClick={this._handleClick.bind(this, mnuItem.menuName)}
                      to={mnuItem.routePath}
                    >
                      <i className={mnuItem.iconsName}></i> {mnuItem.menuName}
                    </NavLink>

                  </li>
                )}
            </ul>
          }
        </nav>
        <nav className="navbar navbar-expand-lg navbar-light bg-header">
          <div className="container-fluid">
            {(this.props.loggedIn && this.props.mobileApp === false) &&
              <button type="button" id="sidebarleftbutton" className="btn">
                <i className="fa fa-bars"></i>
              </button>
            }
            <div className="logo home-logo" style={{ fontSize: "15px", paddingTop: "6px" }}>{this.props.pageTitle !== '' ? this.props.pageTitle : "Welcome to " + (sessionStorage.getItem("user-outletname") !== "NONE" ? sessionStorage.getItem("user-outletname") : sessionStorage.getItem("user-propertyname"))}</div>
            {/* <div style={{ whiteSpace: "pre-wrap", }}>{this.state.pageTitle !== '' ? this.state.pageTitle : "Welcome to " + sessionStorage.getItem("user-outletname")}  </div> */}
            {/* <button type="button" id="sidebarrightbutton" className="btn">
              <i className="fa fa-user"></i>
            </button> */}
          </div>
        </nav>

      </div>
    );
  }
}
export default Header