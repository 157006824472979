import React, { Component } from 'react';
// import React, { Component, useState, useEffect, useRef } from "react";
// import { gsap } from "gsap/all";
import Slider from "react-slick";
import LoadingAnimation from '../../components/loadinganimation';
import Loadingpage from '../../components/loadinganimation/loading-page';
//import loadjs from 'loadjs';
import Header from '../../components/Header';
import './home.scss';
//import { NavLink } from 'react-router-dom';
//import Cookies from 'universal-cookie';
import Config from '../../data/SiteConfig';
import ErrorAlert from '../../components/alertmessages';
import { isMobile } from 'react-device-detect';
//import sHelpers from '../../modules/helpers/statichelpers';
const homeslidersettings = {
    autoplay: true,
    dots: true,
    infinite: true,
    arrows: false,
    speed: 300,
    slidesToShow: 1,
    adaptiveHeight: false
};



// const ymlcarousel = {
//     slidesToShow: 5,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 2000,
//     responsive: [
//         {
//             breakpoint: 768,
//             settings: {
//                 slidesToShow: 4,
//                 slidesToScroll: 4
//             }
//         },
//         {
//             breakpoint: 480,
//             settings: {
//                 slidesToShow: 3,
//                 slidesToScroll: 3
//             }
//         }
//     ]
// };

// const groups = [
//     { grpId: 1, grpName: 'Biriyani', imgName: '../assets/theme1/img/biriyani-food-7.png', routePath: "/home" },
//     { grpId: 2, grpName: 'Soups', imgName: '../assets/theme1/img/soups.jpg', routePath: "/home" },
//     { grpId: 3, grpName: 'Burger', imgName: '../assets/theme1/img/fast-food-3.png', routePath: "/home" },
//     { grpId: 4, grpName: 'Hot Dog', imgName: '../assets/theme1/img/fast-food-4.png', routePath: "/home" },
//     { grpId: 5, grpName: 'Fry Chick', imgName: '../assets/theme1/img/fast-food-5.png', routePath: "/home" },
//     { grpId: 6, grpName: 'Shawarma', imgName: '../assets/theme1/img/fast-food-6.png', routePath: "/home" }
// ];
let loadcomfn = null;
let mobileNo = '';
let token = '';
let tableNo = '';
let serviceType = '';
let organizationId = '';
let propertyId = '';
let outletCode = '';
let outletName = '';
let verifiedTable = '';
let statsId = '';
let mainGuestMobileNo = '';
//let groupSession = false;
let groupPin = '';
//let olDetail = {};
//let outletDetails = null;
//let bstatic = "0";
let guestLoggedIn = "false";
let qrLevel = 5 // By Default Service type Mode
let mobileApp = "false";
let outletDet = [];
let isappOrders = false;
let sortedSliderImages = '';
let urlPattern = '';
export default class Home extends Component {

    constructor(props) {
        super(props);
        this.goToItems = this.goToItems.bind(this);
        // this.getDetails = this.getDetails.bind(this);     
        this.state = {
            loading: false,
            getMenus: [],
            getItems: [],
            getModifiers: [],
            getTaxes: [],
            getCharges: [],
            mobileNo: '',
            orgId: 0,
            propertyId: '',
            outletCode: '',
            tableNo: '',
            serviceType: '',
            alertMessage: '',
            msgType: '',
            msgShow: false,
            APIResponseMsg: '',
            outletDetails: null,
            sliderImages: [
                {
                    altName: "",
                    sortOrder: 0,
                    bannerType: 0,
                    height: 0,
                    imageName: "",
                    imagePathName: "",
                    primaryImageType: "",
                    secondaryImageType: "",
                    bannerName: "Slide 1",
                    primaryImageUrl: "/img/slider/no-image.png",
                    secondaryImageUrl: "",
                    status: 0,
                    width: 0
                }
            ],
            groupMemberList: [],
            loggedIn: false,
            showOutlets: false,
            showServiceTypes: false,
            outletList: [],
            ServiceTypeList: [],
            pagePreview: "",
            pageContent: "",
            mobileApp: false,
            bannerImgs: [],
            pageTitle: '',        
           
        }

    }


    componentWillMount() {

        if (Config.IsMobileAccess) {
            if (!isMobile) {
                this.props.history.push('/deviceerror' + urlPattern);
                return false;
            }
        }      


        //$('.modal-backdrop').remove();
        //let cookie = new Cookies();
        // let token = cookie.get("token");
        // let mobileNo = cookie.get("user-mobileNo");
        // let tableNo = cookie.get("user-tableNo");
        // let serviceType = cookie.get("user-serviceType");


        // sessionStorage.removeItem('getMenuGroups');
        // sessionStorage.removeItem('getModifierGroups');
        // sessionStorage.removeItem('getTaxes');
        // sessionStorage.removeItem('getmenuItems');
        // sessionStorage.removeItem('getCharges');
        sessionStorage.removeItem("user-vendorOrderId");


        mobileNo = sessionStorage.getItem('user-mobileno');
        token = sessionStorage.getItem('user-token');
        tableNo = sessionStorage.getItem('user-tableno');
        serviceType = sessionStorage.getItem('user-servicetype');
        organizationId = sessionStorage.getItem('user-orgnationid');
        propertyId = sessionStorage.getItem('user-propertyid');
        outletCode = sessionStorage.getItem('user-outletcode');
        outletName = sessionStorage.getItem('user-outletname');
        verifiedTable = sessionStorage.getItem('user-table-verified');
        statsId = sessionStorage.getItem('user-statsid');
        mainGuestMobileNo = sessionStorage.getItem('main-guest-no') == null ? '' : sessionStorage.getItem('main-guest-no');
        //  groupSession = sessionStorage.getItem('group-session') == null ? false : sessionStorage.getItem('group-session');
        groupPin = sessionStorage.getItem('group-pin') == null ? '' : sessionStorage.getItem('group-pin');
        // outletDetails = sessionStorage.getItem('user-outletDetails');
        //bstatic = sessionStorage.getItem('user-bstatic');
        guestLoggedIn = sessionStorage.getItem('user-loggedin') == null ? "false" : sessionStorage.getItem('user-loggedin');
        qrLevel = sessionStorage.getItem('user-qr-level') == null ? 5 : sessionStorage.getItem('user-qr-level');
        mobileApp = sessionStorage.getItem('mobile-app') == null ? "false" : sessionStorage.getItem('mobile-app');
        outletDet = JSON.parse(sessionStorage.getItem("user-outletDetails"));
        sessionStorage.setItem('dashboard-hide', (sessionStorage.getItem('dashboard-hide') == null ? "false" : sessionStorage.getItem('dashboard-hide')));
        urlPattern =   sessionStorage.getItem('url-pattern');
        // console.log(token);
        // console.log(tableNo);
        // console.log(serviceType);
        // console.log(organizationId);
        // console.log(propertyId);
        // console.log(outletDet);

        // Check Session
        if (!urlPattern) {
            // Handle error or redirect as needed
            this.props.history.push('/qrcodeerror' + Config.defaultUrlPattern);
            return false;
          }
          
        const sessionDt = sessionStorage.getItem("u-l-v-url-dt");
        if (!sessionDt) {
            this.props.history.push('/sessionerror' + urlPattern);
            return false;
        }

      
          
        if (token === undefined || token === "" || token === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (mobileNo === undefined || mobileNo === "" || mobileNo === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (tableNo === undefined || tableNo === "" || tableNo === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (serviceType === undefined || serviceType === "" || serviceType === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (organizationId === undefined || organizationId === "" || organizationId === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (propertyId === undefined || propertyId === "" || propertyId === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (outletCode === undefined || outletCode === "" || outletCode === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }

        if (outletDet !== null) {
            isappOrders = outletDet.enableGuestAppOrders;
        }
        // else {
        //     olDetail = JSON.parse(outletDetails);
        //     this.setState({ paymentVendorLogo: olDetail.outletImageUrl !== '' ? olDetail.outletImageUrl : "https://cdn.razorpay.com/logos/BUVwvgaqVByGp2_large.png" });
        // }      
        //  console.log(Config.qrCodeLevel.Property);


        if (serviceType === "FINE-DINE") {

            if (verifiedTable === undefined || verifiedTable === "" || verifiedTable === null || verifiedTable === false || verifiedTable === "false") {
                if (isappOrders === true) {
                    this.props.history.push('/' + urlPattern);
                    return false;
                }
            }
        }
        if (statsId === undefined || statsId === "" || statsId === null) {
            statsId = "0"
        }
        // var status = navigator.onLine;
        //console.log(guestLoggedIn);

        document.body.classList.remove('modal-open');
        let modalPopup = document.querySelector('.modal-backdrop');
        if (modalPopup !== null) {
            modalPopup.classList.remove('modal-backdrop', 'fade', 'show');
        }


        /// Session Validation for Fine Dine
        // Static Var
        // StaticValidation.StaticSwitchCtrl();
        // if (bstatic === "0") {     
        //else this.getDetails(false);
        // }
        // else {
        //     this.setState({ loading: true });
        //     setTimeout(() => {
        //         this.setState({ loading: false });
        //         this.props.history.push('/emhome');
        //     }, 1500);
        // }        
        // Check Auto Login
        if (parseInt(qrLevel) === Config.qrCodeLevel.Property) {
            this.getOutletList();
        }
        else if (parseInt(qrLevel) === Config.qrCodeLevel.Outlet) {
            this.getServiceTypeList(outletCode, outletName);

        }
        else {
            this.getCMSDetails(true); //Get CMS Page Details     
        }

        //window.onunload = function () { null };
    }
    componentDidMount() {
        const script = document.createElement("script");
        script.src = "../assets/theme1/js/theme.js"; // it is not calling mentioned for to avoid null value
        script.async = true;
        script.onload = () => this.loadwindow();
        document.body.appendChild(script);
        // window.location.reload(true);


        if (guestLoggedIn === "true") {

            this.setState({ loggedIn: true });
        }

        if (mobileApp === "true") {

            this.setState({ mobileApp: true });
        }

        if (serviceType === "FINE-DINE") {
            // Comment line for avoid the logout automatically - Table Checkout Cases 
            // if (!sHelpers.sessionValidation()) {
            //     this.showMessage("Your session has expired, please scan once again", "danger");
            //     this.setState({ loading: true });
            //     setTimeout(() => {
            //         this.setState({ loading: false });
            //         this.props.history.push('/logout');
            //     }, 3000);
            //     return false;
            // }
            if (guestLoggedIn === "true") {
                this.getGuestDashboardDetails();
            }
            /// else this.getDetails(false);


        }
        // else {
        //     this.getDetails(false);
        // }

        // Handle Back Button -- Start
        // if (mobileApp === "true") {
        //     const { history } = this.props;
        //     history.listen((newLocation, action) => {

        //         console.log(newLocation.pathname);
        //         console.log(newLocation.search);
        //         console.log(action);

        //         console.log(this.currentPathname);
        //         console.log(this.currentSearch);
        //         console.log(this.action);

        //         // if (action === "PU5H") {
        //         //     if (
        //         //         newLocation.pathname !== this.currentPathname ||
        //         //         newLocation.search !== this.currentSearch
        //         //     ) {
        //         //         this.currentPathname = newLocation.pathname;
        //         //         this.currentSearch = newLocation.search;

        //         //         history.push({
        //         //             pathname: newLocation.pathname,
        //         //             search: newLocation.search
        //         //         });
        //         //     }
        //         //     else {
        //         //         console.log(newLocation.pathname);
        //         //         console.log(newLocation.search);
        //         //         console.log(action);

        //         //         // if (newLocation.pathname === '/home') {
        //         //         //     console.log(newLocation.pathname);
        //         //         //     this.props.history.push('/home');
        //         //         //     history.go(1);
        //         //         // }
        //         //         // else if (newLocation.pathname === '/items') {
        //         //         //     console.log('item');
        //         //         //     this.props.history.push('/items');
        //         //         // }
        //         //         // else if (newLocation.pathname === '/cart') {
        //         //         //     console.log('cart');
        //         //         //     this.props.history.push('/cart');
        //         //         // }
        //         //     }

        //         // } else {
        //         if (action === "POP") {
        //             console.log(newLocation.pathname);
        //             console.log(newLocation.search);
        //             console.log(action);

        //             alert('wait');

        //             // if (newLocation.pathname === '/home') {
        //             //     console.log(newLocation.pathname);
        //             //     this.props.history.push('/home');
        //             //     history.go(1);
        //             // }
        //             // else if (newLocation.pathname === '/items') {
        //             //     console.log('item');
        //             //     this.props.history.push('/items');
        //             // }
        //             // else if (newLocation.pathname === '/cart') {
        //             //     console.log('cart');
        //             //     this.props.history.push('/cart');
        //             // }
        //             // }
        //         }
        //     });
        // }
        // // Handle Back Button --  End
    }

    // guestTableCheck() {

    //     if (outletCode !== 'NONE') {
    //         // console.log(outletCode);
    //         let urlParam = '?OutletId=' + propertyId + outletCode + '&ServiceType=' + serviceType;
    //         // // Get Outlet Details                    
    //         fetch(Config.basePOSUrl + 'GetOutletDetails' + urlParam, {
    //             method: 'GET',
    //             headers: {
    //                 Authorization: "Bearer " + token,
    //                 'content-type': 'application/json'
    //             }
    //         })
    //             .then(res => res.json())
    //             .then(response => {
    //                 // console.log(response);
    //                 if (response.errorCode === 0) {
    //                     this.setState({ outletDetails: response.response.outletDetails });
    //                     // if (response.response.outletDetails.homePageSliderImages !== null && response.response.outletDetails.homePageSliderImages.length > 0) {
    //                     //     this.setState({ sliderImages: response.response.outletDetails.homePageSliderImages });
    //                     // }
    //                     sessionStorage.setItem("user-outletDetails", JSON.stringify(response.response.outletDetails));
    //                     setTimeout(() => {
    //                         window.scrollTo(0, 0);
    //                     }, 1000);

    //                     //// Table Check 
    //                     if (guestLoggedIn === "true") {
    //                         if (serviceType === "FINE-DINE") {
    //                             let olDetail = this.state.outletDetails;
    //                             //console.log(olDetail);
    //                             let postObj = {
    //                                 OutletId: propertyId + outletCode,
    //                                 GuestMobileCountryCode: "91",
    //                                 GuestMobileNumber: this.state.loggedIn === true ? mainGuestMobileNo : '',
    //                                 TableNumber: serviceType !== "FINE-DINE" ? '' : tableNo
    //                             }

    //                             fetch(Config.basePOSUrl + 'GetGuestTableStatus', {
    //                                 method: 'POST',
    //                                 headers: {
    //                                     Authorization: "Bearer " + token,
    //                                     'content-type': 'application/json'
    //                                 },
    //                                 body: JSON.stringify(postObj)
    //                             })
    //                                 .then(res => res.json())
    //                                 .then(response => {
    //                                     if (response.errorCode === 0) {
    //                                         // console.log(response);
    //                                         if (!response.response.guestTableStatus.isCheckedIn) {
    //                                             //  console.log(olDetail);
    //                                             if (olDetail.autoCheckInWhenGuestContinueOrderInSameTable) {
    //                                                 let postObj = {
    //                                                     OutletId: propertyId + outletCode,
    //                                                     GuestName: sessionStorage.getItem("guest-name") === null ? ' ' : sessionStorage.getItem("guest-name"),
    //                                                     MobileNo: this.state.loggedIn === true ? mainGuestMobileNo : '',
    //                                                     TableNumber: tableNo,
    //                                                     GroupCode: groupPin
    //                                                 }
    //                                                 fetch(Config.basePOSUrl + 'GuestCheckIn', {
    //                                                     method: 'POST',
    //                                                     headers: {
    //                                                         Authorization: "Bearer " + token,
    //                                                         'content-type': 'application/json'
    //                                                     },
    //                                                     body: JSON.stringify(postObj)
    //                                                 })
    //                                                     .then(res => res.json())
    //                                                     .then(response => {
    //                                                         if (response.errorCode === 0) {
    //                                                             setTimeout(() => {
    //                                                                 this.getDetails(true);
    //                                                             }, 500);
    //                                                         }
    //                                                         else {
    //                                                             this.showMessage("Your selected table has been checked out.", "danger");
    //                                                             setTimeout(() => {
    //                                                                 this.setState({ loading: false });
    //                                                                 this.props.history.push('/logout');
    //                                                             }, 3000);
    //                                                         }
    //                                                     })
    //                                                     .catch(err => {
    //                                                         sessionStorage.removeItem("user-token");
    //                                                         sessionStorage.removeItem("user-mobileno");
    //                                                         this.showMessage('Guest Check IN  - Failed to fetch - ' + err, "danger");
    //                                                     });
    //                                             }
    //                                             else {
    //                                                 this.showMessage('Your table has been Closed! , Please scan and login once again', "danger");
    //                                                 this.setState({ loading: true });
    //                                                 setTimeout(() => {
    //                                                     this.setState({ loading: false });
    //                                                     this.props.history.push('/');
    //                                                 }, 3000);
    //                                             }
    //                                         }
    //                                         else {
    //                                             if (tableNo !== response.response.guestTableStatus.currentTableNumber) {
    //                                                 this.showMessage("Your table has been transfer from " + tableNo + " to " + response.response.guestTableStatus.currentTableNumber, "danger");
    //                                                 sessionStorage.setItem('user-tableno', response.response.guestTableStatus.currentTableNumber);
    //                                                 tableNo = sessionStorage.getItem('user-tableno');
    //                                             }

    //                                             setTimeout(() => {
    //                                                 this.getDetails(true);
    //                                             }, 500);
    //                                         }
    //                                     }
    //                                     else {
    //                                         this.setState({ loading: false });
    //                                         this.showMessage(response.message, "danger");

    //                                     }
    //                                 })
    //                                 .catch(err => {
    //                                     this.setState({ loading: false });
    //                                     this.showMessage(err, "danger");
    //                                 });
    //                         } // End Fine Dine
    //                         else { // 
    //                             setTimeout(() => {
    //                                 this.getDetails(true);
    //                             }, 500);
    //                         }
    //                     } // End Check Logged In
    //                     else {
    //                         setTimeout(() => {
    //                             this.getDetails(true);
    //                         }, 500);
    //                     }
    //                 }

    //                 else {
    //                     this.setState({ APIResponseMsg: "" });
    //                 }
    //             })
    //             .catch(err => {
    //                 this.setState({ APIResponseMsg: "Outlet Details - Failed to fetch" })
    //                 //  console.log('Outlet Details - Failed to fetch - ' + err);
    //                 this.showMessage('Outlet Details - Failed to fetch - ' + err, "danger");
    //             });

    //     } // End Outlet Valid
    //     else {
    //         setTimeout(() => {
    //             this.getDetails(true);
    //         }, 500);

    //     }
    // }

    getGuestDashboardDetails() {
        let urlParam = '?OutletId=' + propertyId + outletCode + '&ServiceType=' + serviceType + '&TableNumber=' + tableNo + '&guestmobileNumber=' + mobileNo;
        //console.log(urlParam);
        fetch(Config.basePOSUrl + 'GetGuestAppDashBoardDetails' + urlParam, {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                'content-type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(response => {
                //console.log(response);
                if (response.errorCode === 0) {
                    if (mobileNo === mainGuestMobileNo) {
                        this.setState({ groupPin: response.response.groupDetails.groupCode });
                        groupPin = response.response.groupDetails.groupCode;
                        sessionStorage.setItem('group-pin', groupPin);
                    }
                    else
                        this.setState({ groupPin: "" });

                    this.setState({ groupMemberList: response.response.groupDetails.guestDetails });
                    // this.getDetails(false);

                }
                else {
                    this.showMessage(response.message, "danger");
                }
            })
            .catch(err => {
                ////console.log(err);            
                this.showMessage('An error occured - ' + err, "danger");
            });
    }

    // getDetails(tableCheck) {

    //     this.setState({ APIResponseMsg: "Loading..." });


    //     // Guest Table Check - CheckIn / Check Out / Table Transfer - to Get latest Table Detail
    //     if (tableCheck === false) {
    //         this.guestTableCheck();
    //         return false;
    //     }
    //     this.setState({ APIResponseMsg: "" });

    // }

    loadItems() {

        //  this.setState({ APIResponseMsg: "Loading..." });
        serviceType = sessionStorage.getItem('user-servicetype');
        propertyId = sessionStorage.getItem('user-propertyid');
        outletCode = sessionStorage.getItem('user-outletcode');

        let urlParam = '?OutletId=' + propertyId + outletCode + '&ServiceType=' + serviceType;
        // console.log(urlParam);
        fetch(Config.basePOSUrl + 'Getguestappmenus' + urlParam, {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                'content-type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(response => {
                //  console.log(response);
                if (response.errorCode === 0) {
                    // console.log(JSON.stringify(response.response.menuGroups));
                    //  console.log(JSON.stringify(response.response.menuItems));
                    // Filter active Menu Items
                    let activeMenuGroups = [];
                    let activeMenuItems = [];
                    // let sortMenus = [];
                    if (response.response.menuGroups.length > 0)
                        activeMenuGroups = response.response.menuGroups.filter(x => x.isActive === true).sort(function (a, b) {	// asc										 
                            return new Date(a.displayOrder) - new Date(b.displayOrder);
                        });

                    if (response.response.menuItems.length > 0) {
                        activeMenuItems = response.response.menuItems.filter(x => x.isActive === true).sort(function (a, b) {	// asc										 
                            return new Date(a.displayOrder) - new Date(b.displayOrder);
                        });
                        // let sortMenus = activeMenuItems.sort(function (a, b) {	// asc										 
                        //     return new Date(a.displayOrder) - new Date(b.displayOrder);
                        // });
                        //  console.log(activeMenuItems);
                    }
                    sessionStorage.setItem("getMenuGroups", JSON.stringify(activeMenuGroups));
                    sessionStorage.setItem("getmenuItems", JSON.stringify(activeMenuItems));
                    sessionStorage.setItem("getModifierGroups", JSON.stringify(response.response.modifierGroups));
                    sessionStorage.setItem("getTaxes", JSON.stringify(response.response.taxes));
                    sessionStorage.setItem("getCharges", JSON.stringify(response.response.charges));
                    sessionStorage.setItem("menuFetchDateTime", response.response.menuDateTime);
                    this.setState({ getMenus: activeMenuGroups });
                    this.setState({ getItems: activeMenuItems });
                    this.setState({ getModifiers: response.response.modifierGroups });
                    this.setState({ getTaxes: response.response.taxes });
                    this.setState({ getCharges: response.response.charges });
                    this.setState({ APIResponseMsg: "" });
                    // this.setState({ outletDetails: olDetail });

                    // if (olDetail.homePageSliderImages !== null && olDetail.homePageSliderImages.length > 0) {
                    //     this.setState({ sliderImages: olDetail.homePageSliderImages });
                    // }
                    // //  this.setState({ APIResponseMsg: "" });
                    // setTimeout(() => {
                    //     window.scrollTo(0, 0);
                    // }, 500);


                    // Check the static Page Redirection                   
                    // if (StaticValidation.enableGuestAppOrders === false && StaticValidation.enableDigitalMenu === true) {
                    //     this.setState({ loading: true });
                    //     setTimeout(() => {
                    //         this.setState({ loading: false });
                    //         this.props.history.push('/emhome');
                    //     }, 1500);
                    // }    

                    // // Get Outlet Details                    
                    // fetch(Config.basePOSUrl + 'GetOutletDetails' + urlParam, {
                    //     method: 'GET',
                    //     headers: {
                    //         Authorization: "Bearer " + token,
                    //         'content-type': 'application/json'
                    //     }
                    // })
                    //     .then(res => res.json())
                    //     .then(response => {
                    //         //  console.log(response);
                    //         if (response.errorCode === 0) {
                    //             this.setState({ outletDetails: response.response.outletDetails });
                    //             if (response.response.outletDetails.homePageSliderImages !== null && response.response.outletDetails.homePageSliderImages.length > 0) {
                    //                 this.setState({ sliderImages: response.response.outletDetails.homePageSliderImages });
                    //             }
                    //             sessionStorage.setItem("user-outletDetails", JSON.stringify(response.response.outletDetails));
                    //             this.setState({ APIResponseMsg: "" });
                    //             setTimeout(() => {
                    //                 window.scrollTo(0, 0);
                    //             }, 1000);

                    //         }
                    //         else {
                    //             this.setState({ APIResponseMsg: "Failed to fetch" });
                    //         }
                    //     })
                    //     .catch(err => {
                    //         this.setState({ APIResponseMsg: "Outlet Details - Failed to fetch" })
                    //         //  console.log('Outlet Details - Failed to fetch - ' + err);
                    //         this.showMessage('Outlet Details - Failed to fetch - ' + err, "danger");
                    //     });                   
   
                    setTimeout(() => {
                        this.setState({ loading: false })                      
                        this.props.history.push('/items' + urlPattern , { focusGroup: "focus-mnugrp-focus-mnugrp-recommended" });
                    }, Config.minMsgTimeout);
                }
                else {
                    this.setState({ APIResponseMsg: "Failed to fetch" })
                    this.showMessage(response.message, "danger");
                }
            })
            .catch(err => {
                this.setState({ APIResponseMsg: "Failed to fetch" })
                //console.log(err);
                // this.showMessage("An error occured", "danger");
            });
    }
    loadClassficiation() {
        serviceType = sessionStorage.getItem('user-servicetype');
        propertyId = sessionStorage.getItem('user-propertyid');
        outletCode = sessionStorage.getItem('user-outletcode');

        let urlParam = '?OutletId=' + propertyId + outletCode + '&ServiceType=' + serviceType;
        // console.log(urlParam);
        fetch(Config.basePOSUrl + 'GetClassificationList' + urlParam, {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                'content-type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(response => {
                if (response.errorCode === 0) {
                    sessionStorage.setItem("getclf", JSON.stringify(response.response.classificationList));
                    this.loadItems();
                }
                else {
                    this.setState({ APIResponseMsg: "Failed to fetch" })
                    this.showMessage(response.message, "danger");
                }
            })
            .catch(err => {
                this.setState({ APIResponseMsg: "Failed to fetch" })
                //console.log(err);
                // this.showMessage("An error occured", "danger");
            });
    }

    getCMSDetails(IsUpdateLog) {
        this.setState({ pageLoading: true });
        let previewVal = "";
        let bannerVal = [];
        this.setState({ APIResponseMsg: "Loading..." });

        let postobj = {
            organizationId: parseInt(organizationId),
            propertyId: propertyId,
            outletCode: outletCode,
            ServiceMode: serviceType,
            ApplicationCode: Config.applicationCode,
            PageId: Config.pageIds.home, // Default Home
            PageType: Config.pageType.home, // Home           
            RequestIp: "",
            username: Config.cmsUserName,
            password: Config.cmsPassword
        }

        fetch(Config.baseAPIUrl + 'PageDetails', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(postobj)
        })
            .then(res => res.json())
            .then(response => {
                // console.log(response);
                if (response.isSuccessful) {
                    sessionStorage.setItem("pageCMSSettings", JSON.stringify(response));
                    this.setState({ pageTitle: response.page.pageTitle });
                    // Slider                   
                    if (response.banners.filter(i => i.bannerType === Config.bannerImageType.Slider) !== null && response.banners.filter(i => i.bannerType === Config.bannerImageType.Slider).length > 0) {
                        // this.setState({ sliderImages: response.banners.filter(i => i.bannerType === Config.bannerImageType.Slider) });

                        sortedSliderImages = response.banners.filter(i => i.bannerType === Config.bannerImageType.Slider).slice().sort((a, b) => a.sortOrder - b.sortOrder);

                        this.setState({ sliderImages: sortedSliderImages });
                    }


                    if (response.banners.filter(i => i.bannerType === Config.bannerImageType.Banner) !== null && response.banners.filter(i => i.bannerType === Config.bannerImageType.Banner).length > 0) {
                        this.setState({ bannerImgs: response.banners.filter(i => i.bannerType === Config.bannerImageType.Banner) });
                        bannerVal = response.banners.filter(i => i.bannerType === Config.bannerImageType.Banner);
                    }


                    if (response.page != null) {
                        if (response.page.preview !== '') {
                            this.setState({ pageContent: response.page.content, pagePreview: response.page.preview });
                            previewVal = response.page.preview;
                        }
                    }
                }
                else {
                    // this.setState({ themeId: 1 }); // Default Theme Value
                    // this.showMessage(response.message, "danger");     
                    // console.log(response.message);
                    if (sessionStorage.getItem("pageCMSSettings") != null) {
                        let cms = JSON.parse(sessionStorage.getItem("pageCMSSettings"));

                        if (cms.banners.filter(i => i.bannerType === Config.bannerImageType.Slider) !== null && cms.banners.filter(i => i.bannerType === Config.bannerImageType.Slider).length > 0)
                            this.setState({ sliderImages: cms.banners.filter(i => i.bannerType === Config.bannerImageType.Slider) });

                        if (cms.page.preview !== '') {
                            this.setState({ pageContent: cms.page.content, pagePreview: cms.page.preview });
                            previewVal = cms.page.preview;
                        }

                    }
                    this.setState({ pageTitle: '' });
                }

                /// Dashboard Hide
                if (previewVal === "" && bannerVal.length === 0) {
                    if (parseInt(qrLevel) !== Config.qrCodeLevel.Property && parseInt(qrLevel) !== Config.qrCodeLevel.Outlet) {
                        this.setState({ loading: false, pageLoading: true });
                        sessionStorage.setItem('dashboard-hide', "true");
                        this.loadItems();
                    }
                    else {
                        if (parseInt(qrLevel) !== Config.qrCodeLevel.Outlet) {
                            this.setState({ pageLoading: false });
                            sessionStorage.setItem('dashboard-hide', "false");
                        }
                    }
                }
                else {
                    this.setState({ pageLoading: false });
                    sessionStorage.setItem('dashboard-hide', "false");
                }

                if (IsUpdateLog) this.updateClickDetail();
                this.setState({ APIResponseMsg: "" });

            })
            .catch(err => {
                //console.log(err);
                this.setState({ sliderImages: [] })
                this.setState({ APIResponseMsg: "", loading: false });
                if (parseInt(qrLevel) !== Config.qrCodeLevel.Property && parseInt(qrLevel) !== Config.qrCodeLevel.Outlet) {
                    this.setState({ loading: false, pageLoading: true });
                    sessionStorage.setItem('dashboard-hide', "true");
                    this.loadItems();
                }
                else {
                    if (parseInt(qrLevel) !== Config.qrCodeLevel.Outlet) {
                        this.setState({ pageLoading: false });
                        sessionStorage.setItem('dashboard-hide', "false");
                    }
                }

                if (IsUpdateLog) this.updateClickDetail();
                //this.showMessage("CMS - Failed to fetch", "danger");
            });
    }

    updateClickDetail() {
        let notes = {
            guestname: sessionStorage.getItem("guest-name") === null ? ' ' : sessionStorage.getItem("guest-name"),
            modetype: outletDet != null ? isappOrders === true ? 1 : 0 : 0
        }
        let posturlObj = {
            organizationId: parseInt(organizationId),
            propertyId: propertyId,
            outletCode: outletCode,
            ServiceMode: serviceType,
            ContactNo: mobileNo,
            StatsId: parseInt(statsId),
            numofMenuAccess: 1,
            username: Config.linkUserName,
            password: Config.linkPassword,
            notes: JSON.stringify(notes)
        }
        // console.log(posturlObj);
        // Update Contact Detail
        fetch(Config.baseAPIUrl + 'ClickURLDetail', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(posturlObj)
        })
            .then(res => res.json())
            .then(response => {
                this.loadClassficiation();
                // setTimeout(() => {
                //     this.setState({ loading: false })
                //     this.props.history.push('/items', { focusGroup: "focus-mnugrp-focus-mnugrp-recommended" });
                // }, 1500);
            })
            .catch(err => {
                console.log(err);
                this.loadClassficiation();
                // setTimeout(() => {
                //     this.setState({ loading: false })
                //     this.props.history.push('/items', { focusGroup: "focus-mnugrp-focus-mnugrp-recommended" });
                // }, 1500);
                //this.showMessage("CMS - Failed to fetch", "danger");
            });
    }

    goToItems() {
        this.setState({ loading: true });

        // guestLoginAtTheTimeOfPlaceOrder
        if (guestLoggedIn === "false") {
            if (!outletDet.guestLoginAtTheTimeOfPlaceOrder) {
                sessionStorage.setItem("login-page-session", 2); // Item Session      
                setTimeout(() => {
                    if (isappOrders === true) {
                        this.props.history.push('/login' + urlPattern);
                        this.setState({ loading: false });
                        return false;
                    }
                }, Config.minMsgTimeout);

            }
        }

        this.getCMSDetails(true);

    }

    showMessage(msg, type) {
        window.scrollTo(0, 0);
        this.setState({ msgShow: true });
        this.setState({ msgType: type });
        this.setState({ alertMessage: msg });
        this.setState({ loading: false });
        setTimeout(() => {
            this.setState({ msgShow: false });
        }, Config.minMsgTimeout);
    };

    loadwindow() {
        loadcomfn = window.initiate;
        loadcomfn();
    }

    getOutletList() {
        // GetOutlet
        fetch(Config.basePOSUrl + 'GetOutletList', {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                'content-type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(response => {
                //  console.log(response);
                this.setState({ showOutlets: true });
                if (response.errorCode === 0) {
                    this.setState({ outletList: response.response.outletList, serviceTypeList: [] });
                }
                else {
                    this.showMessage(response.message, "danger");
                    this.setState({ outletList: [], serviceTypeList: [] });
                }
            })
            .catch(err => {
                console.log(err);
                this.showMessage('An error occured - ' + err, "danger");
                this.setState({ outletList: [], serviceTypeList: [] });
            });
    }

    getServiceTypeList(outleCode, outletName) {
        sessionStorage.setItem('user-outletcode', outleCode);
        sessionStorage.setItem('user-outletname', outletName);

        sessionStorage.removeItem('cartList');
        sessionStorage.removeItem('modifierCartList');
        sessionStorage.removeItem('taxCartList');
        sessionStorage.removeItem('chargeCartList');

        // GetService Type       
        fetch(Config.basePOSUrl + 'GetOutletServiceTypeList?OutletCode=' + outleCode + '', {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                'content-type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(response => {
                //console.log(response);
                if (response.errorCode === 0) {
                    if (response.response.serviceModeList.length === 1) {
                        if (parseInt(qrLevel) === Config.qrCodeLevel.Outlet) {
                            sessionStorage.setItem('dashboard-hide', "true");
                            this.handleClickServiceType(response.response.serviceModeList[0].serviceType);
                        }
                        else {
                            setTimeout(() => {
                                this.setState({ serviceTypeList: response.response.serviceModeList });
                                this.setState({ showOutlets: false, showServiceTypes: true, loading: false });
                                sessionStorage.setItem('dashboard-hide', "false");
                            }, Config.minMsgTimeout);
                        }
                    }
                    else if (response.response.serviceModeList.length > 1) {
                        setTimeout(() => {
                            this.setState({ serviceTypeList: response.response.serviceModeList });
                            this.setState({ showOutlets: false, showServiceTypes: true, loading: false });
                        }, Config.minMsgTimeout);
                    }
                    else {
                        this.showMessage('Service not available', "danger");
                        this.setState({ serviceTypeList: [], showOutlets: false, showServiceTypes: true, loading: false });
                    }
                }
                else {
                    this.showMessage(response.message, "danger");
                    setTimeout(() => {
                        this.setState({ serviceTypeList: [] });
                        this.setState({ showOutlets: false, showServiceTypes: true, loading: false });
                    }, Config.minMsgTimeout);
                }
            })
            .catch(err => {
                console.log(err);
                this.showMessage('An error occured - ' + err, "danger");
                setTimeout(() => {
                    this.setState({ serviceTypeList: [] });
                    this.setState({ showOutlets: false, showServiceTypes: true, loading: false });
                }, Config.minMsgTimeout);
            });
    }

    outletListDom() {
        return (
            <>
                <div className="clear clear-bottom"></div>
                <div className="outlet-group">
                    <h6>Outlets</h6>
                    <ul>
                        <div className="outlet-list" style={{ padding: "4px 9px" }}>
                            {this.state.outletList.map(o =>
                                <li key={o.outletCode} onClick={this.showOutletServiceType.bind(this, false, o.outletCode, o.outletName)}>
                                    <div className="sol-left">
                                        {o.imageUrl === "" &&
                                            <img src="/img/no-image/no-outlet.png" alt="profile" />
                                        }
                                        {o.imageUrl !== "" &&
                                            <img src={o.imageUrl} alt="lucid-outlet-contact-less" />
                                        }
                                    </div>
                                    <div className="sol-right">
                                        <div className="outlet-name">{o.outletName}</div>
                                        <div className="outlet-detail">{o.cuisineTypes.toString()}
                                        </div>
                                        {o.isVegOutlet &&
                                            <div className="food-type">
                                                <div className="veg">  <img className="food-type-img" src={"/img/f-t-veg.png"} alt="lucid-contactless-app-pos" />  Only Veg</div>
                                            </div>
                                        }
                                        {!o.isVegOutlet &&
                                            <div className="food-type">
                                                <div className="veg">  <img className="food-type-img" src={"/img/f-t-nonveg.png"} alt="lucid-contactless-app-pos" />  Non-Veg</div>
                                            </div>
                                        }
                                    </div>
                                </li>
                            )}

                        </div>
                    </ul>

                </div>
            </>
        )
    }

    showOutletServiceType(outlet, outletCode, outletName) {
        this.setState({ loading: true });
        if (!outlet) this.getServiceTypeList(outletCode, outletName);
        else {
            setTimeout(() => {
                this.setState({ serviceTypeList: [] });
                this.setState({ showOutlets: true, showServiceTypes: false, loading: false });
            }, Config.minMsgTimeout);
        }
    }

    serviceTypeListDom() {
        return (
            <>
                <div className="clear clear-bottom"></div>
                <div className="service-type-group">
                    {parseInt(qrLevel) === Config.qrCodeLevel.Property && <div className="heading-text" onClick={this.showOutletServiceType.bind(this, true)}><i className="fa fa-angle-left" ></i> Back To Outlet</div>}
                    <ul>
                        <div className="service-type-group-list">
                            {this.state.serviceTypeList.map(s =>
                                <li key={s.serviceType} className="order-now" onClick={this.handleClickServiceType.bind(this, s.serviceType, s.serviceTypeName)}>
                                    {s.serviceTypeName}
                                </li>
                            )}
                        </div>
                    </ul>
                </div>
            </>
        )
    }

    handleClickServiceType(serviceType) {

        sessionStorage.setItem('user-servicetype', serviceType);
        sessionStorage.removeItem('cartList');
        sessionStorage.removeItem('modifierCartList');
        sessionStorage.removeItem('taxCartList');
        sessionStorage.removeItem('chargeCartList');

        this.setState({ APIResponseMsg: "Loading...", loading: true, pageLoading: true });
        this.getoutletDetails();
    }

    getoutletDetails() {
        serviceType = sessionStorage.getItem('user-servicetype');
        outletCode = sessionStorage.getItem('user-outletcode');

        let urlParam = '?OutletId=' + propertyId + outletCode + '&ServiceType=' + serviceType;
        // // Get Outlet Details                    
        fetch(Config.basePOSUrl + 'GetOutletDetails' + urlParam, {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                'content-type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(response => {
                //  console.log(response);
                if (response.errorCode === 0) {
                    this.setState({ outletDetails: response.response.outletDetails });
                    // if (response.response.outletDetails.homePageSliderImages !== null && response.response.outletDetails.homePageSliderImages.length > 0) {
                    //     this.setState({ sliderImages: response.response.outletDetails.homePageSliderImages });
                    // }
                    sessionStorage.setItem("user-outletDetails", JSON.stringify(response.response.outletDetails));
                    outletDet = JSON.parse(sessionStorage.getItem("user-outletDetails"));
                    isappOrders = response.response.outletDetails.enableGuestAppOrders;
                    setTimeout(() => {
                        window.scrollTo(0, 0);
                    }, Config.minMsgTimeout);
                    // Digital Menu
                    if (response.response.outletDetails.enableGuestAppOrders === false && response.response.outletDetails.enableDigitalMenu === true) {

                        if (response.response.outletDetails.guestDetailsMandatoryForDigitalMenu === false) {
                            sessionStorage.setItem('user-table-verified', true);
                            sessionStorage.setItem('user-digitalMenu', true);
                            //sessionStorage.setItem('user-mobileno', "0000000000");                           

                            setTimeout(() => {
                                // this.props.history.push('/emhome');
                                this.props.history.push('/home' + urlPattern);
                            }, Config.minMsgTimeout);
                            return false;
                        }
                        if (guestLoggedIn === "false") {
                            setTimeout(() => {
                                this.props.history.push('/login' + urlPattern);
                                this.setState({ loading: false });
                            }, Config.minMsgTimeout);
                            return false;
                        }

                    }

                    // guestLoginAtTheTimeOfPlaceOrder
                    if (guestLoggedIn === "false") {
                        if (!response.response.outletDetails.guestLoginAtTheTimeOfPlaceOrder) {
                            sessionStorage.setItem("login-page-session", 2); // Item Session      
                            setTimeout(() => {
                                this.props.history.push('/login' + urlPattern);
                                this.setState({ loading: false });
                            }, Config.minMsgTimeout);
                            return false;
                        }
                    }

                    //// Table Check                    
                    setTimeout(() => {
                        this.getCMSDetails(true);
                    }, Config.minMsgTimeout);
                }
                else {

                    this.setState({ APIResponseMsg: "Failed to fetch", loading: false });
                }

            })
            .catch(err => {
                this.setState({ APIResponseMsg: "Outlet Details - Failed to fetch", loading: false })
                //  console.log('Outlet Details - Failed to fetch - ' + err);
                this.showMessage('Outlet Details - Failed to fetch - ' + err, "danger");
            });
    }

    // componentWillMount() {
    //     loadjs('../../ assets/js/jquery341.min.js', function () {
    //         loadjs('../../assets/js/theme.js');
    //     });
    // }

    render() {
        return (
            <>
                {this.state.loading && <LoadingAnimation />}
                {this.state.pageLoading && <Loadingpage />}
                <div id="content">
                    {this.state.msgShow && <ErrorAlert alertMsg={this.state.alertMessage} msgType={this.state.msgType} msgShow={this.state.msgShow} />}
                    <Header loggedIn={this.state.loggedIn}
                        mobileApp={this.state.mobileApp}
                        clmenu={isappOrders}
                        pageTitle={this.state.pageTitle}
                    />
                    <div className="content-warp">
                        <div style={{ padding: "0 8px" }}>
                            <Slider {...homeslidersettings} className="img-hero">
                                {this.state.sliderImages.map(img =>
                                    <div key={"slider-img-" + img.sort}>
                                        <img src={img.primaryImageUrl} alt="lucid-slider-contact-less-order" />
                                    </div>
                                )}
                                {/* <div>
                                <img src="../assets/theme1/img/slide1.jpg" alt="slider" />
                            </div>
                            <div>
                                <img src="../assets/theme1/img/slide2.jpg" alt="slider" />
                            </div>
                            <div>
                                <img src="../assets/theme1/img/slide3.jpg" alt="slider" />
                            </div> */}
                            </Slider>
                        </div>
                        {/* <div className="section-home you-might-like">
                        <div className="heading-section">
                            <div className="sa-title popcat">Recipes You Might Like...</div>
                            <div className="clear"></div>
                        </div>
                        <Slider {...ymlcarousel} className="yml-carousel">
                            <div className="yml-box">
                                <div className="yml-img">
                                    <img src="../assets/theme1/img/food9.jpg" alt="you might like" />
                                </div>
                                <div className="yml-food-text">Chicken Fried Steak</div>
                            </div>
                            <div className="yml-box">
                                <div className="yml-img">
                                    <img src="../assets/theme1/img/food10.jpg" alt="you might like" />
                                </div>
                                <div className="yml-food-text">Grilled Fish Tacos</div>
                            </div>
                            <div className="yml-box">
                                <div className="yml-img">
                                    <img src="../assets/theme1/img/food11.jpg" alt="you might like" />
                                </div>
                                <div className="yml-food-text">Steak with Garlic</div>
                            </div>
                            <div className="yml-box">
                                <div className="yml-img">
                                    <img src="../assets/theme1/img/food12.jpg" alt="you might like" />
                                </div>
                                <div className="yml-food-text">Harvest Salad</div>
                            </div>
                            <div className="yml-box">
                                <div className="yml-img">
                                    <img src="../assets/theme1/img/food13.jpg" alt="you might like" />
                                </div>
                                <div className="yml-food-text">Cheesy Pizza</div>
                            </div>
                            <div className="yml-box">
                                <div className="yml-img">
                                    <img src="../assets/theme1/img/food14.jpg" alt="you might like" />
                                </div>
                                <div className="yml-food-text">Teriyaki Chicken</div>
                            </div>
                        </Slider>
                    </div> */}

                        {isappOrders === true &&
                            <div className="section-subscribe">

                                <div className="subcontainer">
                                    <div className="subrow">

                                        <div className="subcol">
                                            {/* <div className="section-title">Popular Categories</div> */}
                                            {/* <p className="textsub">Casual Dining - Andhra, Biryani, North Indian, Chinese</p> */}
                                            {sessionStorage.getItem("user-servicetype") !== "NONE" && <p className="textsub home-param-txt">Service Type  : <span className="home-param-value-txt">{sessionStorage.getItem("user-servicetype") === "FINE-DINE" ? 'Table Service' : sessionStorage.getItem("user-servicetype")}</span></p>}
                                            {sessionStorage.getItem("user-servicetype") === "FINE-DINE" && <p className="textsub home-param-txt">Table No: <span className="home-param-value-txt">{sessionStorage.getItem("user-tableno")}</span></p>}
                                            {(this.state.groupPin !== "" && this.state.groupPin != null) && <p className="textsub home-param-txt">Group Pin: <span className="home-param-value-txt"> {this.state.groupPin}</span></p>}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        <div className="home-menu box-menu">
                            <ul className="list-unstyled">
                                {/* <span style={{textAlign:"center",color: "#6c757d87",fontSize:"12px"}}><h1>Loading...</h1></span> */}
                                {this.state.getMenus.length === 0 &&
                                    <span style={{ textAlign: "center", color: "#6c757d87", fontSize: "12px" }}>
                                        <h1>
                                            <div>{this.state.APIResponseMsg}</div>
                                            <div>
                                                {/* {this.state.APIResponseMsg === "Failed to fetch" ? <div onClick={this.getDetails} style={{ marginLeft: "18px", fontSize: "20px", width: "50%", float: "right", marginTop: "-22px" }} ><i className="fas fa-sync-alt"></i></div> : <div style={{ marginLeft: "18px", fontSize: "20px", width: "50%", float: "right", marginTop: "-22px" }}><i className="fas fa-sync-alt fa-spin"></i></div>} */}
                                            </div>
                                        </h1>
                                    </span>
                                }
                                {
                                    this.state.APIResponseMsg === "" && serviceType !== "NONE" &&
                                    <div className={"order-now" + (isappOrders === false ? " order-now-dm" : "")} onClick={() => this.goToItems()}>{(isappOrders === true) ? "Order Now" : "Go To Menu"}</div>
                                }
                                {
                                    this.state.pagePreview !== "" &&
                                    <div className="home-content" dangerouslySetInnerHTML={{ __html: this.state.pagePreview }}></div>
                                }

                                {this.state.showOutlets && this.outletListDom()}
                                {this.state.showServiceTypes && this.serviceTypeListDom()}

                                {this.state.groupMemberList.length > 0 &&
                                    <div className="group-content">
                                        <h6>Group Members</h6>
                                        <div className="group-list">
                                            <ul>
                                                {
                                                    this.state.groupMemberList.map(m =>
                                                        <li className="people" key={m.guestMobileNumber}>
                                                            {m.guestName === '' && <div> <i className="fas fa-mobile-alt"></i> <span className="people-content">{m.guestMobileNumber}{m.isMainGuest && <span className="people-main"> (Main)</span>}{(m.guestMobileNumber === mobileNo && !m.isMainGuest) && <span> (You)</span>}</span></div>}
                                                            {m.guestName !== '' && <div> <i className="fas fa-user-alt"></i> <span className="people-content">{m.guestName}{m.isMainGuest && <span className="people-main"> (Main)</span>}{(m.guestMobileNumber === mobileNo && !m.isMainGuest) && <span> (You)</span>}</span></div>}
                                                        </li>

                                                    )
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                }
                                {/* {
                                    this.state.getMenus.map(grp =>
                                        <li key={grp.menuGroupId} onClick={() => this.goToItems(grp.menuGroupId, grp.menuGroupName)}>
                                            <NavLink to="/items" className="dish-list-link">
                                                <div className="dish-list">
                                                    <div className="dish-list-tbl-cell">
                                                        <img src="../assets/theme1/img/item/menu-icon.png" className="img-fluid" alt="dish-menu" />
                                                        <div className="dish-list-text">
                                                            <h4>{grp.menuGroupName}</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </NavLink>
                                        </li>
                                    )} */}

                                {this.state.bannerImgs.length > 0 &&
                                    this.state.bannerImgs.map(img =>
                                        <div className="home-banner" key={"bannerh-" + img.imageId}>
                                            <img src={img.primaryImageUrl} alt="banner" key={"banner-" + img.imageId} />
                                        </div>
                                    )
                                }
                            </ul>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}