import React, { Component } from 'react';
import Iframe from 'react-iframe';
import './myorders.scss';
import { isMobile } from 'react-device-detect';
import sHelpers from '../../modules/helpers/statichelpers';
import LoadingAnimation from '../../components/loadinganimation';
import ErrorAlert from '../../components/alertmessages';
import Config from '../../data/SiteConfig';
let guestLoggedIn = "false";
let urlPattern = '';
export default class ViewBill extends Component {
    constructor(props) {
        super(props)
        this.goBack = this.goBack.bind(this);
        this.handleProcessRequest = this.handleProcessRequest.bind(this);
        this.state = {
            loading: false,
            alertMessage: '',
            msgShow: false,
            eBillUrl: '',
            isPay: false,
            billAmount: 0,
            posBillId: '',
            refBillId: '',
            iFrameHeight: '900px'
        }
    }


    componentWillMount() {

        const sessionDt = sessionStorage.getItem("u-l-v-url-dt");
        if (!sessionDt) {
            this.props.history.push('/sessionerror' + urlPattern);
            return false;
        }
        urlPattern =   sessionStorage.getItem('url-pattern');
        if (!urlPattern) {        
            this.props.history.push('/qrcodeerror' + Config.defaultUrlPattern);
            return false;
          }
        if (this.props.location.state !== undefined) {
            //            console.log(this.props.location.state.eBillUrl);
            if (this.props.location.state.eBillUrl === undefined || this.props.location.state.eBillUrl === null || this.props.location.state.eBillUrl === '') {
                this.setState({ loading: true });
                setTimeout(() => {
                    this.setState({ loading: false });
                    this.props.history.push('/myorders' + urlPattern);
                }, Config.minMsgTimeout);
            }
            else if (this.props.location.state.billAmount === undefined || this.props.location.state.billAmount === null || this.props.location.state.billAmount === '') {
                this.setState({ loading: true });
                setTimeout(() => {
                    this.setState({ loading: false });
                    this.props.history.push('/myorders' + urlPattern);
                }, Config.minMsgTimeout);
            }
            else if (this.props.location.state.eBillUrl === undefined || this.props.location.state.eBillUrl === null || this.props.location.state.eBillUrl === '') {
                this.setState({ loading: true });
                setTimeout(() => {
                    this.setState({ loading: false });
                    this.props.history.push('/myorders' + urlPattern);
                }, Config.minMsgTimeout);
            }
            else if (this.props.location.state.posBillId === undefined || this.props.location.state.posBillId === null || this.props.location.state.posBillId === '') {
                this.setState({ loading: true });
                setTimeout(() => {
                    this.setState({ loading: false });
                    this.props.history.push('/myorders' + urlPattern);
                }, Config.minMsgTimeout);
            }
            else if (this.props.location.state.refBillId === undefined || this.props.location.state.refBillId === null || this.props.location.state.refBillId === '') {
                this.setState({ loading: true });
                setTimeout(() => {
                    this.setState({ loading: false });
                    this.props.history.push('/myorders' + urlPattern);
                }, Config.minMsgTimeout);
            }
            else {
                this.setState({
                    eBillUrl: this.props.location.state.eBillUrl,
                    billAmount: Number((this.props.location.state.billAmount)),
                    isPay: this.props.location.state.isPay,
                    posBillId: this.props.location.state.posBillId,
                    refBillId: this.props.location.state.refBillId,
                });
            }
        }
        else {
            //console.log('jk');
            this.setState({ loading: true });
            setTimeout(() => {
                this.setState({ loading: false });
                this.props.history.push('/myorders' + urlPattern);
            }, Config.minMsgTimeout);
        }


    }

    componentDidMount() {
        guestLoggedIn = sessionStorage.getItem('user-loggedin') == null ? "false" : sessionStorage.getItem('user-loggedin');
        if (Config.IsMobileAccess) {
            if (!isMobile) {
                this.props.history.push('/deviceerror' + urlPattern);
                return false;
            }
        }
        if (!sHelpers.inputParamValidation()) {
            this.props.history.push('/' + urlPattern);
        }
        this.setState({ loading: true });

        window.scrollTo(0, 0);

        if (guestLoggedIn === "false") {
            setTimeout(() => {
                this.setState({ loading: false });
                this.props.history.push('/items' + urlPattern);
            }, Config.minMsgTimeout);
            return false;
        }

    }

    resizeIframe(obj) {
        //  obj.style.height = obj.contentWindow.document.documentElement.scrollHeight + 'px';
        this.setState({ loading: false });
    }

    goBack() {
        this.props.history.push('/myorders' + urlPattern);
    }

    handleProcessRequest() {
        this.setState({ loading: true });
        setTimeout(() => {
            this.props.history.push('/settleprocessrequest' + urlPattern, { eBillUrl: this.state.eBillUrl, totalNetAmount: this.state.billAmount, isPay: this.state.isPay, posBillId: this.state.posBillId, refBillId: this.state.refBillId });
        }, Config.minMsgTimeout);
    }
    render() {
        return (
            <>
                {this.state.loading && <LoadingAnimation />}
                {this.state.msgShow && <ErrorAlert alertMsg={this.state.alertMessage} msgType={this.state.msgType} msgShow={this.state.msgShow} />}
                <div id="content" className="top">
                    <div className="content-warp">
                        <div className="section-myorders">
                            <div className="heading-page" ref="recommended">
                                {this.state.isPay && <span style={{ fontSize: "14px", fontWeight: 600 }}>₹{this.state.billAmount.toFixed(2)}</span>}
                                {!this.state.isPay && <span style={{ fontSize: "14px", fontWeight: 600 }}>View Bill</span>}
                                <div className="back-btn">
                                    <div className="icon-back" onClick={this.goBack}><i className="fa fa-angle-left" ></i></div>
                                </div>
                                {this.state.isPay && <div className="proceed-pay" onClick={this.handleProcessRequest}><span><img className="bill-pay" src="/img/rupee.png" alt="lucid-rupee" /></span><span> Proceed to Pay</span></div>}
                            </div>
                            <div className="content-from">

                                <Iframe url={this.state.eBillUrl + "?apv=1"}
                                    width="100%"
                                    height="100%"
                                    id="myId"
                                    className="iframe-view"
                                    display="initial"
                                    position="relative"
                                    border="0"
                                    onLoad={this.resizeIframe.bind(this)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    };

}