import React, { Component } from 'react';
import './message.scss';
import Config from '../../data/SiteConfig';


export default class MessageAlert extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showHide: false
        }
    }
    componentDidMount() {
        this.setState({ showHide: this.props.msgShow });
        setTimeout(() => {
            this.setState({ showHide: false });
        }, Config.averageMsgTimeout);
    };

    render() {
        return (
            <div>
                {/* <div className="alert alert-danger" role="alert">
              This is a danger alert—check it out!
            </div> */}
                {this.state.showHide && (
                    <div className="toast fade show placement-toasts show-message" id="msg-toast" role="alert" aria-live="assertive" aria-atomic="true" >
                        {/* <div className="toast-header">                                
                        <strong className="mr-auto">Error</strong>
                        <small>11 mins ago</small>
                        <button type="button" className="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div> */}
                        <div className="toast-body">
                            {this.props.msgType === "success" && <span className="text-success"><i className="fas fa-exclamation-circle"></i> {this.props.alertMsg}</span>}
                            {this.props.msgType === "danger" && <span className="text-danger"><i className="fas fa-exclamation-circle"></i> {this.props.alertMsg}</span>}
                            {this.props.msgType === "info" && <span className="text-dark"><i className="fas fa-exclamation-circle"></i> {this.props.alertMsg}</span>}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
