import React, { Component } from 'react';
import './popupmenus.scss';
export default class PopupMenus extends Component {
    constructor(props) {
        super(props)
        this.goCart = this.goCart.bind(this);
        this.state = {
            showHide: false,
            recommendedList: [],
            getMenus: [],
            activeMenuGroup: -1,
        }
    }

    componentDidMount() {
        this.setState({ recommendedList: this.props.recommendedList });
        this.setState({ getMenus: this.props.getMenus });
    };

    handleMenuGroupClick(index, mnuid) {
        this.setState({
            activeMenuGroup: index,
        });
        //  window.location.hash = '#' + mnuid;           
        var stickyContainer = document.getElementById("" + mnuid + "");
        //console.log(stickyContainer.offsetTop);
        // window.scrollTo(0, stickyContainer.offsetTop);
        window.scrollTo({
            top: stickyContainer.offsetTop,
            left: 0,
            behavior: 'smooth'
        });
    }

    goCart() {
        this.props.history.push('/cart');
    }

    render() {
        return (
            <div>
                <div className="menu-goup" >
                    <button className="theme-button btn em-popup-menugroup-btn" style={this.props.stylePopupMenubBtn} data-toggle="modal" data-target="#filter-menu-group-modal"><i className="fa fa-utensils sidebar-icon"></i> MENU</button>
                </div>
                {/* Filter Menu Group Popup*/}
                <div className="modal custom-modal fade" id="filter-menu-group-modal" role="dialog" >
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content custom-modal-content animate-bottom">
                            {/* <div class="modal-header">
                                            <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div> */}
                            <div className="modal-body custom-modal-body">
                                <ul>
                                    {this.props.getMenus.map((itmgrp, idx) =>
                                        <li onClick={this.handleMenuGroupClick.bind(this, idx, "focus-mnugrp-" + itmgrp.menuGroupId)} key={"filter-mnu-grp-" + idx} data-dismiss="modal" id={"focus-mnugrp-" + itmgrp.menuGroupId}>
                                            <span className={idx === this.state.activeMenuGroup ? "em-popup-menugroup-clist" : "em-popup-menugroup-list"} style={this.props.stylePopupText} >{itmgrp.menuGroupName.toLowerCase()}</span>
                                            <span className={idx === this.state.activeMenuGroup ? "ccount" : "count"} >{itmgrp.noOfItems}</span>
                                            {/* <span className="count">{this.state.getItems.filter(itm => itm.menuGroupId === itmgrp.menuGroupId).length}</span> */}
                                        </li>
                                    )}
                                </ul>
                            </div>
                            {/* <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                            <button type="button" class="btn btn-primary">Save changes</button>
                                        </div> */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}