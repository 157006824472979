import React, { Component } from 'react';
import './request.scss';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Config from '../../data/SiteConfig';
export default class Request extends Component {
    constructor(props) {
        super(props)
        this.handleRequest = this.handleRequest.bind(this);
        this.state = {
            //  loading: false,
            // alertMessage: '',
            //  msgShow: false,
            guestRequestList: [],
            requestMasterList: []
        }

    }

    handleRequest() {
        this.getGuestRequestList();
        this.getRequestMasterList();
        this.inputElement.click();
    }

    getRequestMasterList() {
        // this.setState({ loading: true });
        let param = 'Module=POS&OutletId=' + this.props.propertyId + this.props.outletCode + '&ServiceType=' + this.props.serviceType;
        fetch(Config.baseGuestUrl + 'GetGuestRequestMasterData?' + param, {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + this.props.token,
                'content-type': 'application/json'
            },
        })
            .then(res => res.json())
            .then(response => {
                //  this.setState({ loading: false });
                //  console.log(response);
                if (response.errorCode === 0) {
                    if (response.response != null) {
                        this.setState({ requestMasterList: response.response.requestNames });
                    }
                    else {
                        this.setState({ requestMasterList: [] });
                        //  this.showMessage(response.message, "danger");
                    }
                }
                else {
                    this.setState({ requestMasterList: [] });
                    //  this.showMessage(response.message, "danger");
                }

            })
            .catch(err => {
                this.setState({ requestMasterList: [] });
                // this.showMessage('Request Master List - Failed to fetch - ' + err, "danger");
            });
    };

    getGuestRequestList() {
        this.setState({ loading: true });
        let param = 'OutletId=' + this.props.propertyId + this.props.outletCode + '&ServiceType=' + this.props.serviceType + '&GuestMobileNo=' + this.props.mobileNo;
        fetch(Config.baseGuestUrl + 'GetGuestRequest?' + param, {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + this.props.token,
                'content-type': 'application/json'
            },
        })
            .then(res => res.json())
            .then(response => {
                // this.setState({ loading: false });
                //  console.log(response);
                if (response.errorCode === 0) {
                    if (response.response != null) {
                        this.setState({ guestRequestList: response.response.guestRequest });
                    }
                    else {
                        this.setState({ guestRequestList: [] });
                        //   this.showMessage(response.message, "danger");
                    }
                }
                else {
                    this.setState({ guestRequestList: [] });
                    //  this.showMessage(response.message, "danger");
                }

            })
            .catch(err => {
                this.setState({ guestRequestList: [] });
                // this.showMessage('Guest Request List - Failed to fetch - ' + err, "danger");
            });
    };

    handleSaveGuestRequest(requestName) {
        // this.setState({ loading: true });
        let postObj = {
            OutletId: this.props.propertyId + this.props.outletCode,
            RequestType: 0,
            ServiceType: this.props.serviceType,
            TableNumber: this.props.serviceType === "FINE-DINE" ? this.props.tableNo : "0",
            OrderId: "",
            BillId: "",
            GuestMobileNo: this.props.mobileNo,
            RequestName: requestName,
            Remarks: "",
        }

        // console.log(postObj);

        fetch(Config.baseGuestUrl + 'SaveGuestRequest', {
            method: 'POST',
            headers: {
                Authorization: "Bearer " + this.props.token,
                'content-type': 'application/json'
            },
            body: JSON.stringify(postObj)
        })
            .then(res => res.json())
            .then(response => {
                //  console.log(response);
                if (response.errorCode === 0) {
                    // this.showMessage(response.message, "success");
                    // this.setState({ loading: false });
                    this.props.callBackRequest(response.message, 1);
                    //this.getGuestRequestList();
                }
                else {
                    //  console.log(response.message);
                    //  this.setState({ loading: false });
                    this.props.callBackRequest('Error request was not placed', 0);
                    // this.showMessage('Error request was not placed', "danger");                   
                }
            })
            .catch(err => {
                //   console.log(err);
                ///  this.setState({ loading: false });
                this.props.callBackRequest('Error request was not placed', 0);
                // this.showMessage('Error request was not placed', "danger");               
            });
    };


    render() {
        return (
            <>
                <div className="section-request">
                    <div className="menu-goup">
                        <button className="theme-button btn" style={{ display: "none" }} ref={input => this.inputElement = input} data-toggle="modal" data-target="#request-modal"><i className="fa fa-utensils sidebar-icon"></i> MENU1</button>
                    </div>
                    {/* Show Modifier*/}
                    <div className="modal fade" id="request-modal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content animate-bottom">
                                <div className="modal-header">
                                    <h5 className="modal-title">
                                        Help
                                    </h5>

                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body" id="div-request-body">
                                    <Tabs>
                                        <TabList>
                                            <Tab>Help  <i className="fa fa-hand-paper" style={{ fontSize: "16px", color: "#d12f8b" }}></i></Tab>
                                            <Tab>My Request <span>({this.state.guestRequestList.length})</span>
                                                <div className="refresh-icon" onClick={this.getGuestRequestList.bind(this)}> <i className={this.state.refreshSpin === 0 ? "fas fa-sync-alt " : "fas fa-sync-alt "}></i></div>
                                            </Tab>
                                        </TabList>
                                        <TabPanel>
                                            <div className="help-list">
                                                <ul>
                                                    {
                                                        this.state.requestMasterList.map((req, idx) =>
                                                            <li key={"request-" + idx}>
                                                                <div className="content request">
                                                                    <div onClick={this.handleSaveGuestRequest.bind(this, req)} data-dismiss="modal">
                                                                        <div className="help-heading"><span className="help-type">{req}</span></div>
                                                                        <i className="fa fa-angle-right right-btn" ></i>
                                                                    </div>

                                                                </div>
                                                            </li>

                                                        )
                                                    }
                                                </ul>
                                            </div>
                                            {this.state.requestMasterList.length === 0 &&
                                                <div>
                                                    <div className="no-data-img"><img src="/img/no-image/no-data.png" alt="lucid-no-orders" /></div>
                                                </div>
                                            }
                                        </TabPanel>
                                        <TabPanel>
                                            <div className="help-list">
                                                <ul>
                                                    {
                                                        this.state.guestRequestList.map((req, idx) =>
                                                            <li key={"guest-request-" + idx}>
                                                                <div className="content request">
                                                                    <div className="help-heading"><span className="help-type">{req.requestName}</span></div>
                                                                    {/* <i className="fa fa-angle-right right-btn" ></i> */}
                                                                    <div className="help">
                                                                        {req.remarks}
                                                                    </div>
                                                                    {req.status === "Open" && <div className="status-open">{req.status}</div>}
                                                                    {req.status === "Processing" && <div className="status-process">{req.status}</div>}
                                                                    {req.status === "Acknowledge" && <div className="status-process">{req.status}</div>}
                                                                    {req.status === "Closed" && <div className="status-closed">{req.status}</div>}
                                                                    {req.status === "Cancelled" && <div className="status-cancelled">{req.status}</div>}
                                                                    <div className="request-time"><i className="fa fa-clock" aria-hidden="true"></i> Updated Time {req.statusUpdatedTime}</div>
                                                                    {req.reason !== "" &&
                                                                        <div className="help">
                                                                            <span className="request-time">Reason: </span>{req.reason}
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </li>

                                                        )
                                                    }
                                                </ul>
                                            </div>
                                            {this.state.guestRequestList.length === 0 &&
                                                <div>
                                                    <div className="no-data-img"><img src="/img/no-image/no-data.png" alt="lucid-no-orders" /></div>
                                                </div>
                                            }

                                        </TabPanel>
                                    </Tabs>
                                    {/* {this.handleGenerateModifierDom()} */}
                                    {/* <ul className="modifier-group-li">
                                        {this.props.modifierGroupIds.map(mid =>
                                            <div key={"modifier-group-id-" + mid}>
                                                <li key={mid}>
                                                    {this.props.modifierList.filter(mgrplst => mgrplst.modifierGroupId === mid).map(mgrp =>
                                                        <div key={"modifier-group-" + mgrp.modifierGroupId}>
                                                            <span className="group-name">{mgrp.minimumSelection > 0 && <span className="minimum-selection">*</span>}{mgrp.groupName}</span>

                                                            {mgrp.maximumSelection === 0 &&
                                                                <span> (<span id={"min-group-modifier-cnt-" + mgrp.modifierGroupId}>0</span>)</span>
                                                            }
                                                            {mgrp.maximumSelection > 0 &&
                                                                <span> (
                                                                <span id={"min-group-modifier-cnt-" + mgrp.modifierGroupId}>0</span>
                                                                /
                                                                <span id={"max-group-modifier-cnt-" + mgrp.modifierGroupId}>{mgrp.maximumSelection}</span>
                                                                )</span>
                                                            }

                                                            <ul className="modifier-li">
                                                                {mgrp.modifiers.map(mlst =>
                                                                    <li key={"modifier-li-" + mlst.modifierId}>
                                                                        {mgrp.maximumSelection === 2 && <Checkbox name={mlst.modifierName} id={"modifier-chk-grp-" + mgrp.modifierGroupId + "-" + mlst.modifierId} checked={this.state.checkedItems.get(mlst.modifierName)} onChange={(e) => this.handleChange(e, mlst.modifierId, mlst.modifierName, mgrp.modifierGroupId, mgrp.groupName, this.props.modifierItemCItemId, mlst.rate, mgrp.minimumSelection, mgrp.maximumSelection, mlst.foodType, mlst.taxId, mlst.chargeIds)}></Checkbox>}
                                                                        {mgrp.maximumSelection === 1 && <Radio name={mgrp.modifierGroupId} id={"modifier-chk-grp-" + mgrp.modifierGroupId + "-" + mlst.modifierId} checked={this.state.radiostatus} onChange={(e) => this.handleRadioChange(e, mlst.modifierId, mlst.modifierName, mgrp.modifierGroupId, mgrp.groupName, this.props.modifierItemCItemId, mlst.rate, mgrp.minimumSelection, mgrp.maximumSelection, mlst.foodType, mlst.taxId, mlst.chargeIds)}></Radio>}
                                                                        {mgrp.maximumSelection === 0 && <Checkbox name={mlst.modifierName} id={"modifier-chk-grp-" + mgrp.modifierGroupId + "-" + mlst.modifierId} checked={this.state.checkedItems.get(mlst.modifierName)} onChange={(e) => this.handleChange(e, mlst.modifierId, mlst.modifierName, mgrp.modifierGroupId, mgrp.groupName, this.props.modifierItemCItemId, mlst.rate, mgrp.minimumSelection, mgrp.maximumSelection, mlst.foodType, mlst.taxId, mlst.chargeIds)}></Checkbox>}
                                                                        {mlst.foodType === 1 && <img className="food-type-img" src={"../assets/theme1/img/f-t-veg.png"} alt="item" />}
                                                                        {mlst.foodType === 2 && <img className="food-type-img" src={"../assets/theme1/img/f-t-nonveg.png"} alt="item" />}
                                                                        {mlst.modifierName}<span className="rate-inr"> ₹{mlst.rate}</span>
                                                                    </li>

                                                                )
                                                                }
                                                            </ul>
                                                        </div>
                                                    )}
                                                </li>
                                            </div>
                                        )}
                                    </ul> */}
                                </div>
                                {/* <div className="modal-footer">
                                    <div className="cart" >
                                        <div className="c-left"> Item Total  ₹<span >110</span></div>
                                        <div className="c-right" data-dismiss="modal">Add Item</div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div >
            </>

        );
    }
}
