import React from 'react'
import './animate.scss';
export default function failed() {
    return (
        <div style={{textAlign: "center"}}>
            <svg id="failureAnimation" className="animated" xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 70 70">
                <circle id="failureAnimationCircle" cx="35" cy="35" r="24" stroke="#ec091f" stroke-width="2" stroke-linecap="round" fill="transparent" />
                <polyline className="failureAnimationCheckLine" stroke="#ec091f" stroke-width="2" points="25,25 45,45" fill="transparent" />
                <polyline className="failureAnimationCheckLine" stroke="#ec091f" stroke-width="2" points="45,25 25,45" fill="transparent" />
            </svg>
        </div>
    )
}