import React, { Component, Fragment } from 'react';
import './settleprocess.scss';
//import LoadingAnimation from '../../components/loadinganimation/paymentloading';
import LoadingAnimation from '../../components/loadinganimation';
import Config from '../../data/SiteConfig';
import ErrorAlert from '../../components/alertmessages';
import { isMobile } from 'react-device-detect';
import sHelpers from '../../modules/helpers/statichelpers';
//import uuid from 'react-uuid';
let mobileNo = '';
let token = '';
let propertyId = '';
let outletCode = '';
let olDetail = {};
let outletDetails = null;
let mainGuestMobileNo = '';
let urlPattern = '';
export default class SettleProcessRequest extends Component {
    constructor(props) {
        super(props);
        this.goBack = this.goBack.bind(this);
        this.gotoThirdParty = this.gotoThirdParty.bind(this);
        this.state = {
            cartValue: 0,
            paymentList: [
                { vendorId: 3, vendorName: "Razorpay", imgUrl: "/img/payment/razorpay.png" },
                { vendorId: 2, vendorName: "Instamojo", imgUrl: "/img/payment/instamojo.png" },
                // { paymentId: 2, paymentName: "CCAvenue", imgUrl: "../assets/theme1/img/payment/ccavenue.png" },
                // { paymentId: 3, paymentName: "Paytm", imgUrl: "../assets/theme1/img/payment/paytm.png" },
                // { paymentId: 4, paymentName: "GPay", imgUrl: "../assets/theme1/img/payment/gpay.png" },
                // { paymentId: 5, paymentName: "PhonePe", imgUrl: "../assets/theme1/img/payment/phonepe.png" },
            ],
            loading: false,
            alertMessage: '',
            msgShow: false,
            totalNetAmount: 0,
            vendorKey: '',
            vendorTransactionId: '',
            vendorOrderId: '',
            paymentVendorLogo: '',
            eBillUrl: '',
            isPay: false,
            posBillId: ''
        }
    }

    componentWillMount() {
        if (Config.IsMobileAccess) {
            if (!isMobile) {
                this.props.history.push('/deviceerror' + urlPattern);
                return false;
            }
        }

        mobileNo = sessionStorage.getItem('user-mobileno');
        token = sessionStorage.getItem('user-token');
        propertyId = sessionStorage.getItem('user-propertyid');
        outletCode = sessionStorage.getItem('user-outletcode');
        outletDetails = sessionStorage.getItem('user-outletDetails');
        mainGuestMobileNo = sessionStorage.getItem('main-guest-no') == null ? '' : sessionStorage.getItem('main-guest-no');
        urlPattern = sessionStorage.getItem('url-pattern');

        const sessionDt = sessionStorage.getItem("u-l-v-url-dt");
        if (!sessionDt) {
            this.props.history.push('/sessionerror' + urlPattern);
            return false;
        }

        if (!urlPattern) {
            this.props.history.push('/qrcodeerror' + Config.defaultUrlPattern);
            return false;
        }
        if (!sHelpers.inputParamValidation()) {
            this.props.history.push('/' + urlPattern);
        }


        if (outletCode === undefined || outletCode === "" || outletCode === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (outletDetails === undefined || outletDetails === "" || outletDetails === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        else {
            olDetail = JSON.parse(outletDetails);
            this.setState({ paymentVendorLogo: olDetail.outletImageUrl !== '' ? olDetail.outletImageUrl : "https://cdn.razorpay.com/logos/BUVwvgaqVByGp2_large.png" });
            if (olDetail.paymentVendors.length === 1) // Single Vendor to direct
                this.gotoThirdParty(olDetail.paymentVendors[0].vendorId, olDetail.paymentVendors[0].vendorName);
        }
        if (this.props.location.state !== undefined) {
            if (this.props.location.state.totalNetAmount === undefined || this.props.location.state.totalNetAmount === null || this.props.location.state.totalNetAmount === 0) {
                this.setState({ loading: true });
                setTimeout(() => {
                    this.setState({ loading: false });
                    this.props.history.push('/myorders' + urlPattern);
                }, Config.minMsgTimeout);
            }
            else if (this.props.location.state.eBillUrl === undefined || this.props.location.state.eBillUrl === null || this.props.location.state.eBillUrl === '') {
                this.setState({ loading: true });
                setTimeout(() => {
                    this.setState({ loading: false });
                    this.props.history.push('/myorders' + urlPattern);
                }, Config.minMsgTimeout);
            }
            else if (this.props.location.state.refBillId === undefined || this.props.location.state.refBillId === null || this.props.location.state.refBillId === '') {
                this.setState({ loading: true });
                setTimeout(() => {
                    this.setState({ loading: false });
                    this.props.history.push('/myorders' + urlPattern);
                }, Config.minMsgTimeout);
            }
            else {
                if (this.props.location.state.totalNetAmount === 0) {
                    this.setState({ loading: true });
                    setTimeout(() => {
                        this.setState({ loading: false });
                        this.props.history.push('/myorders' + urlPattern);
                    }, Config.minMsgTimeout);
                }
                // console.log(this.props.location.state.posBillId);
                this.setState({
                    totalNetAmount: this.props.location.state.totalNetAmount,
                    eBillUrl: this.props.location.state.eBillUrl,
                    isPay: this.props.location.state.isPay,
                    posBillId: this.props.location.state.refBillId
                })
            }
        }
        else {
            this.setState({ loading: true });
            setTimeout(() => {
                this.setState({ loading: false });
                this.props.history.push('/myorders' + urlPattern);
            }, Config.minMsgTimeout);
        }
        window.scrollTo(0, 0);
    }



    submitClick = (e) => {
        e.preventDefault();

        // e.preventDefault();
        e.click();
        //this.inputElement.click();
    }

    goBack() {
        //this.props.history.goBack();
        this.props.history.push('/viewbill' + urlPattern, { eBillUrl: this.state.eBillUrl, billAmount: this.state.totalNetAmount, isPay: this.state.isPay, posBillId: this.state.posBillId });
    }
    gotoThirdParty(vendorId, vendorName) {
        //e.preventDefault();
        this.setState({ loading: true });
        setTimeout(() => {
            var postObj = {
                OutletId: propertyId + outletCode,
                PaymentVendorId: vendorId,
                Amount: this.state.totalNetAmount.toFixed(2),
                GuestName: sessionStorage.getItem("guest-name") === null ? ' ' : sessionStorage.getItem("guest-name"),
                MobileNoCountryCode: "91",
                // MobileNo: mainGuestMobileNo,
                MobileNo: (mainGuestMobileNo === '' || mainGuestMobileNo === "0000000000") ? mobileNo : mainGuestMobileNo,
                EmailId: "",
                OrderId: "",
                BillId: this.state.posBillId,
                RedirectUrl: Config.redirectBillPaidUrl
            }
            // console.log(postObj);
            // API Call
            fetch(Config.basePaymentUrl + 'InitiateOnlinePayment', {
                method: 'POST',
                headers: {
                    Authorization: "Bearer " + token,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(postObj)
            })
                .then(res => res.json())
                .then(response => {
                    if (response.errorCode === 0) {
                        //  console.log(response);
                        setTimeout(() => {
                            sessionStorage.setItem("paymentInitiatedId", response.response.paymentInitiatedId);
                            sessionStorage.setItem("vendorTransactionId", response.response.vendorTransactionId);
                            sessionStorage.setItem("vendorId", vendorId);
                            sessionStorage.setItem("vendorName", vendorName);
                            sessionStorage.setItem("paymentStatus", response.response.status);
                            sessionStorage.setItem("payAmount", this.state.totalNetAmount.toFixed(2));
                            sessionStorage.setItem("ebillUrl", this.state.eBillUrl);
                            sessionStorage.setItem("isPay", this.state.isPay);
                            sessionStorage.setItem("posBillId", this.state.posBillId);
                            if (vendorId === 2) {        // instamojo                         
                                window.location.href = response.response.vendorRedirectUrl;
                                sessionStorage.setItem('redirect-page', "settleprocessverification");
                            }
                            else if (vendorId === 3) { // Razor Pay
                                this.setState({
                                    vendorTransactionId: response.response.vendorTransactionId,
                                    vendorKey: response.response.vendorKey
                                })
                                setTimeout(() => {
                                    sessionStorage.setItem('redirect-page', "settleprocessverification");
                                    this.inputElement.click();
                                }, Config.minMsgTimeout);
                            }
                            else {
                                this.setState({ loading: true });
                                this.showMessage('OOPS! Initial transaction failed. Please try again', "danger");
                                setTimeout(() => { this.setState({ loading: false }); this.props.history.push('/viewbill' + urlPattern, { eBillUrl: this.state.billUrl, billAmount: this.state.totalNetAmount, isPay: this.state.isPay, posBillId: this.state.posBillId }); }, 1500);
                            }
                            //window.location.href ="https://www.instamojo.com/@gatewayofficeparks/c57b613d098242c4adf6893321a78b35";
                            // this.props.history.push('/paymentVerification?payment_status=Failed');
                        }, Config.minMsgTimeout);
                    }
                    else {
                        //  console.log(response.message);
                        this.setState({ loading: true });
                        this.showMessage('OOPS! Initial transaction failed [' + response.message + ']. Please try again', "danger");
                        setTimeout(() => { this.setState({ loading: false }); this.props.history.push('/viewbill' + urlPattern, { eBillUrl: this.state.billUrl, billAmount: this.state.totalNetAmount, isPay: this.state.isPay, posBillId: this.state.posBillId }); }, 1500);
                    }
                })
                .catch(err => {
                    //  console.log(err);
                    this.setState({ loading: true });
                    this.showMessage('OOPS! Initial transaction failed. Please try again', "danger");
                    setTimeout(() => { this.setState({ loading: false }); this.props.history.push('/viewbill' + urlPattern, { eBillUrl: this.state.billUrl, billAmount: this.state.totalNetAmount, isPay: this.state.isPay, posBillId: this.state.posBillId }); }, 1500);
                });
        }, Config.minMsgTimeout);


    }
    showMessage(msg, type) {
        window.scrollTo(0, 0);
        this.setState({ msgShow: true });
        this.setState({ msgType: type });
        this.setState({ alertMessage: msg });
        this.setState({ loading: false });
        setTimeout(() => {
            this.setState({ msgShow: false });
        }, Config.minMsgTimeout);
    };

    render() {

        return (
            <div> {this.state.loading && <LoadingAnimation />}
                <div id="content" className="top">
                    {this.state.msgShow && <ErrorAlert alertMsg={this.state.alertMessage} msgType={this.state.msgType} msgShow={this.state.msgShow} />}
                    <div className="content-warp">
                        <div className="section-settleprocess">
                            <div className="heading-page" ref="recommended">
                                Payments
                                <span className="payment-txt">To pay: ₹{this.state.totalNetAmount.toFixed(2)} </span>
                                <div className="back-btn">
                                    <div className="icon-back" onClick={this.goBack}><i className="fa fa-angle-left" ></i></div>
                                </div>
                            </div>
                            <div className="content-from m-t-contact-form">
                                <div className="gateway-details">
                                    {
                                        this.state.paymentList.map((pay, i) => {
                                            return (
                                                <Fragment key={"pay-main-div-" + pay.vendorId}>
                                                    {
                                                        olDetail.paymentVendors.length > 1 &&
                                                        olDetail.paymentVendors.filter(x => x.vendorId === pay.vendorId).length > 0 &&
                                                        // <div className="content" key={"pay-div-" + pay.vendorId} onClick={(e) => this.gotoThirdParty(e, pay.vendorId, pay.vendorName)}><span><img src={pay.imgUrl} alt="" /></span><span className="payment-name">{pay.vendorName}</span></div>

                                                        <div className="content" key={"pay-div-" + pay.vendorId} onClick={this.gotoThirdParty.bind(this, pay.vendorId, pay.vendorName)}><span><img src={pay.imgUrl} alt="" /></span><span className="payment-name">{pay.vendorName}</span></div>
                                                    }

                                                </Fragment>
                                            );
                                        })
                                    }
                                    {
                                        olDetail.paymentVendors.length === 1 && <div className="content"><span>Please wait...</span></div>
                                    }
                                </div>
                            </div>
                            <div>
                                <form id="razorpay-submit" method="POST" action={Config.razorpayRedirectUrl} onSubmit={this.handleSubmit}>
                                    <input type="hidden" name="key_id" value={this.state.vendorKey} />
                                    <input type="hidden" name="order_id" value={this.state.vendorTransactionId} />
                                    <input type="hidden" name="name" value={sessionStorage.getItem("user-propertyname")} />
                                    <input type="hidden" name="description" value={sessionStorage.getItem("user-outletname")} />
                                    <input type="hidden" name="image" value={this.state.paymentVendorLogo} />
                                    <input type="hidden" name="prefill[name]" value="" />
                                    <input type="hidden" name="prefill[contact]" value={mobileNo} />
                                    <input type="hidden" name="prefill[email]" value="test@mail.com" />
                                    <input type="hidden" name="notes[shipping address]" value=" " />
                                    <input type="hidden" name="callback_url" value={Config.redirectBillPaidUrl} />
                                    <input type="hidden" name="cancel_url" value={Config.redirectBillPaidFailedUrl} />
                                    <button style={{ display: "none" }} ref={input => this.inputElement = input}>Submit</button>
                                    {/* <button style={{ display: "none" }} ref={(e) => this.submitClick.bind(e)}>Submit</button> */}

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}