import React, { Component } from 'react';
import './processfailed.scss';
import LoadingSVGAnimation from '../../components/checkmarkanimation/failed';
//import LoadingAnimation from '../../components/loadinganimation/paymentloading';
import LoadingAnimation from '../../components/loadinganimation';
import { isMobile } from 'react-device-detect';
import Config from '../../data/SiteConfig';
let urlPattern = '';
export default class ProcessFailed extends Component {
    constructor(props) {
        super(props);
        this.goBack = this.goBack.bind(this);
        this.gotomyorders = this.gotomyorders.bind(this);
        this.state = {
            cartValue: 1500,
            loading: false,
            orderId: ''
        }
    }
    componentWillMount() {
        if (Config.IsMobileAccess) {
            if (!isMobile) {
                this.props.history.push('/deviceerror' + urlPattern);
                return false;
            }
        }
        sessionStorage.removeItem('redirect-page');
        const sessionDt = sessionStorage.getItem("u-l-v-url-dt");
        if (!sessionDt) {
            this.props.history.push('/sessionerror' + urlPattern);
            return false;
        }
        urlPattern = sessionStorage.getItem('url-pattern');
        if (!urlPattern) {
            this.props.history.push('/qrcodeerror' + Config.defaultUrlPattern);
            return false;
        }
        // this.setState({ loading: true });
        // setTimeout(() => {
        //     this.setState({ loading: false });
        //     this.props.history.push('/items');
        // }, 900000);

        sessionStorage.removeItem("paymentInitiatedId");
        sessionStorage.removeItem("vendorTransactionId");
        sessionStorage.removeItem("vendorId");
        sessionStorage.removeItem("vendorName");
        sessionStorage.removeItem("paymentStatus");
        sessionStorage.removeItem("payAmount");

    }
    goBack() {
        // this.props.history.push('/home');
    }
    gotomyorders() {
        this.setState({ loading: true });
        setTimeout(() => {
            this.setState({ loading: false });
            this.props.history.push('/mybills' + urlPattern);
        }, Config.minMsgTimeout);

    }

    render() {
        return (
            <div>
                {this.state.loading && <LoadingAnimation />}
                <div id="content" className="top">
                    <div className="content-warp">
                        <div className="section-processfailed">
                            <div className="heading-page">
                                {sessionStorage.getItem("user-propertyname")}
                                <span className="payment-txt">{sessionStorage.getItem("user-outletname")} - {sessionStorage.getItem("user-servicetype") === "FINE-DINE" ? 'Table Service' : sessionStorage.getItem("user-servicetype")} </span>
                                {/* <div className="back-btn">
                                    <div className="icon-back" onClick={this.goBack}><i className="fas fa-arrow-left" aria-hidden="true"></i></div>
                                </div> */}
                            </div>
                            <div className="content-from">
                                <div className="message-details">
                                    <div className="content">
                                        <div><LoadingSVGAnimation /></div>
                                        <div className="msg">Transaction Failed.</div>
                                        <div className="msg-order-id">Please Try Again</div>
                                        <div><button className="theme-button btn" onClick={this.gotomyorders}>Retry</button></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

}

