import React, { Component } from "react"
import './settleprocess.scss';
//import LoadingAnimation from '../../components/loadinganimation/verificationloading';
// import LoadingAnimation from '../../components/loadinganimation/paymentloading';
import LoadingAnimation from '../../components/loadinganimation';
import * as QueryString from "query-string"
import Config from '../../data/SiteConfig';
import ErrorAlert from '../../components/alertmessages';
import { isMobile } from 'react-device-detect';
import sHelpers from '../../modules/helpers/statichelpers';
//import uuid from 'react-uuid';
let mobileNo = '';
let token = '';;
let tableNo = '';
let serviceType = '';
let propertyId = '';
let outletCode = '';
let paymentInitiatedId = '';
let vendorTransactionId = '';
let vendorId = '';
let vendorName = '';
let totalAmount = 0;
let eBillUrl = "";
let isPay = "";
let posBillId = "";
let mainGuestMobileNo = '';
let urlPattern = '';
export default class SettleProcessVerification extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            alertMessage: '',
            msgShow: false,
            paymentInitiatedId: "",
            statusMsg: "",
        }
    }


    componentDidMount() {
        if (Config.IsMobileAccess) {
            if (!isMobile) {
                this.props.history.push('/deviceerror' + urlPattern);
                return false;
            }
        }
        sessionStorage.removeItem('redirect-page');
        const sessionDt = sessionStorage.getItem("u-l-v-url-dt");
        if (!sessionDt) {
            this.props.history.push('/sessionerror' + urlPattern);
            return false;
        }
        urlPattern = sessionStorage.getItem('url-pattern');
        if (!urlPattern) {
            this.props.history.push('/qrcodeerror' + Config.defaultUrlPattern);
            return false;
        }
        let inputParam = QueryString.parse(window.location.search);
        let vendorPaymentStatus = inputParam.payment_status;
        this.setState({ statusMsg: "Processing Your Request" });
        // console.log(inputParam);

        mobileNo = sessionStorage.getItem('user-mobileno');
        token = sessionStorage.getItem('user-token');
        tableNo = sessionStorage.getItem('user-tableno');
        serviceType = sessionStorage.getItem('user-servicetype');
        propertyId = sessionStorage.getItem('user-propertyid');
        outletCode = sessionStorage.getItem('user-outletcode');
        mainGuestMobileNo = sessionStorage.getItem('main-guest-no') == null ? '' : sessionStorage.getItem('main-guest-no');


        if (!sHelpers.inputParamValidation()) {
            this.props.history.push('/' + urlPattern);
        }

        paymentInitiatedId = sessionStorage.getItem('paymentInitiatedId');
        vendorTransactionId = sessionStorage.getItem('vendorTransactionId');
        vendorId = sessionStorage.getItem('vendorId');
        vendorName = sessionStorage.getItem('vendorName');
        totalAmount = sessionStorage.getItem('payAmount');
        eBillUrl = sessionStorage.getItem('ebillUrl');
        isPay = sessionStorage.getItem('isPay');
        posBillId = sessionStorage.getItem('posBillId');



        if (eBillUrl === undefined || eBillUrl === "" || eBillUrl === null) {
            this.showMessage('Transaction failed. Please try again', "danger");
            this.setState({ loading: true });
            setTimeout(() => { this.props.history.push('/myorders' + urlPattern); }, Config.averageMsgTimeout);
            return false;
        }
        if (posBillId === undefined || posBillId === "" || posBillId === null) {
            this.showMessage('Transaction failed. Please try again', "danger");
            this.setState({ loading: true });
            setTimeout(() => { this.props.history.push('/myorders' + urlPattern); }, Config.averageMsgTimeout);
            return false;
        }

        else if (paymentInitiatedId === undefined || paymentInitiatedId === "" || paymentInitiatedId === null) {
            this.showMessage('Transaction failed. Please try again', "danger");
            this.setState({ loading: true });
            setTimeout(() => { this.props.history.push('/viewbill' + urlPattern, { eBillUrl: eBillUrl, billAmount: totalAmount, isPay: isPay, posBillId: posBillId }); }, Config.averageMsgTimeout);
            return false;
        }


        window.scrollTo(0, 0);

        if (vendorPaymentStatus !== null && vendorPaymentStatus !== "Failed") {
            // API Call
            this.handleCheckPaymentStatus();
        }
        else {
            sessionStorage.removeItem("paymentInitiatedId");
            sessionStorage.removeItem("vendorTransactionId");
            sessionStorage.removeItem("vendorId");
            sessionStorage.removeItem("vendorName");
            sessionStorage.removeItem("paymentStatus");
            sessionStorage.removeItem('payAmount');
            this.setState({ loading: false, statusMsg: "Redirecting..." });
            this.showMessage('Transaction failed. Please try again', "danger");
            setTimeout(() => { this.setState({ loading: true }); setTimeout(() => { this.props.history.push('/viewbill' + urlPattern, { eBillUrl: eBillUrl, billAmount: totalAmount, isPay: isPay, posBillId: posBillId }); }, 3000); }, 2500);
        }
    }
    handleCheckPaymentStatus() {

        this.setState({ loading: true });
        fetch(Config.basePaymentUrl + 'GetPaymentStatus?PaymentInitiatedId=' + paymentInitiatedId, {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                'content-type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(response => {
                if (response.errorCode === 0) {
                    setTimeout(() => {
                        if (response.response.status === "SUCCESS") {
                            this.handleBillandSettle();
                        }
                        else if (response.response.status === "PENDING") {
                            setTimeout(() => {
                                this.handleCheckPaymentStatus();
                            }, Config.averageMsgTimeout);
                        }
                        else {
                            this.setState({ loading: true });
                            this.setState({ statusMsg: "Transaction failed. Please try again" });
                            this.showMessage('Transaction failed. Please try again', "danger");
                            setTimeout(() => { this.props.history.push('/viewbill' + urlPattern, { eBillUrl: eBillUrl, billAmount: totalAmount, isPay: isPay, posBillId: posBillId }); }, 2000);
                        }

                        //  window.location.href = response.response.vendorRedirectUrl;

                        // this.props.history.push('/paymentVerification', {
                        //     getMenus: this.state.getMenus,
                        //     getItems: this.state.getItems,
                        //     getModifiers: this.state.getModifiers,
                        //     getTaxes: this.state.getTaxes,
                        //     getCharges: this.state.getCharges
                        // });
                    }, 2000);
                }
                else {
                    //  console.log(response.message);
                    this.setState({ loading: true });
                    this.showMessage(response.message, "danger");
                    this.setState({ statusMsg: response.message });
                    setTimeout(() => { this.setState({ loading: false }); this.props.history.push('/viewbill' + urlPattern, { eBillUrl: eBillUrl, billAmount: totalAmount, isPay: isPay, posBillId: posBillId }); }, Config.averageMsgTimeout);
                }
            })
            .catch(err => {
                // console.log(err);
                this.setState({ loading: true });
                this.showMessage('Transaction failed. Please try again', "danger");
                setTimeout(() => { this.setState({ loading: false }); this.props.history.push('/viewbill' + urlPattern, { eBillUrl: eBillUrl, billAmount: totalAmount, isPay: isPay, posBillId: posBillId }); }, Config.averageMsgTimeout);
            });
    }

    handleBillandSettle() {
        let paymentDetails = [];

        paymentDetails.push({
            PayMode: "ONLP", // Online Pay 
            PaymentAmount: totalAmount,
            TransactionRefId: vendorTransactionId,
            PaymentVendorId: vendorId, // Vendor Id
            PaymentVendorName: vendorName
        });

        let postObj = {
            OutletId: propertyId + outletCode,
            ServiceType: serviceType,
            TableNumber: tableNo,
            GuestName: sessionStorage.getItem("main-guest-name") === null ? (sessionStorage.getItem("guest-name") === null ? ' ' : sessionStorage.getItem("guest-name")) : sessionStorage.getItem("main-guest-name"),
            // MobileNo: mainGuestMobileNo,
            MobileNo: (mainGuestMobileNo === '' || mainGuestMobileNo === "0000000000") ? mobileNo : mainGuestMobileNo,
            BillId: posBillId,
            BillAmount: totalAmount,
            PaymentDetails: paymentDetails
        }
        this.setState({ loading: true });


        fetch(Config.basePOSUrl + 'Settle', {
            method: 'POST',
            headers: {
                Authorization: "Bearer " + token,
                'content-type': 'application/json'
            },
            body: JSON.stringify(postObj)
        })
            .then(res => res.json())
            .then(response => {
                if (response.errorCode === 0) {
                    setTimeout(() => {
                        this.setState({ loading: false });
                        sessionStorage.removeItem("paymentInitiatedId");
                        sessionStorage.removeItem("vendorTransactionId");
                        sessionStorage.removeItem("vendorId");
                        sessionStorage.removeItem("vendorName");
                        sessionStorage.removeItem("paymentStatus");
                        sessionStorage.removeItem("payAmount");
                        sessionStorage.removeItem("order-instruction");
                        sessionStorage.removeItem('ebillUrl');
                        sessionStorage.removeItem('isPay');
                        //  console.log(response);
                        this.props.history.push('/settleprocesssuccess' + urlPattern,
                            {
                                billSuccess: true
                            });
                    }, Config.averageMsgTimeout);
                }
                else {
                    this.setState({ loading: true });
                    this.showMessage(response.message, "danger");
                    setTimeout(() => { this.setState({ loading: false }); this.props.history.push('/viewbill' + urlPattern, { eBillUrl: eBillUrl, billAmount: totalAmount, isPay: isPay, posBillId: posBillId }); }, Config.averageMsgTimeout);
                }
            })
            .catch(err => {
                //  console.log(err);
                this.setState({ loading: true });
                this.showMessage('Error the bill was not settled', "danger");
                setTimeout(() => { this.setState({ loading: false }); this.props.history.push('/viewbill' + urlPattern, { eBillUrl: eBillUrl, billAmount: totalAmount, isPay: isPay, posBillId: posBillId }); }, Config.averageMsgTimeout);
            });
    };

    showMessage(msg, type) {
        window.scrollTo(0, 0);
        this.setState({ msgShow: true });
        this.setState({ msgType: type });
        this.setState({ alertMessage: msg });
        this.setState({ loading: false });
        setTimeout(() => {
            this.setState({ msgShow: false });
        }, Config.averageMsgTimeout);
    };

    render() {
        return (
            <div>
                {this.state.loading && <LoadingAnimation />}
                <div id="content" className="top">
                    {this.state.msgShow && <ErrorAlert alertMsg={this.state.alertMessage} msgType={this.state.msgType} msgShow={this.state.msgShow} />}
                    <div className="content-warp">
                        <div className="section-settleprocessverification">
                            <div className="heading-page">
                                {sessionStorage.getItem("user-propertyname")}
                                <span className="payment-txt">{sessionStorage.getItem("user-outletname")} - {sessionStorage.getItem("user-servicetype") === "FINE-DINE" ? 'Table Service' : sessionStorage.getItem("user-servicetype")} </span>
                                {/* <div className="back-btn">
                                    <div className="icon-back" onClick={this.goBack}><i className="fas fa-arrow-left" aria-hidden="true"></i></div>
                                </div> */}
                            </div>
                            <div className="content-from">
                                <div className="message-details">
                                    <div className="content">
                                        {/* <div><LoadingAnimation /></div> */}
                                        <div className="msg">{this.state.statusMsg}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}