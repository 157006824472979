import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";// JK Custom
import './App.css';
//import { registerNav } from './modules/navigation' ;
import Login from './View/login/login';
import Spalash from './View/splashscreen/screen';
import LoginVerification from './View/login/loginverification';
import Home from './View/home';
import Items from './View/items';
import Cart from './View/cart';
import Payment from './View/payment';
import PaymentQR from './View/payment/paymentqr'; // demo link
import OrderSuccess from './View/ordersuccess';
import MyOrders from './View/myorders';
import QRCodeError from './View/error/qrcoderror';
import PaymentVerification from './View/payment/paymentverification';
import OrderFailed from './View/orderfailed';
import Logout from './View/logout';
import TableVerification from './View/login/tableverification';
import DeviceError from './View/error/deviceerror';
import Orientation from './View/error/orientation';
import ProcessRequest from './View/process/processrequest';
import ProcessVerification from './View/process/processverification';
import ProcessSuccess from './View/process/processsuccess';
import ProcessFailed from './View/process/processfailed';
import MyBills from './View/mybills';
import Offline from './View/error/offline';
import ViewBill from './View/myorders/viewbill';
import SettleProcessRequest from './View/settlepayment/settleprocessrequest';
import SettleProcessVerification from './View/settlepayment/settleprocessverification';
import SettleProcessSuccess from './View/settlepayment/settleprocesssuccess';
import SettleProcessFailed from './View/settlepayment/settleprocessfailed';
import MyAddress from './View/myaccount/address';
import MyAddressEntry from './View/myaccount/addressentry';
import OrderTracking from './View/myorders/ordertracking';
import GPaySuccess from './View/ordersuccess/gpaysuccess';
import HelpRequest from './View/helprequest';
import PrepaidCardVerification from './View/payment/prepaidcardverification';
import PayOnDeliveryVerification from './View/payment/payondeliveryverification';
import Error404 from './View/error/err404';
// EMenu
import emHome from './View/emenu/home';
import emItems from './View/emenu/items';
// import emPages from './View/emenu/pages';

// Group Order
import JoinTable from './View/login/jointable';
import SessionError from './View/error/sessionerror';
//import uuid from 'react-uuid';

import * as QueryString from "query-string";
import Config from "./data/SiteConfig";
//import { useAlert } from 'react-alert';

//jk command
// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

//jk custom  


// Url Binding
let version = Config.appVersionNo;
let dtNumber = ""; // datetime
let uid = ""; // uniqueid
let inputParam = QueryString.parse(window.location.search);
let urlKey = "";
//let uniqueUrlPattern = "/v1/145268548565/ad4-587-df8";
let url = window.location.href;
const baseUrl = url.split("/")[0] + "//" + url.split("/")[2];
let _splitUrl = url.split("/");
let urlPattern = sessionStorage.getItem("url-pattern");

if (inputParam.ukey) {
  if (_splitUrl.length >= 4) {
    dtNumber = _splitUrl[3];
    uid = _splitUrl[4];
    urlKey = inputParam.ukey;
  } else {
    url = baseUrl + Config.defaultUrlPattern;
    _splitUrl = url.split("/");
    dtNumber = _splitUrl[3];
    uid = _splitUrl[4];
    urlKey = "";
  }
  sessionStorage.setItem("url-key", urlKey);
  sessionStorage.setItem("dt-number", dtNumber);
  sessionStorage.setItem("uid", uid);
  sessionStorage.setItem("url-version", version);
  window.history.pushState({}, "", baseUrl);
  sessionStorage.setItem(
    "url-pattern",
    "/" + version + "/" + dtNumber + "/" + uid
  );
} else {
  if (!sessionStorage.getItem("url-key")) {
    if (inputParam.ukey) {
      if (_splitUrl.length >= 4) {
        dtNumber = _splitUrl[3];
        uid = _splitUrl[4];
        urlKey = inputParam.ukey;
      } else {
        url = baseUrl + Config.defaultUrlPattern;
        _splitUrl = url.split("/");
        dtNumber = _splitUrl[3];
        uid = _splitUrl[4];
        urlKey = "";
      }
    } else {
      url = baseUrl + Config.defaultUrlPattern;
      _splitUrl = url.split("/");
      dtNumber = _splitUrl[3];
      uid = _splitUrl[4];
      urlKey = "";
    }
    sessionStorage.setItem("url-key", urlKey);
    sessionStorage.setItem("dt-number", dtNumber);
    sessionStorage.setItem("uid", uid);
    sessionStorage.setItem("url-version", version);
    window.history.pushState({}, "", baseUrl);
    sessionStorage.setItem(
      "url-pattern",
      "/" + version + "/" + dtNumber + "/" + uid
    );
  } else {
    urlKey = sessionStorage.getItem("url-key");
    dtNumber = sessionStorage.getItem("dt-number");
    uid = sessionStorage.getItem("uid");
    version = sessionStorage.getItem("url-version");

    if (url.includes("orderfailed"))
      window.history.pushState({}, "", baseUrl + "/orderfailed" + urlPattern);
    else if (url.includes("processfailed"))
      window.history.pushState({}, "", baseUrl + "/processfailed" + urlPattern);
    else if (url.includes("settleprocessfailed"))
      window.history.pushState({}, "", baseUrl + "/settleprocessfailed" + urlPattern);
    else if (sessionStorage.getItem("redirect-page"))
      window.history.pushState({}, "", baseUrl + "/" + sessionStorage.getItem("redirect-page") + urlPattern);

    //sessionStorage.setItem("unique-urlPattern", uniqueUrlPattern);
  }
}


function App() {
  //  console.log('app');
  return (
    <div>
      <Router>
        <Switch>
          <Route component={Spalash} exact path="/:version/:dtNumber/:uid" ></Route>
          <Route key="login" component={Login} path="/login/:version/:dtNumber/:uid"></Route>
          <Route key="loginVerification" component={LoginVerification} path="/loginVerification/:version/:dtNumber/:uid"></Route>
          <Route key="home" component={Home} path="/home/:version/:dtNumber/:uid" ></Route>
          <Route key="items" component={Items} path="/items/:version/:dtNumber/:uid" exact></Route>
          <Route key="cart" component={Cart} path="/cart/:version/:dtNumber/:uid" exact></Route>
          <Route key="payment" component={Payment} path="/payment/:version/:dtNumber/:uid" exact></Route>
          <Route key="paymentqr" component={PaymentQR} path="/paymentqr/:version/:dtNumber/:uid" exact></Route>
          <Route key="ordersuccess" component={OrderSuccess} path="/ordersuccess/:version/:dtNumber/:uid" exact></Route>
          <Route key="myorders" component={MyOrders} path="/myorders/:version/:dtNumber/:uid" exact></Route>
          <Route key="qrcodeerror" component={QRCodeError} path="/qrcodeerror/:version/:dtNumber/:uid" exact></Route>
          <Route key="paymentverification" component={PaymentVerification} path="/paymentverification/:version/:dtNumber/:uid" exact></Route>
          <Route key="orderfailed" component={OrderFailed} path="/orderfailed/:version/:dtNumber/:uid" exact></Route>
          <Route key="logout" component={Logout} path="/logout/:version/:dtNumber/:uid" exact></Route>
          <Route key="tableverification" component={TableVerification} path="/tableverification/:version/:dtNumber/:uid" exact></Route>
          <Route key="deviceerror" component={DeviceError} path="/deviceerror/:version/:dtNumber/:uid" exact></Route>
          <Route key="orientation" component={Orientation} path="/orientation/:version/:dtNumber/:uid" exact></Route>
          <Route key="processrequest" component={ProcessRequest} path="/processrequest/:version/:dtNumber/:uid" exact></Route>
          <Route key="processverification" component={ProcessVerification} path="/processverification/:version/:dtNumber/:uid" exact></Route>
          <Route key="processsuccess" component={ProcessSuccess} path="/processsuccess/:version/:dtNumber/:uid" exact></Route>
          <Route key="processfailed" component={ProcessFailed} path="/processfailed/:version/:dtNumber/:uid" exact></Route>
          <Route key="mybills" component={MyBills} path="/mybills/:version/:dtNumber/:uid" exact></Route>
          <Route key="offline" component={Offline} path="/offline/:version/:dtNumber/:uid" exact></Route>
          <Route key="viewbill" component={ViewBill} path="/viewbill/:version/:dtNumber/:uid" exact></Route>
          <Route key="settleprocessrequest" component={SettleProcessRequest} path="/settleprocessrequest/:version/:dtNumber/:uid" exact></Route>
          <Route key="settleprocessverification" component={SettleProcessVerification} path="/settleprocessverification/:version/:dtNumber/:uid" exact></Route>
          <Route key="settleprocesssuccess" component={SettleProcessSuccess} path="/settleprocesssuccess/:version/:dtNumber/:uid" exact></Route>
          <Route key="settleprocessfailed" component={SettleProcessFailed} path="/settleprocessfailed/:version/:dtNumber/:uid" exact></Route>
          <Route key="chooseaddress" component={MyAddress} path="/chooseaddress/:version/:dtNumber/:uid" exact></Route>
          <Route key="addaddress" component={MyAddressEntry} path="/addaddress/:version/:dtNumber/:uid" exact></Route>
          <Route key="ordertrack" component={OrderTracking} path="/ordertrack/:version/:dtNumber/:uid" exact></Route>
          <Route key="gpaysuccess" component={GPaySuccess} path="/gpaysuccess/:version/:dtNumber/:uid" exact></Route>
          <Route key="helprequest" component={HelpRequest} path="/helprequest/:version/:dtNumber/:uid" exact></Route>
          <Route key="prepaidcardverification" component={PrepaidCardVerification} path="/prepaidcardverification/:version/:dtNumber/:uid" exact></Route>
          <Route key="payondeliveryverification" component={PayOnDeliveryVerification} path="/payondeliveryverification/:version/:dtNumber/:uid" exact></Route>
          <Route key="error404" component={Error404} path="/error404/:version/:dtNumber/:uid" exact></Route>
          {/* E Menu */}
          <Route key="emhome" component={emHome} path="/emhome" exact></Route>
          <Route key="emitems" component={emItems} path="/emitems" exact></Route>
          {/* <Route key="empages" component={emPages} path="/empages" exact></Route> */}

          {/* Group Order */}
          <Route key="jointable" component={JoinTable} path="/jointable/:version/:dtNumber/:uid" exact></Route>
          <Route key="sessionerror" component={SessionError} path="/sessionerror/:version/:dtNumber/:uid" exact></Route>

          {/* <Redirect to='/' /> */}
          <Redirect to={`/${version}/${dtNumber}/${uid}?ukey=${urlKey}`} />
        </Switch>
      </Router>
    </div>
  );
}
export default App;
