import React, { Component, Fragment } from 'react';
import './payment.scss';
//import LoadingAnimation from '../../components/loadinganimation/paymentloading';
import LoadingAnimation from '../../components/loadinganimation';
import Config from '../../data/SiteConfig';
import ErrorAlert from '../../components/alertmessages';
import { isMobile } from 'react-device-detect';
import sHelpers from '../../modules/helpers/statichelpers';
//import GooglePayButton from '@google-pay/button-react';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Radio from '../../components/radionbutton/radiobutton';
const podImg = '/img/payment/lucid-pod.png';
let mobileNo = '';
let token = '';
let tableNo = '';
let serviceType = '';
let orgnationTd = '';
let propertyId = '';
let outletCode = '';
let olDetail = {};
let outletDetails = null;
let mainGuestMobileNo = '';
let urlPattern = '';
// const paymentMethods = [
//     {
//         type: 'CARD',
//         parameters: {
//             allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
//             allowedCardNetworks: ['AMEX', 'DISCOVER', 'INTERAC', 'JCB', 'MASTERCARD', 'VISA']
//         },
//         tokenizationSpecification: {
//             type: 'PAYMENT_GATEWAY',
//             parameters: {
//                 // 'gateway': 'stripe',
//                 // 'stripe:version': '2019-03-14',
//                 // 'stripe:publishableKey': '<YOUR_PUBLIC_STRIPE_KEY>'
//                 gateway: 'example',
//                 gatewayMerchantId: 'exampleGatewayMerchantId'
//             }
//         }
//     },
//     {
//         type: 'PAYPAL',
//         parameters: {
//             'purchase_context': {
//                 'purchase_units': [{
//                     'payee': {
//                         'merchant_id': 'BCR2DN6TV77ITYBK'
//                     }
//                 }]
//             }
//         },
//         tokenizationSpecification: {
//             type: 'DIRECT'
//         }
//     }
// ]
export default class Payment extends Component {
    constructor(props) {
        super(props);
        this.goBack = this.goBack.bind(this);
        this.gotoThirdParty = this.gotoThirdParty.bind(this);
        this.handlePrepaidCardBalance = this.handlePrepaidCardBalance.bind(this);
        this.gotoPrepaidCardVerification = this.gotoPrepaidCardVerification.bind(this);
        this.state = {

            cartValue: 1500,
            paymentList: [
                { vendorId: 3, vendorName: "Razorpay", imgUrl: "/img/payment/razorpay.png" },
                { vendorId: 2, vendorName: "Instamojo", imgUrl: "/img/payment/instamojo.png" },
                // { paymentId: 2, paymentName: "CCAvenue", imgUrl: "../assets/theme1/img/payment/ccavenue.png" },
                // { paymentId: 3, paymentName: "Paytm", imgUrl: "../assets/theme1/img/payment/paytm.png" },
                // { paymentId: 4, paymentName: "GPay", imgUrl: "../assets/theme1/img/payment/gpay.png" },
                // { paymentId: 5, paymentName: "PhonePe", imgUrl: "../assets/theme1/img/payment/phonepe.png" },
            ],
            onlineWalletVendorsList: [
                { vendorId: 1, vendorName: "LUCID Prepaid Card", imgUrl: "/img/payment/lucid-prepadicard.png" }
            ],
            loading: false,
            alertMessage: '',
            msgShow: false,
            totalItems: 0,
            totalNetAmount: 0,
            vendorKey: '',
            vendorTransactionId: '',
            vendorOrderId: '',
            paymentVendorLogo: '',
            prepaidCardBalance: 0,
            radiostatus: 0,
            selected: 'radio-0'
        }
        this.handlePayOnRadioChange = this.handlePayOnRadioChange.bind(this);
    }

    componentWillMount() {

        if (Config.IsMobileAccess) {
            if (!isMobile) {
                this.props.history.push('/deviceerror' + urlPattern);
                return false;
            }
        }

        mobileNo = sessionStorage.getItem('user-mobileno');
        token = sessionStorage.getItem('user-token');
        tableNo = sessionStorage.getItem('user-tableno');
        serviceType = sessionStorage.getItem('user-servicetype');
        orgnationTd = sessionStorage.getItem('user-orgnationid');
        propertyId = sessionStorage.getItem('user-propertyid');
        outletCode = sessionStorage.getItem('user-outletcode');
        outletDetails = sessionStorage.getItem('user-outletDetails');
        urlPattern = sessionStorage.getItem('url-pattern');
        mainGuestMobileNo = sessionStorage.getItem('main-guest-no') == null ? '' : sessionStorage.getItem('main-guest-no');
        // console.log(mobileNo);
        // console.log(token);
        // console.log(tableNo);
        // console.log(serviceType);
        // console.log(orgnationTd);
        // console.log(propertyId);
        // console.log(outletCode);

        const sessionDt = sessionStorage.getItem("u-l-v-url-dt");
        if (!sessionDt) {
            this.props.history.push('/sessionerror' + urlPattern);
            return false;
        }

        if (!urlPattern) {
            this.props.history.push('/qrcodeerror' + Config.defaultUrlPattern);
            return false;
        }
        if (token === undefined || token === "" || token === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (mobileNo === undefined || mobileNo === "" || mobileNo === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (tableNo === undefined || tableNo === "" || tableNo === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (serviceType === undefined || serviceType === "" || serviceType === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (orgnationTd === undefined || orgnationTd === "" || orgnationTd === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (propertyId === undefined || propertyId === "" || propertyId === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (outletCode === undefined || outletCode === "" || outletCode === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }

        if (outletDetails === undefined || outletDetails === "" || outletDetails === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        else {
            olDetail = JSON.parse(outletDetails);
            this.setState({ paymentVendorLogo: olDetail.outletImageUrl !== '' ? olDetail.outletImageUrl : "https://cdn.razorpay.com/logos/BUVwvgaqVByGp2_large.png" });
            if (olDetail.paymentVendors.length === 1 && olDetail.onlineWalletVendors.length === 0) // Single Vendor to direct
                this.gotoThirdParty(olDetail.paymentVendors[0].vendorId, olDetail.paymentVendors[0].vendorName);
        }

        /// Session Validation for Fine Dine
        if (serviceType === "FINE-DINE") {
            if (!sHelpers.sessionValidation()) {
                this.showMessage("Your session has expired, please scan once again", "danger");
                this.setState({ loading: true });
                setTimeout(() => {
                    this.setState({ loading: false });
                    this.props.history.push('/logout' + urlPattern);
                }, 3000);
                return false;
            }
        }

        if (sessionStorage.getItem("cartList") === null) {
            this.setState({ loading: true });
            setTimeout(() => {
                this.setState({ loading: false });
                this.props.history.push('/items' + urlPattern);
            }, 800);
        }


        // Validate Vendor Order Id ()
        if (sessionStorage.getItem('user-vendorOrderId') === undefined || sessionStorage.getItem('user-vendorOrderId') === null || sessionStorage.getItem('user-vendorOrderId') === '') {
            this.setState({ loading: false });
            this.props.history.push('/cart' + urlPattern);
            return false;
        }

        if (this.props.location.state !== undefined) {

            if (this.props.location.state.totalNetAmount === undefined || this.props.location.state.totalNetAmount === null || this.props.location.state.totalNetAmount === 0) {
                this.setState({ loading: true });
                setTimeout(() => {
                    this.setState({ loading: false });
                    this.props.history.push('/items' + urlPattern);
                }, 2000);
            }
            else {

                this.setState({
                    totalItems: this.props.location.state.totalItems,
                    totalNetAmount: this.props.location.state.totalNetAmount
                })
            }
        }
        else {
            this.setState({ loading: true });
            setTimeout(() => {
                this.setState({ loading: false });
                this.props.history.push('/items' + urlPattern);
            }, 2000);
        }
        window.scrollTo(0, 0);

        sessionStorage.removeItem('proceed-pod');
    }
    loadPaymentDataHandler = paymentData => {
        const paymentToken = paymentData.paymentMethodData.tokenizationData.token
        console.log(paymentToken);
    }
    submitClick = (e) => {
        e.preventDefault();

        // e.preventDefault();
        e.click();
        //this.inputElement.click();
    }

    goBack() {
        //this.props.history.goBack();
        this.props.history.push('/cart' + urlPattern);
    }
    gotoThirdParty(vendorId, vendorName) {

        // Validate Vendor Order Id ()
        if (sessionStorage.getItem('user-vendorOrderId') === undefined || sessionStorage.getItem('user-vendorOrderId') === null || sessionStorage.getItem('user-vendorOrderId') === '') {
            this.setState({ loading: false });
            this.showMessage('OOPS! Initial transaction failed. Please try again', "danger");
            setTimeout(() => {
                this.props.history.push('/cart' + urlPattern);
            }, 1500);

            return false;
        }

        // e.preventDefault();
        this.setState({ loading: true });

        setTimeout(() => {
            var postObj = {
                OutletId: propertyId + outletCode,
                PaymentVendorId: vendorId,
                Amount: this.state.totalNetAmount.toFixed(2),
                GuestName: sessionStorage.getItem("guest-name") === null ? ' ' : sessionStorage.getItem("guest-name"),
                MobileNoCountryCode: "91",
                // MobileNo: mainGuestMobileNo,
                MobileNo: (mainGuestMobileNo === '' || mainGuestMobileNo === "0000000000") ? mobileNo : mainGuestMobileNo,
                EmailId: "",
                OrderId: "",
                BillId: "",
                RedirectUrl: Config.redirectUrl
            }

            //  console.log(postObj);
            // API Call
            fetch(Config.basePaymentUrl + 'InitiateOnlinePayment', {
                method: 'POST',
                headers: {
                    Authorization: "Bearer " + token,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(postObj)
            })
                .then(res => res.json())
                .then(response => {
                    // console.log(response);
                    if (response.errorCode === 0) {
                        setTimeout(() => {
                            sessionStorage.setItem("paymentInitiatedId", response.response.paymentInitiatedId);
                            sessionStorage.setItem("vendorTransactionId", response.response.vendorTransactionId);
                            sessionStorage.setItem("vendorId", vendorId);
                            sessionStorage.setItem("vendorName", vendorName);
                            sessionStorage.setItem("paymentStatus", response.response.status);
                            if (vendorId === 2) {        // instamojo                         
                                window.location.href = response.response.vendorRedirectUrl;
                                sessionStorage.setItem('redirect-page', "paymentverification");
                            }
                            else if (vendorId === 3) { // Razor Pay
                                this.setState({
                                    vendorTransactionId: response.response.vendorTransactionId,
                                    vendorKey: response.response.vendorKey
                                })
                                setTimeout(() => {
                                    sessionStorage.setItem('redirect-page', "paymentverification");
                                    this.inputElement.click();
                                }, Config.minMsgTimeout);
                            }
                            else {
                                this.setState({ loading: true });
                                this.showMessage('OOPS! Initial transaction failed. Please try again', "danger");
                                setTimeout(() => { this.setState({ loading: false }); this.props.history.push('/cart' + urlPattern); }, Config.averageMsgTimeout);
                            }

                            //window.location.href ="https://www.instamojo.com/@gatewayofficeparks/c57b613d098242c4adf6893321a78b35";
                            // this.props.history.push('/paymentVerification?payment_status=Failed');
                        }, 2000);
                    }
                    else {
                        //  console.log(response.message);
                        this.setState({ loading: true });
                        this.showMessage('OOPS! Initial transaction failed [' + response.message + ']. Please try again', "danger");
                        setTimeout(() => { this.setState({ loading: false }); this.props.history.push('/cart' + urlPattern); }, Config.averageMsgTimeout);
                    }
                })
                .catch(err => {
                    //  console.log(err);
                    this.setState({ loading: true });
                    this.showMessage('OOPS! Initial transaction failed. Please try again', "danger");
                    setTimeout(() => { this.setState({ loading: false }); this.props.history.push('/cart' + urlPattern); }, Config.averageMsgTimeout);
                });
        }, Config.minMsgTimeout);
    }

    handlePrepaidCardBalance(vendorId, vendorName, rowId) {
        this.setState({ loading: true });
        //  this.handleMultiPrepaidCardAccountBalance(vendorId, []);

        var postObj = {
            OutletId: propertyId + outletCode,
            VendorId: vendorId,
            // GuestMobileNo: mainGuestMobileNo  Commended by on 08-11-2023
            // Added by on 08-11-2023
            GuestMobileNo: (mainGuestMobileNo === '' || mainGuestMobileNo === "0000000000") ? mobileNo : mainGuestMobileNo,
        }
        //  console.log(postObj);
        // API Call
        fetch(Config.basePOSUrl + 'GetGuestOnlineWalletDetails', {
            method: 'POST',
            headers: {
                Authorization: "Bearer " + token,
                'content-type': 'application/json'
            },
            body: JSON.stringify(postObj)
        })
            .then(res => res.json())
            .then(response => {
                // console.log(response);
                if (response.errorCode === 0) {
                    if (response.response != null) {
                        let activeCardList = response.response.walletDetails.filter(c => c.remarks === "Card is Valid");
                        if (activeCardList.length > 0) {
                            this.setState({ loading: false });
                            confirmAlert({
                                customUI: ({ onClose }) => {
                                    return (
                                        <div className="prepaid-card-list">
                                            <h6>Select card to pay</h6>
                                            <div className="itm-total-txt">{this.state.totalItems} items, To pay: ₹{this.state.totalNetAmount.toFixed(2)} </div>
                                            <ul id="card-li-list">
                                                {
                                                    activeCardList.map((acc, idx) =>
                                                        <li key={"card-account" + idx}>
                                                            {acc.availableBalance > this.state.totalNetAmount &&
                                                                <div className="content prepaid-card-account">
                                                                    {acc.accountId}<span className="pay-btn" onClick={this.gotoPrepaidCardVerification.bind(this, vendorId, vendorName, acc.accountId, onClose)}>Pay</span>
                                                                    <span className="pay-wallet-bal">Wallet Balance ₹{acc.availableBalance} </span>
                                                                </div>
                                                            }
                                                            {acc.availableBalance < this.state.totalNetAmount &&
                                                                <div className="content prepaid-card-account">
                                                                    {acc.accountId}
                                                                    <span className="non-pay-btn">Insufficient  Wallet Balance ₹{acc.availableBalance} </span>
                                                                </div>
                                                            }
                                                        </li>
                                                    )
                                                }

                                            </ul>
                                            <button onClick={onClose}>Cancel</button>
                                        </div>
                                    );
                                }
                            });
                        }
                        // else if (activeCardList.length === 1) {
                        //     const payclick = document.getElementById("" + rowId + "");
                        //     payclick.innerHTML = '<span onClick="' + this.gotoPrepaidCardVerification.bind(this, vendorId, vendorName, activeCardList[0].accountId, null) + '" style="float: right;border: 1px solid #d12f8b;padding: 2px 8px 4px 8px;font-size: 14px;font-weight: 500;">Pay ₹' + activeCardList[0].availableBalance + '</span>'; //'<span style="float: right;border: 1px solid #d12f8b;padding: 2px 8px 4px 8px;font-size: 14px;font-weight: 500;">Pay ₹' + activeCardList[0].availableBalance + '</span>';
                        //     // payclick.onClick(this.gotoPrepaidCardVerification.bind(this, vendorId, vendorName, activeCardList[0].accountId, null));
                        //     //document.getElementById("" + rowId + "").innerHTML = this.SingleCardPayDom(vendorId, vendorName, activeCardList[0].accountId, activeCardList[0].availableBalance); // '<span onClick="' + this.gotoPrepaidCardVerification.bind(this, vendorId, vendorName, activeCardList[0].accountId, null) + '" style="float: right;border: 1px solid #d12f8b;padding: 2px 8px 4px 8px;font-size: 14px;font-weight: 500;">Pay ₹' + activeCardList[0].availableBalance + '</span>';
                        // }
                        else {
                            this.showMessage('OOPS! Card detail is not available', "danger");
                        }
                    }
                }
                else {
                    //console.log(response.message);
                    //this.showMessage('OOPS! Get Balance failed [' + response.message + ']. Please try again', "danger");
                    this.showMessage(response.message, "danger");
                }
                this.setState({ loading: false });
            })
            .catch(err => {
                //console.log(err);
                this.setState({ loading: false });
                this.showMessage('OOPS! Get Balance failed. Please try again', "danger");
            });
    }

    gotoPrepaidCardVerification(vendorId, vendorName, accountId, close) {
        if (close != null) close();
        this.setState({ loading: true });
        if (sessionStorage.getItem('user-vendorOrderId') === undefined || sessionStorage.getItem('user-vendorOrderId') === null || sessionStorage.getItem('user-vendorOrderId') === '') {
            this.setState({ loading: false });
            this.showMessage('OOPS! Initial transaction failed. Please try again', "danger");
            setTimeout(() => {
                this.props.history.push('/cart' + urlPattern);
            }, Config.minMsgTimeout);

            return false;
        }

        sessionStorage.setItem("vendorId", vendorId);
        sessionStorage.setItem("vendorName", vendorName);
        sessionStorage.setItem("prepaidCardAccountId", accountId);

        setTimeout(() => {
            this.props.history.push('/prepaidcardverification' + urlPattern);
        }, Config.minMsgTimeout);

    }

    handlePayOnDelivery() {
        sessionStorage.setItem("payonDeliveryMode", '');
        this.setState({ loading: true });
        if (olDetail.payOnDeliveryModes.length > 0) {
            this.setState({ loading: false });
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className="prepaid-card-list">
                            <h6>Pay On Delivery Mode</h6>
                            <div className="itm-total-txt">{this.state.totalItems} items, To pay: ₹{this.state.totalNetAmount.toFixed(2)} </div>
                            <ul id="card-li-list">
                                {
                                    olDetail.payOnDeliveryModes.map((acc, idx) =>
                                        <li key={"card-account" + idx}>

                                            <div className="content prepaid-card-account">
                                                <label style={{ marginBottom: "0px" }}>
                                                    <Radio name={"pay-mode"} id={"pay-mode-chk-" + idx} onChange={(e) => this.handlePayOnRadioChange(e, acc)} value={acc} ></Radio>
                                                    <div style={{ paddingLeft: "10px", float: "right", marginTop: "-1px", fontWeight: "500" }}>{acc}</div>

                                                </label>
                                            </div>

                                        </li>
                                    )
                                }

                            </ul>
                            <button onClick={this.gotoProceedPayOnDelivery.bind(this, onClose)}>Proceed</button>    <button onClick={this.gotoClosePayOnDelivery.bind(this, onClose)}>Cancel</button>
                        </div>
                    );
                }
            });

            setTimeout(() => {
                document.getElementById('pay-mode-chk-' + 0).setAttribute('checked', true);
                let val = document.getElementById('pay-mode-chk-' + 0).value;
                sessionStorage.setItem("payonDeliveryMode", val);
            }, Config.minMsgTimeout);
        }
    }

    handlePayOnRadioChange(e, value) {
        sessionStorage.setItem("payonDeliveryMode", value);
        // console.log(sessionStorage.getItem('payonDeliveryMode'));
    }

    gotoClosePayOnDelivery(close) {
        if (close != null) close();
        sessionStorage.setItem("payonDeliveryMode", '');
    }
    gotoProceedPayOnDelivery(close) {
        this.setState({ loading: true });
        if (sessionStorage.getItem('payonDeliveryMode') === undefined || sessionStorage.getItem('payonDeliveryMode') === null || sessionStorage.getItem('payonDeliveryMode') === '') {
            if (close != null) close();
            this.setState({ loading: false });
            this.showMessage('OOPS! Initial transaction failed. Please try again', "danger");
            setTimeout(() => {
                this.props.history.push('/cart' + urlPattern);
            }, Config.minMsgTimeout);

            return false;
        }

        if (close != null) close();
        sessionStorage.setItem('proceed-pod', true);
        setTimeout(() => {
            this.props.history.push('/payondeliveryverification' + urlPattern);
        }, Config.minMsgTimeout);
    }

    showMessage(msg, type) {
        window.scrollTo(0, 0);
        this.setState({ msgShow: true });
        this.setState({ msgType: type });
        this.setState({ alertMessage: msg });
        this.setState({ loading: false });
        setTimeout(() => {
            this.setState({ msgShow: false });
        }, Config.minMsgTimeout);
    };

    render() {
        return (
            <div> {this.state.loading && <LoadingAnimation />}
                <div id="content" className="top">
                    {this.state.msgShow && <ErrorAlert alertMsg={this.state.alertMessage} msgType={this.state.msgType} msgShow={this.state.msgShow} />}
                    <div className="content-warp">
                        <div className="section-payment">
                            <div className="heading-page" ref="recommended">
                                Payments
                                <span className="payment-txt">{this.state.totalItems} items, To pay: ₹{this.state.totalNetAmount.toFixed(2)} </span>
                                <div className="back-btn">
                                    <div className="icon-back" onClick={this.goBack}><i className="fa fa-angle-left" ></i></div>
                                </div>
                            </div>
                            <div className="content-from m-t-contact-form">
                                <div className="gateway-details">
                                    {
                                        this.state.paymentList.map((pay, i) => {
                                            return (
                                                <Fragment key={"pay-main-div-" + pay.vendorId}>
                                                    {
                                                        (olDetail.paymentVendors.length > 1 || olDetail.onlineWalletVendors.length > 0) &&
                                                        olDetail.paymentVendors.filter(x => x.vendorId === pay.vendorId).length > 0 &&
                                                        <div className="content" key={"pay-div-" + pay.vendorId} onClick={this.gotoThirdParty.bind(this, pay.vendorId, pay.vendorName)}><span><img src={pay.imgUrl} alt="" /></span><span className="payment-name">{pay.vendorName}</span></div>
                                                    }
                                                </Fragment>

                                            );
                                        })
                                    }
                                    {
                                        this.state.onlineWalletVendorsList.map((pay, i) => {
                                            return (
                                                <Fragment key={"pay-main-div-wallet-" + pay.vendorId}>
                                                    {
                                                        // olDetail.paymentVendors.length > 1 &&
                                                        olDetail.onlineWalletVendors.filter(x => x.vendorId === pay.vendorId).length > 0 &&
                                                        <div onClick={this.handlePrepaidCardBalance.bind(this, pay.vendorId, pay.vendorName, "pay-btn-balance-" + pay.vendorId)} className="content" key={"pay-div-card-" + pay.vendorId} ><span><img style={{ height: "20px" }} src={pay.imgUrl} alt="" /></span><span className="payment-name">{pay.vendorName}</span><span id={"pay-btn-balance-" + pay.vendorId}></span></div>
                                                    }
                                                </Fragment>

                                            );
                                        })
                                    }
                                    {
                                        /* Pay on Delivery */

                                        (olDetail.enablePayOnDelivery && serviceType === Config.serviceType.DELIVERY &&
                                            <div onClick={this.handlePayOnDelivery.bind(this)} className="content" key={"pay-div-delivery-1"}><span><img style={{ height: "30px" }} src={podImg} alt="lucid-pay-on- delivery" /></span><span className="payment-name">Pay On Delivery</span></div>
                                        )

                                    }
                                    {
                                        (olDetail.paymentVendors.length === 1 && olDetail.onlineWalletVendors.length === 0) && <div className="content"><span>Please wait...</span></div>
                                        // <div className="content">
                                        //     <GooglePayButton
                                        //         environment="TEST" // after change production
                                        //         paymentRequest={{
                                        //             apiVersion: 2,
                                        //             apiVersionMinor: 0,
                                        //             allowedPaymentMethods: [
                                        //                 {
                                        //                     type: 'CARD',
                                        //                     parameters: {
                                        //                         allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                                        //                         allowedCardNetworks: ['MASTERCARD', 'VISA'],
                                        //                     },
                                        //                     tokenizationSpecification: {
                                        //                         type: 'PAYMENT_GATEWAY',
                                        //                         parameters: {
                                        //                             gateway: 'example',
                                        //                         },
                                        //                     },
                                        //                 },
                                        //             ],
                                        //             merchantInfo: {
                                        //                 // merchantId: '12345678901234567890',
                                        //                 // merchantName: 'Demo Merchant',
                                        //                 merchantId: 'BCR2DN6TV77ITYBK',
                                        //                 merchantName: 'LUCID IT Solutions',
                                        //             },
                                        //             transactionInfo: {
                                        //                 totalPriceStatus: 'FINAL',
                                        //                 totalPriceLabel: 'Total',
                                        //                 totalPrice: '1.00',
                                        //                 currencyCode: 'INR',
                                        //                 countryCode: 'IN',
                                        //             },
                                        //             shippingAddressRequired: false,
                                        //             callbackIntents: ["PAYMENT_AUTHORIZATION"],
                                        //         }}
                                        //         onLoadPaymentData={paymentRequest => {
                                        //             console.log('load payment data', paymentRequest);
                                        //         }}
                                        //         onPaymentAuthorized={paymentRequest => {
                                        //             console.log('load payment data', paymentRequest);
                                        //             // setTimeout(() => {
                                        //             //     this.setState({ loading: false });
                                        //             //     this.props.history.push('/gpaysuccess');
                                        //             // }, 2000);
                                        //             return { transaction: "success", }

                                        //         }}
                                        //         onCancel={paymentRequest => {
                                        //             console.log('load cancel data', paymentRequest);
                                        //         }}
                                        //         onError={paymentRequest => {
                                        //             console.log('load error data', paymentRequest);
                                        //         }}
                                        //         onReadyToPayChange={paymentRequest => {
                                        //             console.log('ready to data', paymentRequest);
                                        //         }}
                                        //         existingPaymentMethodRequired='false'
                                        //         buttonColor='white'
                                        //         buttonType='buy'
                                        //         buttonSizeMode='fill'
                                        //         style={{ color: "#ddd" }}
                                        //     />

                                        // </div>

                                    }
                                </div>
                            </div>
                            <div>
                                <form id="razorpay-submit" method="POST" action={Config.razorpayRedirectUrl} onSubmit={this.handleSubmit}>
                                    <input type="hidden" name="key_id" value={this.state.vendorKey} />
                                    <input type="hidden" name="order_id" value={this.state.vendorTransactionId} />
                                    <input type="hidden" name="name" value={sessionStorage.getItem("user-propertyname")} />
                                    <input type="hidden" name="description" value={sessionStorage.getItem("user-outletname")} />
                                    <input type="hidden" name="image" value={this.state.paymentVendorLogo} />
                                    <input type="hidden" name="prefill[name]" value="" />
                                    <input type="hidden" name="prefill[contact]" value={mobileNo} />
                                    <input type="hidden" name="prefill[email]" value="test@mail.com" />
                                    <input type="hidden" name="notes[shipping address]" value=" " />
                                    <input type="hidden" name="callback_url" value={Config.redirectUrl} />
                                    <input type="hidden" name="cancel_url" value={Config.redirectFailedUrl} />
                                    <button style={{ display: "none" }} ref={input => this.inputElement = input}>Submit</button>
                                    {/* <button style={{ display: "none" }} ref={(e) => this.submitClick.bind(e)}>Submit</button> */}

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

}