import React, { Component } from 'react';
import './login.scss';
import ErrorAlert from '../../components/alertmessages';
import LoadingAnimation from '../../components/loadinganimation/login';
import Config from '../../data/SiteConfig';
import { isMobile } from 'react-device-detect';
import sHelpers from '../../modules/helpers/statichelpers';
//import Cookies from 'universal-cookie';
import Slider from "react-slick";
import Header from '../../components/Header';
const homeslidersettings = {
    autoplay: true,
    dots: true,
    infinite: true,
    arrows: false,
    speed: 300,
    slidesToShow: 1,
    adaptiveHeight: false
};


let tableNo = '';
let serviceType = '';
let orgnationId = '';
let propertyId = '';
let outletCode = '';
let token = '';
let mobileNo = ';'
let statsId = '';
let groupSession = false;
let mainGuestMaskMobileNo = '';
let mainGuestMobileNo = '';
let pageSession = '';
let outletDetails = '';
let urlPattern = '';
//const [counter, setCounter] = React.useState(60);
export default class LoginSignin extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            mobileNo: '',
            orgId: 0,
            propertyId: '',
            outletCode: '',
            tableNo: '',
            serviceType: '',
            otp: '',
            alertMessage: '',
            msgType: '',
            msgShow: false,
            groupPin: '',
            showGroupPin: false,
            inputPlaceholder: '',
            showOTP: false,
            showTablePIN: false,

            // group pin added by dasprakash on 09-10-2023 jira-1490
            otpVerification: false,
            pinverified: false,

            tablePin: '',
            sliderImages: [
                {
                    altName: "",
                    sortOrder: 0,
                    bannerType: 0,
                    height: 0,
                    imageName: "",
                    imagePathName: "",
                    primaryImageType: "",
                    secondaryImageType: "",
                    bannerName: "Slide 1",
                    primaryImageUrl: "/img/slider/login-verification-df-lucid.jpg",
                    secondaryImageUrl: "",
                    status: 0,
                    width: 0
                }
            ],
            loggedIn: false,
        }
    }
    componentWillMount() {
        console.log(sessionStorage.getItem('verified-otp'))
        this.setState({ otpVerification: sessionStorage.getItem('verified-otp') });
        console.log(this.state.otpVerification)
        if (Config.IsMobileAccess) {
            if (!isMobile) {
                this.props.history.push('/deviceerror' + urlPattern);
                return false;
            }
        }
        //  sessionStorage.removeItem('user-mobileno');
        token = sessionStorage.getItem('user-token');
        tableNo = sessionStorage.getItem('user-tableno');
        serviceType = sessionStorage.getItem('user-servicetype');
        orgnationId = sessionStorage.getItem('user-orgnationid');
        propertyId = sessionStorage.getItem('user-propertyid');
        outletCode = sessionStorage.getItem('user-outletcode');
        statsId = sessionStorage.getItem('user-statsid');
        groupSession = sessionStorage.getItem('group-session') == null ? false : sessionStorage.getItem('group-session');
        mainGuestMobileNo = sessionStorage.getItem('main-guest-no') == null ? '' : sessionStorage.getItem('main-guest-no');
        mainGuestMaskMobileNo = sessionStorage.getItem('main-guest-mask-no') == null ? '' : sessionStorage.getItem('main-guest-mask-no');
        pageSession = sessionStorage.getItem('login-page-session') == null ? 1 : parseInt(sessionStorage.getItem('login-page-session'));
        outletDetails = sessionStorage.getItem('user-outletDetails') != null ? JSON.parse(sessionStorage.getItem("user-outletDetails")) : '';
        urlPattern =   sessionStorage.getItem('url-pattern');

        const sessionDt = sessionStorage.getItem("u-l-v-url-dt");
        if (!sessionDt) {
            this.props.history.push('/sessionerror' + urlPattern);
            return false;
        }
       
        if (!urlPattern) {        
            this.props.history.push('/qrcodeerror' + Config.defaultUrlPattern);
            return false;
          }
       
        if (tableNo === undefined || tableNo === "" || tableNo === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
         //  Added by vijay on 01-02-2024 Jira id - 2078 -Testing points Unwanted API calls
         if (token === undefined || token === "" || token === null) {
          this.props.history.push('/' + urlPattern);
            return false;
         }

        if (serviceType === undefined || serviceType === "" || serviceType === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (orgnationId === undefined || orgnationId === "" || orgnationId === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (propertyId === undefined || propertyId === "" || propertyId === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (outletCode === undefined || outletCode === "" || outletCode === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }

        if (statsId === undefined || statsId === "" || statsId === null) {
            statsId = "0"
        }


        if (this.props.location.state !== undefined) {
            if (this.props.location.state.mobileNo === undefined || this.props.location.state.mobileNo === null || this.props.location.state.mobileNo === '') {
                this.setState({ loading: true });
                this.showMessage('Invalid Mobile Number', "danger");
                setTimeout(() => {
                    this.setState({ loading: false });
                    this.props.history.push('/login' + urlPattern);
                }, Config.minMsgTimeout);
            }
            else {
                this.setState({
                    mobileNo: this.props.location.state.mobileNo
                })
                mobileNo = this.props.location.state.mobileNo;

                if (this.props.location.state.validateOTP) { this.setState({ inputPlaceholder: "Enter OTP" }); this.setState({ showOTP: true }); }
                if (this.props.location.state.validatePIN) { this.setState({ inputPlaceholder: "Enter PIN" }); this.setState({ showTablePIN: true }); }
                if (this.props.location.state.validateOTP && this.props.location.state.validatePIN) this.setState({ inputPlaceholder: "Enter PIN" });
                //console.log("this.props.location.state.validateOTP")
                //console.log(this.props.location.state.validateOTP)
                //console.log("this.props.location.state.validatePIN")
                //console.log(this.props.location.state.validatePIN)
                // Validate Group Pin Access
                // group pin commented by dasprakash on 27-09-2023 jira-1493
                // if (groupSession === "true") {
                //     if (mainGuestMobileNo !== mobileNo) {
                //         this.setState({
                //             showGroupPin: false
                //         });
                //     }
                // }
                // redirectToHome
                // group pin added by dasprakash on 27-09-2023 jira-1493
                // if (!this.props.location.state.validateOTP && !this.props.location.state.validatePIN) {
                //     this.redirectToHome()
                //     return false;
                // }





                // if (groupSession === "false") {
                //     this.setState({ loading: true });
                //     this.showMessage('Invalid Group Session', "danger");
                //     setTimeout(() => {
                //         this.setState({ loading: false });
                //         this.props.history.push('/login');
                //     }, 2000);
                // }          


            }
        }

        // //console.log(groupSession);

        // //console.log(this.props.location.state.validatePIN)
        // //console.log(this.state.showGroupPin);
       // Added by vijay on 12-01-2023 -Jira id -2075
     if (outletDetails === undefined || outletDetails === "" || outletDetails === null) {
     this.props.history.push('/' + urlPattern);
     return false;
}



        this.getCMSDetails(); //Get CMS Page

    }


    handlechange = event => {
        const value = event.target.value;
        this.setState({ otp: value });
        //  //console.log(value);
    };

    // group pin added by dasprakash on 27-09-2023 jira-1493
    handleTablePin = event => {
        const value = event.target.value;
        this.setState({ tablePin: value });
        //  //console.log(value);
    };

    handleGroupPinChange = event => {
        const value = event.target.value;
        this.setState({ groupPin: value });
    };

    // group pin added by dasprakash on 27-09-2023 jira-1493
    handleSessionVerified() {
        sessionStorage.setItem('user-table-verified', true);
        sessionStorage.removeItem('otpReferenceId');
        sessionStorage.setItem('user-loggedin', true);
        //sessionStorage.setItem('main-guest-no', mainGuestMobileNo);
        sessionStorage.setItem('main-guest-no', mainGuestMobileNo === '0000000000' ? mobileNo : mainGuestMobileNo);
        sessionStorage.setItem('main-guest-no', mainGuestMobileNo === '' ? mobileNo : mainGuestMobileNo);
        if (groupSession === "false") {
            sessionStorage.setItem('main-guest-no', this.state.mobileNo); // https://lucid.atlassian.net/browse/LC-1185
        }
        // set Logged in Date                                                                    
        let loggedIn = sHelpers.getFormattedDate(new Date());
        sessionStorage.setItem('user-loggedin-date', loggedIn);
        //console.log("pageSession")
        //console.log(pageSession)
        // Redirect to Page
        if (pageSession === Config.pageSession.Cart) {
            setTimeout(() => {
                this.setState({ loading: false });
                this.props.history.push('/cart' + urlPattern, { onClick: true });
            }, Config.minMsgTimeout);
            return false;
        }
        else if (pageSession === Config.pageSession.PayBill) {
            setTimeout(() => {
                this.setState({ loading: false });
                this.props.history.push('/mybills' + urlPattern);
            }, Config.minMsgTimeout);
            return false;
        }
        else if (pageSession === Config.pageSession.MyOrders) {
            setTimeout(() => {
                this.setState({ loading: false });
                this.props.history.push('/myorders' + urlPattern);
            }, Config.minMsgTimeout);
            return false;
        }
        else if (pageSession === Config.pageSession.ChooseAddress) {
            setTimeout(() => {
                this.setState({ loading: false });
                this.props.history.push('/cart' + urlPattern);
            }, Config.minMsgTimeout);
            return false;
        }
        else {
            setTimeout(() => {
                this.setState({ loading: false });
                this.props.history.push('/home' + urlPattern);
            }, Config.minMsgTimeout);
            return false;
        }

    }
    // 

    redirectToHome() {
        // Commended  by vijay on 02-02-2024 Jira id - 2078 -Testing points Unwanted API calls[GetGuestAppToken]
       // Get Token
      // let urlParam = '?OrganizationId=' + orgnationId + '&PropertyId=' + propertyId + '&TokenKey=' + Config.tokenKey;
       // //console.log(urlParam);
       // fetch(Config.basePOSUrl + 'GetGuestAppToken' + urlParam, {
       //     method: 'GET',
       //     headers: {
       //         'content-type': 'application/json'
       //     }
       // })
           // .then(res => res.json())
           // .then(response => {
               //console.log("response1");
               //console.log(response);

               // let cookie = new Cookies();
               // if (response.errorCode === 0) {
               //     //                                //console.log("res " + JSON.parse(response));                            
               //     token = response.response.token;
               //     //  //console.log("token: " + response.response.token);
               //     // get Outlet Details
               //     let uParam = '?OutletId=' + propertyId + outletCode + '&ServiceType=' + serviceType;

                 // Commended  by vijay on 02-02-2024 Jira id - 2075 -Testing points Unwanted API calls[GetOutletDetails]
              
                 //     fetch(Config.basePOSUrl + 'GetOutletDetails' + uParam, {
               //         method: 'GET',
               //         headers: {
               //             Authorization: "Bearer " + token,
               //             'content-type': 'application/json'
               //         }
               //     })
               //         .then(res => res.json())
               //         .then(response => {
                           //console.log("response2loginveri");
                           //console.log(response);
                           // if (response.errorCode === 0) {
                              // sessionStorage.setItem("user-outletDetails", JSON.stringify(response.response.outletDetails));
                               sessionStorage.setItem('user-token', token);
                               sessionStorage.setItem('user-mobileno', mobileNo);
                               sessionStorage.setItem('main-guest-mask-no', mainGuestMaskMobileNo);
                               sessionStorage.setItem('main-guest-no', mainGuestMobileNo === '' ? mobileNo : mainGuestMobileNo);
                               //console.log("first time check");
                               //console.log(sessionStorage.getItem('main-guest-no'));

                               // Temperary hard code for table pin switch value as false
                             //  response.response.outletDetails.checkInWithTablePin = false;
                               outletDetails.checkInWithTablePin = false;

                               // digital Menu
                               if (outletDetails.enableGuestAppOrders === false && outletDetails.enableDigitalMenu === true) {
                                   // Added by vijay on 02-02-2024 -Jira id -2078 -Due to V2 like same lines added
                                   sessionStorage.setItem("user-outletDetails", JSON.stringify(outletDetails));
                                   sessionStorage.setItem('user-token', token);                        
                                   sessionStorage.setItem('user-mobileno', mobileNo);
                                   sessionStorage.setItem('main-guest-mask-no', mainGuestMaskMobileNo);
                                   sessionStorage.setItem('main-guest-no', mainGuestMobileNo === '' ? mobileNo : mainGuestMobileNo);                 
                                   
                                   
                                   sessionStorage.setItem('user-table-verified', false);
                                   sessionStorage.setItem('user-digitalMenu', true);
                                   sessionStorage.setItem('user-loggedin', true);
                                   sessionStorage.setItem('main-guest-no', this.state.mobileNo);

                                   setTimeout(() => {
                                       this.props.history.push('/home' + urlPattern);
                                   }, Config.minMsgTimeout);
                                   return false;
                               }
                               if (outletDetails.checkInWithTablePin) {
                                   setTimeout(() => {
                                       this.setState({ loading: false });
                                       this.props.history.push('/tableverification' + urlPattern);
                                   }, Config.minMsgTimeout);
                               }
                               else {
                                   // if (serviceType === "FINE-DINE") { // Table Check In
                                   //     // group pin commended by dasprakash on 29-09-2023 jira-1493
                                   //     sessionStorage.setItem('group-pin', this.state.showGroupPin ? this.state.groupPin : '');
                                   //     //console.log("this.state.groupPin");
                                   //     //console.log(this.state.groupPin);
                                   //     //console.log(this.props.location.state.validateOTP);
                                   //     //console.log(this.props.location.state.validatePIN);

                                   //     let postObj = {
                                   //         OutletId: propertyId + outletCode,
                                   //         GuestName: sessionStorage.getItem("guest-name") === null ? ' ' : sessionStorage.getItem("guest-name"),
                                   //         MobileNo: this.state.mobileNo,
                                   //         TableNumber: tableNo,
                                   //         GroupCode: this.state.showGroupPin ? this.state.groupPin : ''
                                   //         // GroupCode: this.props.location.state.validateOTP === false &&
                                   //         //     this.props.location.state.validatePIN === true ?
                                   //         //     this.state.otp :
                                   //         //     this.state.showGroupPin ? this.state.groupPin : ''

                                   //     }
                                   //     //console.log("postObj")
                                   //     //console.log(postObj)

                                   //     fetch(Config.basePOSUrl + 'GuestCheckIn', {
                                   //         method: 'POST',
                                   //         headers: {
                                   //             Authorization: "Bearer " + token,
                                   //             'content-type': 'application/json'
                                   //         },
                                   //         body: JSON.stringify(postObj)
                                   //     })
                                   //         .then(res => res.json())
                                   //         .then(response => {
                                   //             //console.log("response.errorCode")
                                   //             //console.log(response)
                                   //             if (response.errorCode === 0) {
                                   //                 //console.log("code 0")
                                   //                 // group pin added by dasprakash on 27-09-2023 jira-1493
                                   //                 this.handleSessionVerified();
                                   //                 // 

                                   //                 // group pin commentewd by dasprakash on 27-09-2023 jira-1493
                                   //                 // sessionStorage.setItem('user-table-verified', true);
                                   //                 // sessionStorage.removeItem('otpReferenceId');
                                   //                 // sessionStorage.setItem('user-loggedin', true);
                                   //                 // //sessionStorage.setItem('main-guest-no', mainGuestMobileNo);
                                   //                 // sessionStorage.setItem('main-guest-no', mainGuestMobileNo === '0000000000' ? mobileNo : mainGuestMobileNo);
                                   //                 // sessionStorage.setItem('main-guest-no', mainGuestMobileNo === '' ? mobileNo : mainGuestMobileNo);
                                   //                 // if (groupSession === "false") {
                                   //                 //     sessionStorage.setItem('main-guest-no', this.state.mobileNo); // https://lucid.atlassian.net/browse/LC-1185
                                   //                 // }
                                   //                 // // set Logged in Date                                                                    
                                   //                 // let loggedIn = sHelpers.getFormattedDate(new Date());
                                   //                 // sessionStorage.setItem('user-loggedin-date', loggedIn);

                                   //                 // // Redirect to Page
                                   //                 // if (pageSession === Config.pageSession.Cart) {
                                   //                 //     setTimeout(() => {
                                   //                 //         this.setState({ loading: false });
                                   //                 //         this.props.history.push('/cart', { onClick: true });
                                   //                 //     }, 2000);
                                   //                 //     return false;
                                   //                 // }
                                   //                 // else if (pageSession === Config.pageSession.PayBill) {
                                   //                 //     setTimeout(() => {
                                   //                 //         this.setState({ loading: false });
                                   //                 //         this.props.history.push('/mybills');
                                   //                 //     }, 2000);
                                   //                 //     return false;
                                   //                 // }
                                   //                 // else if (pageSession === Config.pageSession.MyOrders) {
                                   //                 //     setTimeout(() => {
                                   //                 //         this.setState({ loading: false });
                                   //                 //         this.props.history.push('/myorders');
                                   //                 //     }, 2000);
                                   //                 //     return false;
                                   //                 // }
                                   //                 // else if (pageSession === Config.pageSession.ChooseAddress) {
                                   //                 //     setTimeout(() => {
                                   //                 //         this.setState({ loading: false });
                                   //                 //         this.props.history.push('/cart');
                                   //                 //     }, 2000);
                                   //                 //     return false;
                                   //                 // }
                                   //                 // else {
                                   //                 //     setTimeout(() => {
                                   //                 //         this.setState({ loading: false });
                                   //                 //         this.props.history.push('/home');
                                   //                 //     }, 2000);
                                   //                 //     return false;
                                   //                 // }

                                   //             }
                                   //             else {
                                   //                 //console.log("code 1")
                                   //                 //console.log(response.errorCode)
                                   //                 //console.log(response)
                                   //                 // sessionStorage.removeItem("user-token");
                                   //                 // sessionStorage.removeItem("user-mobileno");
                                   //                 this.showMessage(response.message, "danger");
                                   //                 // group pin added by dasprakash on 27-09-2023 jira-1493
                                   //                 // if (groupSession === 'true') {
                                   //                 //     this.handleSessionVerified();
                                   //                 //     return false;
                                   //                 // }
                                   //                 // 


                                   //                 setTimeout(() => {
                                   //                     this.setState({ loading: true });
                                   //                     // Redirect to Page
                                   //                     if (pageSession === Config.pageSession.Cart) {
                                   //                         setTimeout(() => {
                                   //                             this.setState({ loading: false });
                                   //                             this.props.history.push('/cart');
                                   //                         }, 2000);
                                   //                         return false;
                                   //                     }
                                   //                     else if (pageSession === Config.pageSession.PayBill) {
                                   //                         setTimeout(() => {
                                   //                             this.setState({ loading: false });
                                   //                             this.props.history.push('/mybills');
                                   //                         }, 2000);
                                   //                         return false;
                                   //                     }
                                   //                     else if (pageSession === Config.pageSession.MyOrders) {
                                   //                         setTimeout(() => {
                                   //                             this.setState({ loading: false });
                                   //                             this.props.history.push('/myorders');
                                   //                         }, 2000);
                                   //                         return false;
                                   //                     }
                                   //                     else {
                                   //                         setTimeout(() => {
                                   //                             this.setState({ loading: false });
                                   //                             this.props.history.push('/home');
                                   //                         }, 2000);
                                   //                         return false;
                                   //                     }
                                   //                 }, 1000);
                                   //                 // this.setState({ loading: true });
                                   //                 // setTimeout(() => {
                                   //                 //     this.setState({ loading: false });
                                   //                 //     this.props.history.push('/qrcoderror');
                                   //                 // }, 3000);
                                   //                 return false;
                                   //             }
                                   //         })
                                   //         .catch(err => {
                                   //             //  sessionStorage.removeItem("user-token");
                                   //             //sessionStorage.removeItem("user-mobileno");
                                   //             // //console.log("err")
                                   //             //console.log(err)
                                   //             this.showMessage('Guest Check IN  - Failed to fetch - ' + err, "danger");
                                   //         });
                                   // } // End Service Type - Fine Dine

                                   sessionStorage.setItem('user-table-verified', true);
                                   sessionStorage.removeItem('otpReferenceId');
                                   sessionStorage.setItem('user-loggedin', true);
                                   // group pin commented by dasprakash on 05-10-2023 jira-1490
                                   // sessionStorage.setItem('main-guest-no', this.state.mobileNo);
                                   // group pin added by dasprakash on 05-10-2023 jira-1490
                                   sessionStorage.setItem('main-guest-no', mainGuestMobileNo === '' || mainGuestMobileNo === '0000000000' ? this.state.mobileNo : mainGuestMobileNo);
                                   sessionStorage.removeItem('verified-otp');

                                   //console.log(sessionStorage.getItem('main-guest-no'));

                                   // Redirect to Page
                                   if (pageSession === Config.pageSession.Cart) {
                                       setTimeout(() => {
                                           this.setState({ loading: false });
                                           this.props.history.push('/cart' + urlPattern, { onClick: true });
                                       }, Config.minMsgTimeout);
                                       return false;
                                   }
                                   else if (pageSession === Config.pageSession.PayBill) {
                                       setTimeout(() => {
                                           this.setState({ loading: false });
                                           this.props.history.push('/mybills' + urlPattern);
                                       }, Config.minMsgTimeout);
                                       return false;
                                   }
                                   else if (pageSession === Config.pageSession.MyOrders) {
                                       setTimeout(() => {
                                           this.setState({ loading: false });
                                           this.props.history.push('/myorders' + urlPattern);
                                       }, Config.minMsgTimeout);
                                       return false;
                                   }
                                   else if (pageSession === Config.pageSession.ChooseAddress) {
                                       setTimeout(() => {
                                           this.setState({ loading: false });
                                           this.props.history.push('/cart' + urlPattern);
                                       }, Config.minMsgTimeout);
                                       return false;
                                   }
                                   else {
                                       setTimeout(() => {
                                           this.setState({ loading: false });
                                           this.props.history.push('/home' + urlPattern);
                                       }, Config.minMsgTimeout);
                                       return false;
                                   }
                               }

                           
                           // else {
                           //     // sessionStorage.removeItem("user-token");
                           //     // sessionStorage.removeItem("user-mobileno");

                           //     this.showMessage(response.message, "danger");
                           // }
                       // })
                       // .catch(err => {
                       //     //  sessionStorage.removeItem("user-token");
                       //     // sessionStorage.removeItem("user-mobileno");
                       //     this.showMessage('Outlet Details - Failed to fetch - ' + err, "danger");
                       // });
               // }
                //  Else condition Commended  by vijay on 02-02-2024 Jira id - 2078 -Testing points Unwanted API calls[GetGuestAppToken]
               // else {
               //     // cookie.remove('token');
               //     // cookie.remove('user-mobileNo');
               //     //  sessionStorage.removeItem("user-token");
               //     //   sessionStorage.removeItem("user-mobileno");
               //     this.showMessage(response.message, "danger");
               // }
           // })
           // .catch(err => {
           //     ////console.log(err);
           //     // sessionStorage.removeItem("user-token");
           //     //  sessionStorage.removeItem("user-mobileno");
           //     this.showMessage("An error occured", "danger");
           // });
   }



    // group pin added by dasprakash on 27-09-2023 jira-1493

    callClickURLDetail() {
        let notes = {
            guestname: sessionStorage.getItem("guest-name") === null ? ' ' : sessionStorage.getItem("guest-name"),
            modetype: outletDetails != null ? outletDetails.enableGuestAppOrders === true ? 1 : 0 : 0
        }
        let posturlObj = {
            organizationId: parseInt(orgnationId),
            propertyId: propertyId,
            outletCode: outletCode,
            ServiceMode: serviceType,
            ContactNo: this.state.mobileNo,
            StatsId: parseInt(statsId),
            username: Config.linkUserName,
            password: Config.linkPassword,
            notes: JSON.stringify(notes)
        }
        // Update Contact Detail

        fetch(Config.baseAPIUrl + 'ClickURLDetail', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(posturlObj)
        })
            .then(res => res.json())
            .then(response => {
               // console.log("response2xaxaxaxaxaxax");
                //console.log(response);
                this.redirectToHome();

            })
            .catch(err => {
                // //console.log(err);
                this.redirectToHome();
                //this.showMessage("CMS - Failed to fetch", "danger");
            });
    }
    // group pin added by dasprakash on 27-09-2023 jira-1493
    callValidateGuestLoginOTP() {
      //  console.log("callValidateGuestLoginOTP method");
        // group pin added by dasprakash on 09-10-2023 jira-1493
        if (sessionStorage.getItem('verified-otp') === "true" && serviceType === "FINE-DINE") {
            this.callGuestCheckIn()
            return false
        }
        //console.log("groupSession true");
        let otprefId = sessionStorage.getItem("otpReferenceId") === null ? '' : sessionStorage.getItem("otpReferenceId");
        let postObj = {
            GuestName: sessionStorage.getItem("guest-name") === null ? ' ' : sessionStorage.getItem("guest-name"),
            MobileNoCountryCode: "91",
            MobileNo: this.state.mobileNo,
            OTP: this.state.otp,
            OTPReferenceId: otprefId,
            OutletId: propertyId + outletCode,
            TableNumber: tableNo
        };
       // console.log(postObj);

        fetch(Config.baseGuestUrl + 'ValidateGuestLoginOTP', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(postObj)
        })
            .then(res => res.json())
            .then(response => {
              //  console.log("ValidateGuestLoginOTP");
              //  console.log(response);
                if (response.errorCode === 0) {
                    // Set the 'verified-otp' key in local storage with a value of true
                    sessionStorage.setItem('verified-otp', true);
                    sessionStorage.setItem('otp', this.state.otp);
                    // Retrieve the value of 'verified-otp' from local storage and update the component state
                    this.setState({ otpVerification: sessionStorage.getItem('verified-otp') });

                 //   console.log("calling guest checkin method");
                   
                    if(serviceType === "FINE-DINE")
                    {
                       // console.log("Service type fine dine ");
                        this.callGuestCheckIn()
                    }
                   
               else
                 {
                  //  console.log("Service type dine in ");
                this.setState({ pinverified: true })
                this.callClickURLDetail();
                }
                    
                } else {
                    this.showMessage(response.message, "danger");
                }

            })

    }

    /// Table Pin
    callGuestCheckIn() {
        //console.log("callGuestCheckIn")
        if (serviceType === "FINE-DINE") {
            sessionStorage.setItem('group-pin', this.state.showGroupPin ? this.state.groupPin : '');
            //console.log("this.state.groupPin");
            //console.log(this.state.groupPin);
            //console.log(this.props.location.state.validateOTP);
            //console.log(this.props.location.state.validatePIN);


            let postObj = {
                OutletId: propertyId + outletCode,
                GuestName: sessionStorage.getItem("guest-name") === null ? ' ' : sessionStorage.getItem("guest-name"),
                MobileNo: this.state.mobileNo,
                TableNumber: tableNo,
                GroupCode: this.state.tablePin
            }

           // console.log(postObj)
            // group pin added by dasprakash on 04-10-2023 jira-1490
            // group pin remove commented by dasprakash on 27-09-2023 jira-1493

            // if (groupSession.toString() === "false") {
            //     setTimeout(() => {
            //         this.setState({ loading: false });
            //         this.props.history.push('/jointable');
            //     }, 500);
            // }
            // 


            // group pin remove added by dasprakash on 27-09-2023 jira-1493
            sessionStorage.setItem('group-session', true);
            groupSession = true;
            // 
//              Commended  by vijay on 02-02-2024 Jira id - 2078 -Testing points Unwanted API calls[GetGuestAppToken]         
            // let urlParam = '?OrganizationId=' + orgnationId + '&PropertyId=' + propertyId + '&TokenKey=' + Config.tokenKey;
            // fetch(Config.basePOSUrl + 'GetGuestAppToken' + urlParam, {
            //     method: 'GET',
            //     headers: {
            //         'content-type': 'application/json'
            //     }
            // })
            //     .then(response => response.json())
            //     .then(data => {
            //         //console.log("Response1:");
            //       //  console.log(data);
            //         if (data.errorCode === 0) {
            //             token = data.response.token;
                        fetch(Config.basePOSUrl + 'GuestCheckIn', {
                            method: 'POST',
                            headers: {
                                Authorization: "Bearer " + token,
                                'content-type': 'application/json'
                            },
                            body: JSON.stringify(postObj)
                        })
                            .then(res => res.json())
                            .then(response => {
                               // console.log("GuestCheckIn response")
                              //  console.log(response)
                                if (response.errorCode === 0) {
                                    //  sessionStorage.setItem('table-pins', response.response.groupCode)
                                    sessionStorage.setItem('table-pins', this.state.tablePin);
                                    this.setState({ pinverified: true })
                                    this.callClickURLDetail();
                                }
                                else {
                                    //console.log("code 1")
                                    //console.log(response.errorCode)
                                    //console.log(response);
                                    this.showMessage(response.message, "danger");
                                }
                            })
                            .catch(err => {
                                //console.log("err")
                                //console.log(Config.basePOSUrl)
                                //console.log(token)
                                //console.log("JSON.stringify(postObj)")
                                //console.log(JSON.stringify(postObj))
                                this.showMessage('Guest Check IN  - Failed to fetch - ' + err, "danger");
                            });
                    //Else condition Commended  by vijay on 02-02-2024 Jira id - 2078 -Testing points Unwanted API calls[GetGuestAppToken]         
                //     } else {
                //         console.error("Error: " + data.errorMessage);
                //     }
                // })
                // .catch(error => {
                //     console.error('Fetch error:', error);
                // });
            // 

            // group pin commented by dasprakash on 04-10-2023 jira-1490
            // fetch(Config.basePOSUrl + 'GuestCheckIn', {
            //     method: 'POST',
            //     headers: {
            //         Authorization: "Bearer " + token,
            //         'content-type': 'application/json'
            //     },
            //     body: JSON.stringify(postObj)
            // })

            //     .then(res => res.json())
            //     .then(response => {
            //         //console.log("response.errorCode")
            //         //console.log(response)
            //         if (response.errorCode === 0) {
            //             this.callClickURLDetail();
            //         }
            //         else {
            //             //console.log("code 1")
            //             //console.log(response.errorCode)
            //             //console.log(response);
            //             this.showMessage(response.message, "danger");
            //         }
            //     })
            //     .catch(err => {
            //         //console.log("err")
            //         //console.log(Config.basePOSUrl)
            //         //console.log(token)
            //         //console.log("JSON.stringify(postObj)")
            //         //console.log(JSON.stringify(postObj))
            //         this.showMessage('Guest Check IN  - Failed to fetch - ' + err, "danger");
            //     });




        } // With out table service
        else {
            this.showMessage('Service mode does not support.', "danger");
        }
    }// end call check in



    handleClick = (e) => {
        e.preventDefault();
  //  console.log("groupSession123");
        //console.log(groupSession);

        this.setState({ loading: true });
        // group pin commented by asprakash on 27-09-2023 jira-1493
        // if (this.state.showGroupPin) {
        //     if (this.state.groupPin === "") {
        //         this.showMessage("Please enter the valid Group Pin.", "info")
        //         return false;
        //     }
        // }
        // group pin commented by dasprakash on 29-09-2023 jira-1490
        // if (this.props.location.state.validateOTP && groupSession === "false") {
        if (this.props.location.state.validateOTP) {
            if (this.state.otp === "" && sessionStorage.getItem('verified-otp') === "false") {
                this.showMessage("Please enter the valid OTP.", "info")
                return false;
            }
        }
        // group pin commented by dasprakash on 29-09-2023 jira-1490
        // else if (this.props.location.state.validatePIN && groupSession === "false") {
        if (this.props.location.state.validatePIN) {
            if (this.state.tablePin === "") {
                this.showMessage("Please enter the valid Table Pin .", "info")
                return false;
            }
        }
        //console.log(this.props.location.state.validatePIN)
        //console.log(this.props.location.state.validateOTP)

        if (this.props.location.state.validatePIN === true && this.props.location.state.validateOTP === false) {
            // console.log("con1")
            this.callGuestCheckIn();
        }
        else {
            // console.log("else")
            // console.log("this.state.otpVerification")
            // console.log(this.state.otpVerification)

            this.callValidateGuestLoginOTP()
        }

        // group pin commented by dasprakash on 29-09-2023 jira-1490
        // else {
        //     //console.log("urlParam");
        //     this.redirectToHome();
        //     return false;
        // }


        // // Get Token
        // let urlParam = '?OrganizationId=' + orgnationTd + '&PropertyId=' + propertyId + '&TokenKey=' + Config.tokenKey;
        // // //console.log(urlParam);
        // fetch(Config.basePOSUrl + 'GetGuestAppToken' + urlParam, {
        //     method: 'GET',
        //     headers: {
        //         'content-type': 'application/json'
        //     }
        // })
        //     .then(res => res.json())
        //     .then(response => {
        //         // let cookie = new Cookies();
        //         if (response.errorCode === 0) {
        //             //                                //console.log("res " + JSON.parse(response));                            
        //             let token = response.response.token;
        //             //console.log("token: " + response.response.token);
        //             // cookie.set('token', token);
        //             // cookie.set('user-mobileNo', this.state.mobileNo);
        //             // cookie.set('user-tableNo', this.state.tableNo);
        //             // cookie.set('user-serviceType', this.state.serviceType);

        //             sessionStorage.setItem('user-token', token);
        //             sessionStorage.setItem('user-mobileno', this.state.mobileNo);

        //             setTimeout(() => {
        //                 this.setState({ loading: false });
        //                 this.props.history.push('/home');
        //             }, 2000);
        //         }
        //         else {
        //             // cookie.remove('token');
        //             // cookie.remove('user-mobileNo');
        // sessionStorage.removeItem("user-token");
        // sessionStorage.removeItem("user-mobileno");
        //             this.showMessage(response.message, "danger");
        //         }
        //     })
        //     .catch(err => {
        //         //console.log(err);
        //         this.showMessage("An error occured", "danger");
        //     });

        //  let cookie = new Cookies();
        //let otprefId = cookie.get("otpReferenceId");

        // group pin commented by dasprakash on 29-09-2023 jira-1490

        // if (groupSession === "false") {
        // if (groupSession === "true") {
        //     //console.log("groupSession true");
        //     let otprefId = sessionStorage.getItem("otpReferenceId") === null ? '' : sessionStorage.getItem("otpReferenceId");
        //     let postObj = {
        //         GuestName: sessionStorage.getItem("guest-name") === null ? ' ' : sessionStorage.getItem("guest-name"),
        //         MobileNoCountryCode: "91",
        //         MobileNo: this.state.mobileNo,
        //         OTP: this.state.otp,
        //         OTPReferenceId: otprefId,
        //         OutletId: propertyId + outletCode,
        //         TableNumber: tableNo
        //     }

        //     //console.log(postObj);

        //     fetch(Config.baseGuestUrl + 'ValidateGuestLoginOTP', {
        //         method: 'POST',
        //         headers: {
        //             'content-type': 'application/json'
        //         },
        //         body: JSON.stringify(postObj)
        //     })
        //         .then(res => res.json())
        //         .then(response => {
        //             //console.log("response");
        //             //console.log(response);
        //             if (response.errorCode === 0) {
        //                 sessionStorage.setItem('user-loggedin', true);

        //                 let notes = {
        //                     guestname: sessionStorage.getItem("guest-name") === null ? ' ' : sessionStorage.getItem("guest-name"),
        //                     modetype: outletDetails != null ? outletDetails.enableGuestAppOrders === true ? 1 : 0 : 0
        //                 }
        //                 let posturlObj = {
        //                     organizationId: parseInt(orgnationId),
        //                     propertyId: propertyId,
        //                     outletCode: outletCode,
        //                     ServiceMode: serviceType,
        //                     ContactNo: this.state.mobileNo,
        //                     StatsId: parseInt(statsId),
        //                     username: Config.linkUserName,
        //                     password: Config.linkPassword,
        //                     notes: JSON.stringify(notes)
        //                 }
        //                 // Update Contact Detail

        //                 fetch(Config.baseAPIUrl + 'ClickURLDetail', {
        //                     method: 'POST',
        //                     headers: {
        //                         'content-type': 'application/json'
        //                     },
        //                     body: JSON.stringify(posturlObj)
        //                 })
        //                     .then(res => res.json())
        //                     .then(response => {
        //                         //console.log("response2");
        //                         //console.log(response);
        //                         this.redirectToHome();


        //                     })
        //                     .catch(err => {
        //                         // //console.log(err);
        //                         this.redirectToHome();
        //                         //this.showMessage("CMS - Failed to fetch", "danger");
        //                     });
        //             }
        //             else {
        //                 //  sessionStorage.removeItem("user-mobileno");
        //                 // //console.log(response.message);
        //                 this.showMessage(response.message, "danger");
        //             }
        //         })
        //         .catch(err => {
        //             // sessionStorage.removeItem("user-mobileno");
        //             //console.log(err);
        //             this.showMessage("An error occured", "danger");
        //         });
        // } // With out Group Session
        // else {
        //     //console.log("groupSession false");

        //     sessionStorage.setItem('user-loggedin', true);
        //     let posturlObj = {
        //         organizationId: parseInt(orgnationId),
        //         propertyId: propertyId,
        //         outletCode: outletCode,
        //         ServiceMode: serviceType,
        //         ContactNo: this.state.mobileNo,
        //         StatsId: parseInt(statsId),
        //         username: Config.linkUserName,
        //         password: Config.linkPassword
        //     }
        //     // Update Contact Detail
        //     fetch(Config.baseAPIUrl + 'ClickURLDetail', {
        //         method: 'POST',
        //         headers: {
        //             'content-type': 'application/json'
        //         },
        //         body: JSON.stringify(posturlObj)
        //     })
        //         .then(res => res.json())
        //         .then(response => {
        //             // //console.log("response");
        //             // //console.log(response);
        //             this.redirectToHome();
        //         })
        //         .catch(err => {
        //             //console.log(err);
        //             this.redirectToHome();
        //             //this.showMessage("CMS - Failed to fetch", "danger");
        //         });
        // }

        // 
    };


    handleResendOtpClick() {

        this.setState({ loading: true });

        let postObj = {
            GuestName: "",
            MobileNoCountryCode: "91",
            MobileNo: this.state.mobileNo,
            PropertyId: propertyId,
            OutletId: propertyId + outletCode
        }

        fetch(Config.baseGuestUrl + 'GuestLogin', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(postObj)
        })
            .then(res => res.json())
            .then(response => {
                ////console.log(response);
                if (response.errorCode === 0) {
                    this.showMessage("OTP sent successfully to mobile number " + this.state.mobileNo, "info");
                    sessionStorage.setItem('otpReferenceId', response.response.otpReferenceId);
                    sessionStorage.setItem('otp-exp-duration', response.response.otpExpiryDuration);
                }
                else if (response.errorCode === 4) // Redirect to Verification - Already Sent
                {
                    let minutes = response.response.otpExpiryDuration < 1 ? 1 : response.response.otpExpiryDuration;
                    this.showMessage("OTP Failed, Please request after " + minutes.toFixed(0) + " minutes.", "info");
                    sessionStorage.setItem('otpReferenceId', response.response.otpReferenceId);
                    sessionStorage.setItem('otp-exp-duration', response.response.otpExpiryDuration);
                }
                else {
                    this.showMessage(response.message, "danger");

                }
                window.scrollTo(0, 0);
            })
            .catch(err => {
                //  //console.log(err);
                this.showMessage("An error occured", "danger");
            });
    }

    goBack() {
        this.props.history.push('/login' + urlPattern);
    }

    getCMSDetails() {
        let postobj = {
            organizationId: parseInt(orgnationId),
            propertyId: propertyId,
            outletCode: outletCode,
            ServiceMode: serviceType,
            ApplicationCode: Config.applicationCode,
            PageId: Config.pageIds.login, // Default Login
            PageType: Config.pageType.login, // Login           
            RequestIp: "",
            username: Config.cmsUserName,
            password: Config.cmsPassword
        }

        fetch(Config.baseAPIUrl + 'PageDetails', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(postobj)
        })
            .then(res => res.json())
            .then(response => {
            //   console.log(response);
                if (response.isSuccessful) {

                    // Slider                   
                    if (response.banners.filter(i => i.bannerType === Config.bannerImageType.Slider) !== null && response.banners.filter(i => i.bannerType === Config.bannerImageType.Slider).length > 0)
                        this.setState({ sliderImages: response.banners.filter(i => i.bannerType === Config.bannerImageType.Slider) });

                    // if (response.page != null) {
                    //     if (response.page.preview !== '')
                    //         this.setState({ pageContent: response.page.content, pagePreview: response.page.preview });

                    // }
                }

            })
            .catch(err => {
                //console.log(err);
                //this.showMessage("CMS - Failed to fetch", "danger");
            });
    }

    showMessage(msg, type) {
        window.scrollTo(0, 0);
        this.setState({ msgShow: true });
        this.setState({ msgType: type });
        this.setState({ alertMessage: msg });
        this.setState({ loading: false });
        setTimeout(() => {
            this.setState({ msgShow: false });
        }, Config.minMsgTimeout);
    };
    render() {
        const { loading } = this.state;
        ////console.log(this.props.location.state.validatePIN);
        return (
            <div>
                {loading &&
                    <LoadingAnimation />
                }
                <div id="login-content">
                    {this.state.msgShow && <ErrorAlert alertMsg={this.state.alertMessage} msgType={this.state.msgType} msgShow={this.state.msgShow} />}
                    <Header loggedIn={this.state.loggedIn} />
                    <div className="content-wrap">
                        {/* <div className="img-hero">
                            <div>
                                <img src="../assets/theme1/img/otp-bg.png" alt="slider" />
                            </div>
                        </div> */}
                        <div style={{ marginTop: "50px", padding: "8px" }}>
                            <Slider {...homeslidersettings} className="img-hero">
                                {this.state.sliderImages.map(img =>
                                    <div key={"slider-img-" + img.sort}>
                                        <img src={img.primaryImageUrl} alt="lucid-slider-contact-less-order" />
                                    </div>
                                )}
                            </Slider>
                        </div>

                        <div className="login-section-home login-panel">
                            <div className="heading-section">
                                <div><img className="img-center" src="/img/user-img.png" alt="lucid-contactless-app-user-login" /></div>

                                <div className="sa-title popcat">Verification Code</div>
                                {!this.state.showGroupPin && <>
                                    {this.props.location.state.validateOTP && !this.state.otpVerification &&
                                        <div className="login-text">OTP has been sent to {this.state.mobileNo}</div>}
                                    {/* group pin commented by dasprakash on 29-09-2023 jira-1490 */}
                                    {/* {((this.props.location.state.validateOTP && this.props.location.state.validatePIN) && !this.state.showGroupPin) && <div className="login-text">(or)</div>} */}
                                    {/* {(this.props.location.state.validatePIN && !this.state.showGroupPin) && <div className="login-text">PIN - Collect from steward / captain</div>} */}
                                </>}
                                <div className="clear"></div>
                                <form className="form" action="##" method="post" id="setting">
                                    {!this.state.showGroupPin && <>

                                        {(this.props.location.state.validateOTP) &&
                                            < div className="form-group">
                                                {/* group pin added by dasprakash on 09-10-2023 jira-1490  */}
                                                {this.state.otpVerification ?
                                                    <>
                                                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", opacity: 0.5, pointerEvents: "none" }} disabled className="col-xs-12">
                                                            <svg style={{ position: "relative", top: "23px", left: "104px" }} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 25 25" fill="none">
                                                                <path d="M23.9587 12.5L21.417 9.59375L21.7712 5.75L18.0107 4.89583L16.042 1.5625L12.5003 3.08333L8.95866 1.5625L6.98991 4.88542L3.22949 5.72917L3.58366 9.58333L1.04199 12.5L3.58366 15.4062L3.22949 19.2604L6.98991 20.1146L8.95866 23.4375L12.5003 21.9062L16.042 23.4271L18.0107 20.1042L21.7712 19.25L21.417 15.4062L23.9587 12.5ZM9.77116 16.6771L7.29199 14.1771C7.19543 14.0807 7.11881 13.9662 7.06654 13.8402C7.01427 13.7142 6.98736 13.5791 6.98736 13.4427C6.98736 13.3063 7.01427 13.1712 7.06654 13.0452C7.11881 12.9192 7.19543 12.8047 7.29199 12.7083L7.36491 12.6354C7.77116 12.2292 8.43783 12.2292 8.84408 12.6354L10.5212 14.3229L15.8857 8.94792C16.292 8.54167 16.9587 8.54167 17.3649 8.94792L17.4378 9.02083C17.8441 9.42708 17.8441 10.0833 17.4378 10.4896L11.2712 16.6771C10.8441 17.0833 10.1878 17.0833 9.77116 16.6771Z" fill="#18A437" />
                                                            </svg>
                                                            <input type="text" className="form-control login-input" onChange={(e) => this.handlechange(e)} ref={(input) => { this.nameInput = input; }} name="input-mobile-no" id="input-mobile-no" placeholder={sessionStorage.getItem('otp')} maxLength="6" autoComplete="off" />
                                                            <span style={{ borderBottom: "1px solid #d12f8b", marginTop: "10px" }} className="resend-otp-text" onClick={this.handleResendOtpClick.bind(this)}>Resend OTP</span>
                                                        </div>
                                                    </>
                                                    :
                                                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }} className="col-xs-12">                                                     
                                                        <input type="text" className="form-control login-input" onChange={(e) => this.handlechange(e)} ref={(input) => { this.nameInput = input; }} name="input-mobile-no" id="input-mobile-no" placeholder="Enter OTP" maxLength="6" autoComplete="off" />
                                                        <span style={{ borderBottom: "1px solid #d12f8b", marginTop: "10px" }} className="resend-otp-text" onClick={this.handleResendOtpClick.bind(this)}>Resend OTP</span>
                                                    </div>
                                                }

                                            </div>
                                        }
                                        {/* group pin commented by dasprakash on 29-09-2023 jira-1490 */}

                                        {/* {((this.props.location.state.validateOTP && this.props.location.state.validatePIN) && this.state.showGroupPin) &&
                                            < div className="form-group">
                                                <div className="col-xs-12">
                                                    <input type="text" className="form-control login-input" onChange={(e) => this.handlechange(e)} ref={(input) => { this.nameInput = input; }} name="input-mobile-no" id="input-mobile-no" placeholder="Enter OTP / PIN" maxLength="6" autoFocus autoComplete="off" />
                                                </div>
                                            </div>
                                        } */}
                                        {this.props.location.state.validatePIN &&
                                            <>
                                                {!this.state.pinverified ?
                                                    <>
                                                        <div className="login-text">PIN - Collect from steward / captain</div>
                                                        <div className="form-group">
                                                            <div className="col-xs-12">
                                                                <input type="text" className="form-control login-input" onChange={(e) => this.handleTablePin(e)} ref={(input) => { this.nameInput = input; }} name="input-mobile-no" id="input-mobile-no" placeholder={this.state.inputPlaceholder} maxLength="6" autoComplete="off" />
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <div className="login-text">PIN - Collect from steward / captain</div>
                                                        <div className="form-group">
                                                            <div className="col-xs-12">
                                                                <svg style={{ position: "relative", top: "23px", left: "250px" }} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 25 25" fill="none">
                                                                    <path d="M23.9587 12.5L21.417 9.59375L21.7712 5.75L18.0107 4.89583L16.042 1.5625L12.5003 3.08333L8.95866 1.5625L6.98991 4.88542L3.22949 5.72917L3.58366 9.58333L1.04199 12.5L3.58366 15.4062L3.22949 19.2604L6.98991 20.1146L8.95866 23.4375L12.5003 21.9062L16.042 23.4271L18.0107 20.1042L21.7712 19.25L21.417 15.4062L23.9587 12.5ZM9.77116 16.6771L7.29199 14.1771C7.19543 14.0807 7.11881 13.9662 7.06654 13.8402C7.01427 13.7142 6.98736 13.5791 6.98736 13.4427C6.98736 13.3063 7.01427 13.1712 7.06654 13.0452C7.11881 12.9192 7.19543 12.8047 7.29199 12.7083L7.36491 12.6354C7.77116 12.2292 8.43783 12.2292 8.84408 12.6354L10.5212 14.3229L15.8857 8.94792C16.292 8.54167 16.9587 8.54167 17.3649 8.94792L17.4378 9.02083C17.8441 9.42708 17.8441 10.0833 17.4378 10.4896L11.2712 16.6771C10.8441 17.0833 10.1878 17.0833 9.77116 16.6771Z" fill="#18A437" />
                                                                </svg>
                                                                <input type="text" className="form-control login-input" onChange={(e) => this.handleTablePin(e)} ref={(input) => { this.nameInput = input; }} name="input-mobile-no" id="input-mobile-no" placeholder={sessionStorage.getItem('table-pins')} maxLength="6" autoComplete="off" />
                                                            </div>
                                                        </div>
                                                    </>}

                                            </>
                                        }
                                    </>
                                    }
                                    {
                                        this.state.showGroupPin &&
                                        <div className="form-group">
                                            <div className="col-xs-12">
                                                <p style={{ textAlign: "center" }}>You can get group pin from {mainGuestMaskMobileNo}</p>
                                                <input type="text" className="form-control login-input" onChange={(e) => this.handleGroupPinChange(e)} ref={(input) => { this.nameInput = input; }} name="input-group-pin" id="input-group-pin" placeholder="Group Pin" maxLength="3" autoComplete="off" />
                                            </div>
                                        </div>
                                    }

                                    <div className="submit-btn">
                                        <div className="col-12">
                                            <button className="theme-button  login-send-otp-btn" onClick={(e) => this.handleClick(e)} id="input-btn-send-otp" disabled={loading}>Confirm</button>
                                        </div>
                                    </div>
                                    {(this.props.location.state.validateOTP && !this.state.showGroupPin) &&
                                        <div className="login-resend-otp">
                                            {/* <span className="resend-otp-text" onClick={this.handleResendOtpClick.bind(this)}>Resend OTP</span> */}
                                            <span className="resend-otp-text back" onClick={this.goBack.bind(this)} >Back</span>
                                        </div>
                                    }
                                </form>
                                <div className="clear"></div>
                            </div>

                        </div>
                    </div>
                </div>
            </div >
        )
    }
}

