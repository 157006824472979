import React, { Component } from 'react';
import './address.scss';
import { isMobile } from 'react-device-detect';
import sHelpers from '../../modules/helpers/statichelpers';
import LoadingAnimation from '../../components/loadinganimation';
import ErrorAlert from '../../components/alertmessages';
import Config from '../../data/SiteConfig';
let guestLoggedIn = "false";
let guestAddressId = 0;
let mobileNo = '';
let token = '';
let outletDetailsStr = null;
let outletDetails = {};
//let guestDetailsStr = null;
//let guestDetails = {};
let urlPattern = '';
export default class MyAddressList extends Component {
    constructor(props) {
        super(props)
        this.goBack = this.goBack.bind(this);
        this.state = {
            loading: false,
            alertMessage: '',
            msgShow: false,
            eBillUrl: '',
            isPay: false,
            billAmount: 0,
            posBillId: '',
            guestAddressList: []
        }
    }

    componentDidMount() {
        guestLoggedIn = sessionStorage.getItem('user-loggedin') == null ? "false" : sessionStorage.getItem('user-loggedin');
        guestAddressId = parseInt(sessionStorage.getItem('user-addressId') == null ? 0 : sessionStorage.getItem('user-addressId'));
        outletDetailsStr = sessionStorage.getItem('user-outletDetails');
        urlPattern =   sessionStorage.getItem('url-pattern');
      
        if (outletDetailsStr === undefined || outletDetailsStr === "" || outletDetailsStr === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        else {
            outletDetails = JSON.parse(outletDetailsStr);
        }
        if (Config.IsMobileAccess) {
            if (!isMobile) {
                this.props.history.push('/deviceerror' + urlPattern);
                return false;
            }
        }
        if (!sHelpers.inputParamValidation()) {
            this.props.history.push('/' + urlPattern);
        }

        const sessionDt = sessionStorage.getItem("u-l-v-url-dt");
        if (!sessionDt) {
            this.props.history.push('/sessionerror' + urlPattern);
            return false;
        }
        // this.setState({ loading: true });
        if (!urlPattern) {        
            this.props.history.push('/qrcodeerror' + Config.defaultUrlPattern);
            return false;
          }
        window.scrollTo(0, 0);

        if (guestLoggedIn === "false") {
            setTimeout(() => {
                this.setState({ loading: false });
                this.props.history.push('/cart' + urlPattern);
            }, Config.minMsgTimeout);
            return false;
        }

        //guestDetailsStr = sessionStorage.getItem('user-GuestDetails');
        token = sessionStorage.getItem('user-token');

        // if (guestDetailsStr === undefined || guestDetailsStr === "" || guestDetailsStr === null) {
        //     guestDetails = null;
        // }
        // else {
        //     guestDetails = JSON.parse(guestDetailsStr);
        // }
        this.getGuestList();
    };

    goBack() {
        this.setState({ loading: true });
        setTimeout(() => {
            this.setState({ loading: false });
            this.props.history.push('/cart' + urlPattern);
        }, 200);

    };

    gotoAddAddress() {
        this.setState({ loading: true });
        setTimeout(() => {
            this.setState({ loading: false });
            this.props.history.push('/addaddress' + urlPattern, {
                mode: Config.formMode.Add, addressId: 0, addressLine1: '', addressLine2: '', addressLine3: '',
                addressType: 'Home', addressTypeId: 1, areaName: outletDetails.address.area, cityName: outletDetails.address.city, landmark: '', zipCode: outletDetails.address.zipCode
            });
        }, 200);
    };

    getGuestList() {
        mobileNo = sessionStorage.getItem('user-mobileno');

        this.setState({ loading: true });
        fetch(Config.baseCRMUrl + 'GetGuestAddressList?GuestMobileNo=' + mobileNo, {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                'content-type': 'application/json'
            },
        })
            .then(res => res.json())
            .then(response => {
                this.setState({ loading: false });
                //  console.log(response);
                if (response.errorCode === 0) {
                    this.setState({ guestAddressList: response.response.addressList });
                }
                else {
                    this.setState({ guestAddressList: [] });
                    this.showMessage(response.message, "danger");
                }
            })
            .catch(err => {
                this.setState({ loading: false });
                this.showMessage('Guest Address List - Failed to fetch - ' + err, "danger");
            });
    };

    handleSelectAddress(addressId, addressTypeId, addressType, addressLine1, addressLine2, addressLine3, areaName, cityName, zipCode, landmark) {
        this.setState({ loading: true });
        let IsAddressChange = false;
        // console.log(addressId, addressTypeId, addressType, addressLine1, addressLine2, addressLine3, areaName, cityName, zipCode, landmark);
        if (addressLine1.trim() === '') {
            if (addressLine2.trim() === '') {
                if (addressLine3.trim() === '') {
                    this.showMessage('unable to add  this address. Please edit the address', "danger");
                    IsAddressChange = true;
                }
            }
        }
        else if (zipCode.trim() === '') {
            this.showMessage('Invalid zipcode, Please edit the address', "danger");
            IsAddressChange = true;
        }
        else if (cityName.trim() === '') {
            this.showMessage('Invalid city name, Please edit the address', "danger");
            IsAddressChange = true;
        }
        else if (areaName.trim() === '') {
            this.showMessage('Invalid area name, Please edit the address', "danger");
            IsAddressChange = true;
        }

        if (IsAddressChange) {
            this.setState({ loading: false });
        }
        else {
            let selectedAddress = {
                addressId: addressId,
                addressLine1: addressLine1,
                addressLine2: addressLine2,
                addressLine3: addressLine3,
                addressType: addressType,
                addressTypeId: addressTypeId,//response.response.guestDetails.addressTypeId,
                area: areaName,
                city: cityName,
                landmark: landmark,
                zipCode: zipCode
            }
            sessionStorage.setItem('user-CurrentAddress', JSON.stringify(selectedAddress));
            setTimeout(() => {
                this.setState({ loading: false });
                this.props.history.push('/cart' + urlPattern);
            }, Config.minMsgTimeout);
        }

    };

    handleEditAddress(addressId, addressTypeId, addressType, addressLine1, addressLine2, addressLine3, areaName, cityName, zipCode, landmark) {
        // console.log(addressId, addressTypeId, addressType, addressLine1, addressLine2, addressLine3, areaName, cityName, zipCode, landmark);
        this.setState({ loading: true });
        setTimeout(() => {
            this.setState({ loading: false });
            this.props.history.push('/addaddress' + urlPattern, {
                mode: Config.formMode.Edit, addressId: addressId, addressLine1: addressLine1, addressLine2: addressLine2, addressLine3: addressLine3,
                addressType: addressType, addressTypeId: addressTypeId, areaName: areaName, cityName: cityName, landmark: landmark, zipCode: zipCode
            });
        }, 200);

    };


    showMessage(msg, type) {
        window.scrollTo(0, 0);
        this.setState({ msgShow: true });
        this.setState({ msgType: type });
        this.setState({ alertMessage: msg });
        this.setState({ loading: false });
        setTimeout(() => {
            this.setState({ msgShow: false });
        }, Config.minMsgTimeout);
    };


    render() {
        return (
            <>
                {this.state.loading && <LoadingAnimation />}
                {this.state.msgShow && <ErrorAlert alertMsg={this.state.alertMessage} msgType={this.state.msgType} msgShow={this.state.msgShow} />}
                <div id="content" className="top">
                    <div className="content-warp">
                        <div className="section-address">
                            <div className="heading-page" ref="recommended">
                                <span style={{ fontSize: "14px", fontWeight: 600 }}>Choose Address</span>
                                <div className="back-btn">
                                    <div className="icon-back" onClick={this.goBack}><i className="fa fa-angle-left"></i></div>
                                </div>
                            </div>
                            {this.state.guestAddressList.length < Config.GuestAddressCount && // Max 5
                                <div className="add-address" onClick={this.gotoAddAddress.bind(this)}>
                                    <span className="plus"><i className="fas fa-plus-circle"></i></span>   Add a new address
                                </div>
                            }
                            <div className="delivery-heading">
                                Delivering in
                            </div>
                            <div className="address-list">
                                <ul>
                                    {
                                        this.state.guestAddressList.map(a =>
                                            <li key={"add-" + a.addressId}>
                                                {a.addressId === guestAddressId && <div className="selected-address"><i className="fa fa-check" aria-hidden="true"></i></div>}
                                                <div className="content delivery" >
                                                    <div onClick={this.handleSelectAddress.bind(this, a.addressId, a.addressTypeId, a.addressType, a.addressLine1, a.addressLine2, a.addressLine3, a.areaName, a.cityName, a.zipCode, a.landmark)}>
                                                        <div className="addr-heading"><span className="addr-type">{a.addressType}</span></div>
                                                        <i className="fa fa-angle-right right-btn" ></i>
                                                        <div className="address">
                                                            {a.addressLine1} {a.addressLine2} {a.addressLine3}
                                                            <br />
                                                            {a.areaName}
                                                            <br />
                                                            {a.cityName} - {a.zipCode}
                                                            <br />
                                                            {a.landmark.trim() !== '' && 'Landmark: ' + a.landmark}
                                                        </div>
                                                    </div>
                                                    <button className="theme-button btn change-btn" onClick={this.handleEditAddress.bind(this, a.addressId, a.addressTypeId, a.addressType, a.addressLine1, a.addressLine2, a.addressLine3, a.areaName, a.cityName, a.zipCode, a.landmark)}>Edit</button>
                                                </div>
                                            </li>
                                        )
                                    }
                                    {/* <li>
                                        <div className="selected-address"><i className="fa fa-check" aria-hidden="true"></i></div>
                                        <div className="content delivery">
                                            <div className="addr-heading"><span className="addr-type">Home</span></div>
                                            <div className="address">
                                                65/1, Venkateshwara Colony,3rd Main,5th Cross, New Thippasandra ,
                                                Bangalore, Karnataka-560075,India. jeikum jey kumar jeyakumarcidit.com,9060960221.
                                    </div>
                                            <button className="theme-button btn change-btn">Edit</button>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="content delivery">
                                            <div className="addr-heading"><span className="addr-type">Work</span></div>

                                            <div className="address">
                                                65/1, Venkateshwara Colony,3rd Main,5th Cross, New Thippasandra ,
                                                Bangalore, Karnataka-560075,India. jeikum jey kumar jeyakumarcidit.com,9060960221.
                                    </div>
                                            <button className="theme-button btn change-btn">Edit</button>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="content delivery">
                                            <div className="addr-heading"><span className="addr-type">Other</span></div>
                                            <div className="address">
                                                65/1, Venkateshwara Colony,3rd Main,5th Cross, New Thippasandra ,
                                                Bangalore, Karnataka-560075,India. jeikum jey kumar jeyakumarcidit.com,9060960221.
                                    </div>
                                            <button className="theme-button btn change-btn">Edit</button>
                                        </div>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    };

}