import React, { Component } from "react"
import './process.scss';
//import LoadingAnimation from '../../components/loadinganimation/verificationloading';
// import LoadingAnimation from '../../components/loadinganimation/paymentloading';
import LoadingAnimation from '../../components/loadinganimation';
import * as QueryString from "query-string"
import Config from '../../data/SiteConfig';
import ErrorAlert from '../../components/alertmessages';
import { isMobile } from 'react-device-detect';
//import uuid from 'react-uuid';
let mobileNo = '';
let token = '';
let tableNo = '';
let serviceType = '';
let orgnationTd = '';
let propertyId = '';
let outletCode = '';
let paymentInitiatedId = '';
let vendorTransactionId = '';
let vendorId = '';
let vendorName = '';
let totalAmount = 0;
let mainGuestMobileNo = '';
let urlPattern = '';
export default class ProcessVerification extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            alertMessage: '',
            msgShow: false,
            paymentInitiatedId: "",
            statusMsg: ""
        }
    }

    componentDidMount() {
        if (Config.IsMobileAccess) {
            if (!isMobile) {
                this.props.history.push('/deviceerror' + urlPattern);
                return false;
            }
        }
        sessionStorage.removeItem('redirect-page');

        let inputParam = QueryString.parse(window.location.search);
        let vendorPaymentStatus = inputParam.payment_status;
        this.setState({ statusMsg: "Processing Your Request" });
        // console.log(inputParam);

        mobileNo = sessionStorage.getItem('user-mobileno');
        token = sessionStorage.getItem('user-token');
        tableNo = sessionStorage.getItem('user-tableno');
        serviceType = sessionStorage.getItem('user-servicetype');
        orgnationTd = sessionStorage.getItem('user-orgnationid');
        propertyId = sessionStorage.getItem('user-propertyid');
        outletCode = sessionStorage.getItem('user-outletcode');
        mainGuestMobileNo = sessionStorage.getItem('main-guest-no') == null ? '' : sessionStorage.getItem('main-guest-no');

        paymentInitiatedId = sessionStorage.getItem('paymentInitiatedId');
        vendorTransactionId = sessionStorage.getItem('vendorTransactionId');
        vendorId = sessionStorage.getItem('vendorId');
        vendorName = sessionStorage.getItem('vendorName');
        totalAmount = sessionStorage.getItem('payAmount');
        urlPattern = sessionStorage.getItem('url-pattern');

        const sessionDt = sessionStorage.getItem("u-l-v-url-dt");
        if (!sessionDt) {
            this.props.history.push('/sessionerror' + urlPattern);
            return false;
        }

        if (!urlPattern) {
            this.props.history.push('/qrcodeerror' + Config.defaultUrlPattern);
            return false;
        }
        if (token === undefined || token === "" || token === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (mobileNo === undefined || mobileNo === "" || mobileNo === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (tableNo === undefined || tableNo === "" || tableNo === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (serviceType === undefined || serviceType === "" || serviceType === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (orgnationTd === undefined || orgnationTd === "" || orgnationTd === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (propertyId === undefined || propertyId === "" || propertyId === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (outletCode === undefined || outletCode === "" || outletCode === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }

        if (paymentInitiatedId === undefined || paymentInitiatedId === "" || paymentInitiatedId === null) {
            this.showMessage('Transaction failed. Please try again', "danger");
            this.setState({ loading: true });
            setTimeout(() => { this.props.history.push('/mybills' + urlPattern); }, Config.averageMsgTimeout);
            return false;
        }

        window.scrollTo(0, 0);

        if (vendorPaymentStatus !== null && vendorPaymentStatus !== "Failed") {
            // API Call
            this.handleCheckPaymentStatus();
        }
        else {
            sessionStorage.removeItem("paymentInitiatedId");
            sessionStorage.removeItem("vendorTransactionId");
            sessionStorage.removeItem("vendorId");
            sessionStorage.removeItem("vendorName");
            sessionStorage.removeItem("paymentStatus");
            sessionStorage.removeItem('payAmount');
            this.setState({ loading: false, statusMsg: "Redirecting..." });
            this.showMessage('Transaction failed. Please try again', "danger");
            setTimeout(() => { this.setState({ loading: true }); setTimeout(() => { this.props.history.push('/mybills' + urlPattern); }, Config.averageMsgTimeout); }, Config.averageMsgTimeout);
        }
    }
    handleCheckPaymentStatus() {

        this.setState({ loading: true });
        fetch(Config.basePaymentUrl + 'GetPaymentStatus?PaymentInitiatedId=' + paymentInitiatedId, {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                'content-type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(response => {
                if (response.errorCode === 0) {
                    setTimeout(() => {
                        // console.log(response);
                        if (response.response.status === "SUCCESS") {
                            this.handleBillandSettle();
                        }
                        else if (response.response.status === "PENDING") {
                            setTimeout(() => {
                                this.handleCheckPaymentStatus();
                            }, Config.averageMsgTimeout);
                        }
                        else {
                            this.setState({ loading: true });
                            this.setState({ statusMsg: "Transaction failed. Please try again" });
                            this.showMessage('Transaction failed. Please try again', "danger");
                            setTimeout(() => { this.props.history.push('/mybills' + urlPattern); }, Config.minMsgTimeout);
                        }

                        //  window.location.href = response.response.vendorRedirectUrl;

                        // this.props.history.push('/paymentVerification', {
                        //     getMenus: this.state.getMenus,
                        //     getItems: this.state.getItems,
                        //     getModifiers: this.state.getModifiers,
                        //     getTaxes: this.state.getTaxes,
                        //     getCharges: this.state.getCharges
                        // });
                    }, 2000);
                }
                else {
                    //  console.log(response.message);
                    this.setState({ loading: true });
                    this.showMessage(response.message, "danger");
                    this.setState({ statusMsg: response.message });
                    setTimeout(() => { this.setState({ loading: false }); this.props.history.push('/mybills' + urlPattern); }, 1500);
                }
            })
            .catch(err => {
                // console.log(err);
                this.setState({ loading: true });
                this.showMessage('Transaction failed. Please try again', "danger");
                setTimeout(() => { this.setState({ loading: false }); this.props.history.push('/mybills' + urlPattern); }, 1500);
            });
    }

    handleBillandSettle() {
        let paymentDetails = [];

        paymentDetails.push({
            PayMode: "ONLP", // Online Pay 
            PaymentAmount: totalAmount,
            TransactionRefId: vendorTransactionId,
            PaymentVendorId: vendorId, // Vendor Id
            PaymentVendorName: vendorName
        });

        let postObj = {
            OutletId: propertyId + outletCode,
            ServiceType: serviceType,
            TableNumber: tableNo,
            OrderAmount: totalAmount,
            PaymentDetails: paymentDetails,
            GuestName: sessionStorage.getItem("main-guest-name") === null ? (sessionStorage.getItem("guest-name") === null ? ' ' : sessionStorage.getItem("guest-name")) : sessionStorage.getItem("main-guest-name"),
            // MobileNo: mainGuestMobileNo
            MobileNo: (mainGuestMobileNo === '' || mainGuestMobileNo === "0000000000") ? mobileNo : mainGuestMobileNo
        }
        this.setState({ loading: true });

        // console.log(postObj);

        fetch(Config.basePOSUrl + 'BillAndSettle', {
            method: 'POST',
            headers: {
                Authorization: "Bearer " + token,
                'content-type': 'application/json'
            },
            body: JSON.stringify(postObj)
        })
            .then(res => res.json())
            .then(response => {
                if (response.errorCode === 0) {
                    setTimeout(() => {
                        this.setState({ loading: false });
                        sessionStorage.removeItem("paymentInitiatedId");
                        sessionStorage.removeItem("vendorTransactionId");
                        sessionStorage.removeItem("vendorId");
                        sessionStorage.removeItem("vendorName");
                        sessionStorage.removeItem("paymentStatus");
                        sessionStorage.removeItem("payAmount");
                        sessionStorage.removeItem("order-instruction");

                        //  console.log(response);
                        this.props.history.push('/processsuccess' + urlPattern,
                            {
                                billSuccess: true
                            });
                    }, 2000);
                }
                else {
                    //  console.log(response.message);
                    this.setState({ loading: true });
                    this.showMessage(response.message, "danger");
                    setTimeout(() => { this.setState({ loading: false }); this.props.history.push('/mybills' + urlPattern); }, Config.minMsgTimeout);
                }
            })
            .catch(err => {
                //  console.log(err);
                this.setState({ loading: true });
                this.showMessage('Error the bill was not settled', "danger");
                setTimeout(() => { this.setState({ loading: false }); this.props.history.push('/mybills' + urlPattern); }, Config.minMsgTimeout);
            });
    };

    showMessage(msg, type) {
        window.scrollTo(0, 0);
        this.setState({ msgShow: true });
        this.setState({ msgType: type });
        this.setState({ alertMessage: msg });
        this.setState({ loading: false });
        setTimeout(() => {
            this.setState({ msgShow: false });
        }, 2000);
    };

    render() {
        return (
            <div>
                {this.state.loading && <LoadingAnimation />}
                <div id="content" className="top">
                    {this.state.msgShow && <ErrorAlert alertMsg={this.state.alertMessage} msgType={this.state.msgType} msgShow={this.state.msgShow} />}
                    <div className="content-warp">
                        <div className="section-processverification">
                            <div className="heading-page">
                                {sessionStorage.getItem("user-propertyname")}
                                <span className="payment-txt">{sessionStorage.getItem("user-outletname")} - {sessionStorage.getItem("user-servicetype") === "FINE-DINE" ? 'Table Service' : sessionStorage.getItem("user-servicetype")} </span>
                                {/* <div className="back-btn">
                                    <div className="icon-back" onClick={this.goBack}><i className="fas fa-arrow-left" aria-hidden="true"></i></div>
                                </div> */}
                            </div>
                            <div className="content-from">
                                <div className="message-details">
                                    <div className="content">
                                        {/* <div><LoadingAnimation /></div> */}
                                        <div className="msg">{this.state.statusMsg}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}