import React, { Component } from 'react';
import './address.scss';
import { isMobile } from 'react-device-detect';
import sHelpers from '../../modules/helpers/statichelpers';
import LoadingAnimation from '../../components/loadinganimation';
import ErrorAlert from '../../components/alertmessages';
import Config from '../../data/SiteConfig';
let guestLoggedIn = "false";
let token = '';
let mobileNo = '';
let outletCode = '';
//let outletDetailsStr = null;
//let outletDetails = {}
//let guestName = '';
let urlPattern = '';
export default class MyAddressEntry extends Component {
    constructor(props) {
        super(props)
        this.goBack = this.goBack.bind(this);
        this.state = {
            loading: false,
            alertMessage: '',
            msgShow: false,
            eBillUrl: '',
            isPay: false,
            billAmount: 0,
            posBillId: '',
            addressTypeList: [],
            fields: {},
            addressTypeId: 0,
            addressType: '',
            addressId: 0
        }
    }

    componentDidMount() {

        const sessionDt = sessionStorage.getItem("u-l-v-url-dt");
        if (!sessionDt) {
            this.props.history.push('/sessionerror' + urlPattern);
            return false;
        }
        
        urlPattern =   sessionStorage.getItem('url-pattern');
        if (!urlPattern) {        
            this.props.history.push('/qrcodeerror' + Config.defaultUrlPattern);
            return false;
          }
        
        if (this.props.location.state !== undefined) {
            if (this.props.location.state.mode === undefined || this.props.location.state.mode === null || this.props.location.state.mode === 0) {
                this.setState({ loading: true });
                setTimeout(() => {
                    this.setState({ loading: false });
                    this.props.history.push('/cart' + urlPattern);
                }, 200);
            }
        }
        guestLoggedIn = sessionStorage.getItem('user-loggedin') == null ? "false" : sessionStorage.getItem('user-loggedin');
        if (Config.IsMobileAccess) {
            if (!isMobile) {
                this.props.history.push('/deviceerror' + urlPattern);
                return false;
            }
        }
        if (!sHelpers.inputParamValidation()) {
            alert();
            this.props.history.push('/' + urlPattern);
        }
        // this.setState({ loading: true });


        token = sessionStorage.getItem('user-token');
        outletCode = sessionStorage.getItem('user-outletcode');
        mobileNo = sessionStorage.getItem('user-mobileno');
        // outletDetailsStr = sessionStorage.getItem('user-outletDetails');
        //guestName = sessionStorage.getItem("guest-name") === null ? '' : sessionStorage.getItem("guest-name");

        // if (outletDetailsStr === undefined || outletDetailsStr === "" || outletDetailsStr === null) {
        //     // this.props.history.push('/');
        //     return false;
        // }
        // else {
        //     outletDetails = JSON.parse(outletDetailsStr);
        // }

        window.scrollTo(0, 0);
        if (guestLoggedIn === "false") {
            setTimeout(() => {
                this.setState({ loading: false });
                this.props.history.push('/cart' + urlPattern);
            }, 200);
            return false;
        }

        // Fill Datea


        let fields = this.state.fields;
        fields["address1"] = this.props.location.state.addressLine1;
        fields["address2"] = this.props.location.state.addressLine2;
        fields["address3"] = this.props.location.state.addressLine3;
        fields["areaname"] = this.props.location.state.areaName;
        fields["city"] = this.props.location.state.cityName;
        fields["landmark"] = this.props.location.state.landmark;
        fields["zipcode"] = this.props.location.state.zipCode;

        // this.setState({ fields["address1"]: this.props.location.state.addressLine1, })
        // document.getElementById('input-address1').focus();
        // ///this.state.fields["address1"] =
        // this.state.fields["address2"] = this.props.location.state.addressLine2;
        // this.state.fields["address3"] = this.props.location.state.addressLine3;
        // this.state.fields["areaname"] = this.props.location.state.areaName;
        // this.state.fields["city"] = this.props.location.state.cityName;
        // this.state.fields["landmark"] = this.props.location.state.landmark;
        // this.state.fields["zipcode"] = this.props.location.state.zipCode;
        // this.setState({ fields });
        this.setState({ addressTypeId: this.props.location.state.addressTypeId, addressType: this.props.location.state.addressType, addressId: this.props.location.state.addressId, fields });

        this.getAddressTypeList();
    };

    getAddressTypeList() {
        this.setState({ loading: true });
        fetch(Config.baseCRMUrl + 'GetAddressTypeList', {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                'content-type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(response => {
                this.setState({ loading: false });
                //  console.log(response);
                if (response.errorCode === 0) {
                    this.setState({ addressTypeList: response.response.addressTypes });
                }
                else {
                    this.setState({ loading: false });
                    this.showMessage(response.message, "danger");
                    this.setState({ addressTypeList: [] });
                }
            })
            .catch(err => {
                this.setState({ loading: false });
                this.showMessage(err, "danger");
            });
    };
    handleSaveAddress() {

        this.setState({ loading: true });

        if (!this.handleValidation()) {
            this.setState({ loading: false });
            return false;
        }

        let postObj = {
            GuestMobileNo: mobileNo,
            OutletCode: outletCode,
            AddressId: this.state.addressId,
            AddressTypeId: this.state.addressTypeId,
            AddressLine1: this.state.fields["address1"],
            AddressLine2: this.state.fields["address2"],
            AddressLine3: this.state.fields["address3"],
            AreaCode: 0,
            AreaName: this.state.fields["areaname"],
            CityCode: 0,
            CityName: this.state.fields["city"],
            ZipCode: this.state.fields["zipcode"],
            Landmark: this.state.fields["landmark"]
        }

        //   console.log(postObj);

        fetch(Config.baseCRMUrl + 'SaveGuestAddress', {
            method: 'POST',
            headers: {
                Authorization: "Bearer " + token,
                'content-type': 'application/json'
            },
            body: JSON.stringify(postObj)
        })
            .then(res => res.json())
            .then(response => {
                //  console.log(response);
                if (response.errorCode === 0) {
                    this.showMessage(response.message, "success");
                    this.setState({ loading: true });
                    setTimeout(() => {
                        this.props.history.push('/chooseaddress' + urlPattern);
                    }, Config.minMsgTimeout);
                }
                else {
                    this.setState({ loading: false });
                    this.showMessage(response.message, "danger");
                }
            })
            .catch(err => {
                this.setState({ loading: false });
                this.showMessage(err, "danger");
            });
    };

    goBack() {
        this.props.history.push('/chooseaddress' + urlPattern);
    };
    handleChange(field, e) {

        let fields = this.state.fields;
        // console.log(fields);        
        fields[field] = e.target.value;
        this.setState({ fields });
    };

    handleValidation() {
        let fields = this.state.fields;
        let formIsValid = true;

        //Address 1
        if (!fields["address1"]) {
            formIsValid = false;
            this.showMessage("House / Flat / Block No.  cannot be empty", "danger");
            document.getElementById('input-address1').focus();
            return formIsValid;
        }
        //Area Name
        if (!fields["areaname"]) {
            formIsValid = false;
            this.showMessage("Area Name  cannot be empty", "danger");
            document.getElementById('input-areaname').focus();
            return formIsValid;
        }

        //City
        if (!fields["city"]) {
            formIsValid = false;
            this.showMessage("City cannot be empty", "danger");
            document.getElementById('input-city').focus();
            return formIsValid;
        }

        // Zipcode         
        if (!fields["zipcode"]) {
            formIsValid = false;
            this.showMessage("Zipcode  cannot be empty", "danger");
            document.getElementById('input-zipcode').focus();
            return formIsValid;
        }

        // Type
        if (this.state.addressTypeId === 0) {
            formIsValid = false;
            this.showMessage("Please choose the save as", "danger");
            return formIsValid;
        }

        return formIsValid;

    };

    handleChangeAddressType(id, name) {
        console.log(id);
        this.setState({ addressTypeId: id });
        this.setState({ addressType: name });
    };


    showMessage(msg, type) {
        window.scrollTo(0, 0);
        this.setState({ msgShow: true });
        this.setState({ msgType: type });
        this.setState({ alertMessage: msg });
        this.setState({ loading: false });
        setTimeout(() => {
            this.setState({ msgShow: false });
        }, Config.minMsgTimeout);
    };

    render() {
        return (
            <>
                {this.state.loading && <LoadingAnimation />}
                {this.state.msgShow && <ErrorAlert alertMsg={this.state.alertMessage} msgType={this.state.msgType} msgShow={this.state.msgShow} />}
                <div id="content" className="top">
                    <div className="content-warp">
                        <div className="section-address">
                            <div className="heading-page" ref="recommended">
                                <span style={{ fontSize: "14px", fontWeight: 600 }}>Add Address</span>
                                <div className="back-btn">
                                    <div className="icon-back" onClick={this.goBack}><i className="fa fa-angle-left" ></i></div>
                                </div>
                            </div>
                            <div class="delivery-address">
                                <div style={{ fontSize: "18px", fontWeight: "500" }}>{this.state.addressType}</div>
                                <> {this.state.fields["address1"]}    {this.state.fields["address2"]}    {this.state.fields["address3"]}</>
                                <> <br />{this.state.fields["areaname"]}</>
                                <> <br />{this.state.fields["city"]} <> - </> {this.state.fields["zipcode"]}</>
                                <> <br />{this.state.fields["landmark"] !== '' && 'Landmark: ' + this.state.fields["landmark"]}</>
                            </div>
                            <div className="address-entry">
                                <div className="stt-form">
                                    <div className="form-group">
                                        <div className="col-xs-12">
                                            <label htmlFor="type">Save As <span style={{ color: "#f00" }}>*</span></label>
                                            <div>
                                                <ul>
                                                    {this.state.addressTypeList.map(t =>
                                                        <li key={"a-t-btn-" + t.addressTypeId}>
                                                            {(t.addressTypeId === this.state.addressTypeId) && <button className="theme-button btn address-type-btn selected-btn" onClick={this.handleChangeAddressType.bind(this, t.addressTypeId, t.addressType)}>{t.addressType}</button>}
                                                            {(t.addressTypeId !== this.state.addressTypeId) && <button className="theme-button btn address-type-btn" onClick={this.handleChangeAddressType.bind(this, t.addressTypeId, t.addressType)}>{t.addressType}</button>}
                                                        </li>
                                                    )}
                                                </ul>
                                                {/* <button className="theme-button btn address-type-btn selected-btn">Home</button>
                                                <button className="theme-button btn address-type-btn">Office</button>
                                                <button className="theme-button btn address-type-btn">Others</button> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="col-xs-12">
                                            <label htmlFor="address1">House / Flat / Block No. <span style={{ color: "#f00" }}>*</span></label>
                                            <input type="text" className="form-control" name="input-address1" id="input-address1" maxLength="100" onChange={this.handleChange.bind(this, "address1")} value={this.state.fields["address1"]} />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="col-xs-12">
                                            <label htmlFor="address2">Street / Building Name</label>
                                            <input type="text" className="form-control" name="input-address2" id="input-address2" maxLength="100" onChange={this.handleChange.bind(this, "address2")} value={this.state.fields["address2"]} />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="col-xs-12">
                                            <input type="text" className="form-control" name="input-address3" id="input-address3" maxLength="100" onChange={this.handleChange.bind(this, "address3")} value={this.state.fields["address3"]} />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="col-xs-12">
                                            <label htmlFor="areaname">Area Name <span style={{ color: "#f00" }}>*</span></label>
                                            <input type="text" className="form-control" name="input-areaname" id="input-areaname" maxLength="100" onChange={this.handleChange.bind(this, "areaname")} value={this.state.fields["areaname"]} />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="col-xs-12">
                                            <label htmlFor="city">City <span style={{ color: "#f00" }}>*</span></label>
                                            <input type="text" className="form-control" name="input-city" id="input-city" maxLength="100" onChange={this.handleChange.bind(this, "city")} value={this.state.fields["city"]} />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="col-xs-12">
                                            <label htmlFor="Zipcode">Zipcode <span style={{ color: "#f00" }}>*</span></label>
                                            <input type="text" className="form-control" name="input-zipcode" id="input-zipcode" maxLength="10" onChange={this.handleChange.bind(this, "zipcode")} value={this.state.fields["zipcode"]} />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="col-xs-12">
                                            <label htmlFor="landmark">Landmark</label>
                                            <input type="text" className="form-control" name="input-landmark" id="input-landmark" maxLength="100" onChange={this.handleChange.bind(this, "landmark")} value={this.state.fields["landmark"]} />
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="save-btn" onClick={this.handleSaveAddress.bind(this)}>
                                Save Address
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    };

}