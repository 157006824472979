import React, { Component } from 'react';
//import './request.scss';
//import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
//import Config from '../../data/SiteConfig';

export default class ImagePopup extends Component {
    constructor(props) {
        super(props)
        this.handlePopupImage = this.handlePopupImage.bind(this);
        this.state = {
        }

    }

    handlePopupImage() {
        //this.getGuestRequestList();        
        this.inputElement.click();
    }
    render() {
        return (
            <>
                <div className="section-image">
                    <div className="menu-goup">
                        <button className="theme-button btn" style={{ display: "none" }} ref={input => this.inputElement = input} data-toggle="modal" data-target="#image-modal"><i className="fa fa-utensils sidebar-icon"></i> MENU1</button>
                    </div>
                    <div className="modal fade" id="image-modal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content animate-bottom">
                                <div className="modal-header p-2">
                                    {/* <h6 className="modal-title" style={{ textTransform: "capitalize" }}>
                                        {this.props.imgItemName}
                                    </h6> */}

                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body" id="div-request-body" style={{ marginTop: "-12px" }}>
                                    <div className='single-item-wrapper'>
                                        <div className='single-item'>
                                            <div className='row'>
                                                <img className='img-fluid item-img' src={this.props.imgUrl} loading="lazy" alt={this.props.imgItemName + '-lucid-pos'} style={{ objectFit: "cover", width: "100%" }} />
                                            </div>
                                        </div>
                                        <div className='container-fluid single-item mt-3 pb-2'>
                                            <div className="row mb-2 mt-2 align-items-start">
                                                <app-item-type>
                                                    <div className="single-image-type">
                                                        {this.props.imgItemFType === 1 && <img className="food-type-img" src={"/img/f-t-veg.png"} alt="item" />}
                                                        {this.props.imgItemFType === 2 && <img className="food-type-img" src={"/img/f-t-nonveg.png"} alt="item" />}
                                                    </div>
                                                </app-item-type>
                                                <div className='col-8 p-0'>
                                                    <div className='item-name'>{this.props.imgItemName}</div>
                                                </div>
                                                <div className='col text-right'>
                                                    <p className='item-price '>
                                                        <span>₹{this.props.imgItemPrice}</span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col">
                                                    <p className="item-description">{this.props.imgItemDesc}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>




                                    <div></div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div >
            </>

        );
    }

}