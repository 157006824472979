import React, { Component } from 'react';
import './cart.scss';
import Config from '../../data/SiteConfig';
import enmConfig from '../../data/enumConfig';
//import uuid from 'react-uuid';
//import Cookies from 'universal-cookie';
import LoadingAnimation from '../../components/loadinganimation';
import ErrorAlert from '../../components/alertmessages';
import CartCalCulation from '../../modules/cart/cartcalculation';
import { isMobile } from 'react-device-detect';
//import sHelpers from '../../modules/helpers/statichelpers';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import sHelpers from '../../modules/helpers/statichelpers';

let totalItems = 0;
let totalItemAmount = 0;
let totalNetAmount = 0;
let cartList = {};
let modifierCartList = [];
let taxList = [];
let chargeList = [];
let orderChargeList = [];
let mobileNo = '';
let token = '';
let tableNo = '';
let serviceType = '';
let orgnationTd = '';
let propertyId = '';
let outletCode = '';
let totalChargesAmount = 0;
let totaltaxAmount = 0;
let outletDetails = null;
let olDetail = {};
let orderCheckoutType = 0;
let mainGuestMobileNo = '';
let groupPin = '';
let guestLoggedIn = "false";
let guestDetailsStr = null;
let guestDetails = {};
let guestCurrentAddress = null;
let guestName = '';
// Validate Order

// let vaildCartList = {};
// let vaildModifierCartList = [];
// let vaildTaxList = [];
// let vaildChargeList = [];
let vailduniquesGroups = [];
// let vaildtotalItemAmount = 0;
// let vaildtotalItemTaxAmount = 0; 
// let vaildtotalItemChargesAmount = 0;


// let vaildtotalChargesAmount = 0;
// let vaildtotalTaxAmount = 0;
// let vaildtotalAmount = 0;
// let vaildVendorOrderId = '';
let userSessionId = '';
let urlPattern = '';
export default class Cart extends Component {
    constructor(props) {
        super(props);
        this.goBack = this.goBack.bind(this);
        this.goPayments = this.goPayments.bind(this);
        this.handleValidateOrder = this.handleValidateOrder.bind(this);
        //this.handlePlaceOrder = this.handlePlaceOrder.bind(this);
        this.state = {
            loading: false,
            // cartItemList: [
            //     { mnuId: 1, mnuName: "Chilli Chicken", price: "166", qty: 2, foodType: 2, modifier: [] },
            //     { mnuId: 2, mnuName: "Chilli Paneer", price: "178", qty: 1, foodType: 1, modifier: [{ modifierId: 1, modifierName: "spicy", price: 10 }, { modifierId: 2, modifierName: "spicy", price: 10 }] },
            //     { mnuId: 3, mnuName: "Veg Manchurian", price: "127", qty: 1, foodType: 2, modifier: [{ modifierId: 3, modifierName: "jam", price: 22 }] }
            // ],
            cartItemList: [
                // { mnuId: 1, mnuName: "Chilli Chicken", price: "166", qty: 2, foodType: 2, modifier: [] },
                // { mnuId: 2, mnuName: "Chilli Paneer", price: "178", qty: 1, foodType: 1, modifier: [{ modifierId: 1, modifierName: "spicy", price: 10 }, { modifierId: 2, modifierName: "spicy", price: 10 }] },
                // { mnuId: 3, mnuName: "Veg Manchurian", price: "127", qty: 1, foodType: 2, modifier: [{ modifierId: 3, modifierName: "jam", price: 22 }] }
            ],
            getMenus: [],
            getItems: [],
            getModifiers: [],
            getTaxes: [],
            getCharges: [],
            orderId: 0,
            orderInstruction: "",
            alertMessage: '',
            msgType: '',
            msgShow: false,
            totalItems: 0,
            totalItemAmount: 0,
            totalAmount: 0,
            totalNetAmount: 0,
            totalChargesAmount: 0,
            totaltaxAmount: 0,
            modifierItemList: [],
            roundOff: 0
        }

    };
    componentWillMount() {

        if (Config.IsMobileAccess) {
            if (!isMobile) {
                this.props.history.push('/deviceerror' + urlPattern);
                return false;
            }
        }
        mobileNo = sessionStorage.getItem('user-mobileno');
        token = sessionStorage.getItem('user-token');
        tableNo = sessionStorage.getItem('user-tableno');
        serviceType = sessionStorage.getItem('user-servicetype');
        orgnationTd = sessionStorage.getItem('user-orgnationid');
        propertyId = sessionStorage.getItem('user-propertyid');
        outletCode = sessionStorage.getItem('user-outletcode');
        outletDetails = sessionStorage.getItem('user-outletDetails');
        mainGuestMobileNo = sessionStorage.getItem('main-guest-no') == null ? '' : sessionStorage.getItem('main-guest-no');
        groupPin = sessionStorage.getItem('group-pin') == null ? '' : sessionStorage.getItem('group-pin');
        guestLoggedIn = sessionStorage.getItem('user-loggedin') == null ? "false" : sessionStorage.getItem('user-loggedin');
        guestDetailsStr = sessionStorage.getItem('user-GuestDetails');
        //sessionStorage.removeItem("order-instruction");
       // sessionStorage.removeItem("user-vendorOrderId");
        guestCurrentAddress = sessionStorage.getItem('user-CurrentAddress') === null ? null : JSON.parse(sessionStorage.getItem('user-CurrentAddress'));
        guestName = sessionStorage.getItem('guest-name') === null ? '' : sessionStorage.getItem('guest-name');
        userSessionId = sessionStorage.getItem('user-session-id') == null ? "" : sessionStorage.getItem('user-session-id');
        urlPattern = sessionStorage.getItem('url-pattern');
        // remove Order charges
        sessionStorage.removeItem('order-charge-Amount');
        sessionStorage.removeItem('order-charge-Tax-Amount');
        sessionStorage.removeItem('order-charge-List');
        sessionStorage.removeItem('proceed-pod');

        // //console.log(mobileNo);
        // //console.log(token);
        // //console.log(tableNo);
        // //console.log(serviceType);
        // //console.log(orgnationTd);
        // //console.log(propertyId);
        // //console.log(outletCode);
        if (!urlPattern) {
            this.props.history.push('/qrcodeerror' + Config.defaultUrlPattern);
            return false;
        }

        const sessionDt = sessionStorage.getItem("u-l-v-url-dt");
        if (!sessionDt) {
            this.props.history.push('/sessionerror' + urlPattern);
            return false;
        }


        if (token === undefined || token === "" || token === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (mobileNo === undefined || mobileNo === "" || mobileNo === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (tableNo === undefined || tableNo === "" || tableNo === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (serviceType === undefined || serviceType === "" || serviceType === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (orgnationTd === undefined || orgnationTd === "" || orgnationTd === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (propertyId === undefined || propertyId === "" || propertyId === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (outletCode === undefined || outletCode === "" || outletCode === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }

        if (outletDetails === undefined || outletDetails === "" || outletDetails === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        else {
            olDetail = JSON.parse(outletDetails);
            orderCheckoutType = olDetail.orderCheckOutType;
        }

        if (guestDetailsStr === undefined || guestDetailsStr === "" || guestDetailsStr === null) {
            guestDetails = null;
        }
        else {
            guestDetails = JSON.parse(guestDetailsStr);
        }

        window.scrollTo(0, 0);
        document.body.classList.remove('modal-open');
        let modalPopup = document.querySelector('.modal-backdrop');
        if (modalPopup !== null) {
            modalPopup.classList.remove('modal-backdrop', 'fade', 'show');
        }

        /// Session Validation for Fine Dine
        // if (serviceType === "FINE-DINE") {
        //     if (!sHelpers.sessionValidation()) {
        //         this.showMessage("Your session has expired, please scan once again", "danger");
        //         this.setState({ loading: true });
        //         setTimeout(() => {
        //             this.setState({ loading: false });
        //             this.props.history.push('/logout');
        //         }, 3000);
        //         return false;
        //     }
        // }



        this.handleCartRecalculate();
        /// Check the Logged in Mode Click
        if (this.props.location.state !== undefined) {
            if (this.props.location.state.onClick !== undefined || this.props.location.state.onClick !== null || this.props.location.state.onClick !== false) {
                this.setState({ loading: true });

                setTimeout(() => {
                    this.setState({ loading: false });
                    let typeId = sessionStorage.getItem('on-click-mode') === null ? 0 : parseInt(sessionStorage.getItem('on-click-mode'));
                    if (typeId > 0) {
                        if (serviceType !== Config.serviceType.DELIVERY)
                            this.handleValidateOrder(typeId, false);
                    };
                }, Config.minMsgTimeout);
            }
        }
    };

    componentDidMount() {

        this.handleCartRecalculate();

        // get unique modifier group
        //set Order Instruction
        this.setState({ orderInstruction: (sessionStorage.getItem("order-instruction") === null || sessionStorage.getItem("order-instruction") === undefined) ? '' : sessionStorage.getItem("order-instruction") });


        // //console.log(_uniquesGroups);
    };

    handleCartRecalculate() {
        // re calculate cart value     
        let cartItemValue = 0;
        let cartItemQty = 0;
        let modifierCartValue = 0;
        let onBillTaxValue = 0;
        let orderLevelCharge = 0;
        let orderLevelChargeTaxAmount = 0;
        totaltaxAmount = 0;
        totaltaxAmount = 0;
        totalChargesAmount = 0;

        totalItemAmount = 0;
        totalNetAmount = 0;
        totalItems = 0;



        cartList = sessionStorage.getItem("cartList") === null ? [] : JSON.parse(sessionStorage.getItem("cartList"));
        taxList = sessionStorage.getItem("taxCartList") === null ? [] : JSON.parse(sessionStorage.getItem("taxCartList"));
        modifierCartList = sessionStorage.getItem("modifierCartList") === null ? [] : JSON.parse(sessionStorage.getItem("modifierCartList"));
        chargeList = sessionStorage.getItem("chargeCartList") === null ? [] : JSON.parse(sessionStorage.getItem("chargeCartList"));


        if (sessionStorage.getItem("cartList") !== null) {
            Array.prototype.forEach.call(cartList.itemList, itm => {
                cartItemValue += (itm.rate * itm.qty);
                cartItemQty += itm.qty;
                // //console.log(cartItemValue);
                let mdcartItemList = modifierCartList.filter(x => x.uid === itm.uid);
                // //console.log(mdcartItemList);
                Array.prototype.forEach.call(mdcartItemList, md => {
                    modifierCartValue += md.rate * itm.qty;
                });
            });

            totaltaxAmount = taxList.reduce((prev, next) => prev + next.taxAmount, 0);
            totaltaxAmount += chargeList.reduce((prev, next) => prev + next.totalTaxAmount, 0);
            totaltaxAmount += modifierCartList.reduce((prev, next) => prev + next.totalTaxAmount, 0);
            totalChargesAmount = chargeList.reduce((prev, next) => prev + next.chargeAmount, 0);
            totalChargesAmount += modifierCartList.reduce((prev, next) => prev + next.totalChargeAmount, 0);

            totalItemAmount = cartItemValue + modifierCartValue;
            onBillTaxValue = CartCalCulation.OnBillTaxCalculation(totalItemAmount);
            // //console.log(onBillTaxValue);        
            CartCalCulation.OrderLevelCharge(totalItemAmount);
            orderLevelCharge = sessionStorage.getItem('order-charge-Amount') === null ? 0 : parseFloat(sessionStorage.getItem('order-charge-Amount'));
            orderLevelChargeTaxAmount = sessionStorage.getItem('order-charge-Tax-Amount') === null ? 0 : parseFloat(sessionStorage.getItem('order-charge-Tax-Amount'));
            // //console.log(orderLevelCharge);
            totalChargesAmount += orderLevelCharge;
            totaltaxAmount += onBillTaxValue;
            totaltaxAmount += orderLevelChargeTaxAmount;
            totalNetAmount = totalItemAmount + totalChargesAmount + totaltaxAmount;
            totalItems = cartItemQty;

            cartList.totalItems = totalItems;
            cartList.totalAmount = totalItemAmount;
            sessionStorage.setItem("cartList", JSON.stringify(cartList));


            // round off
            let roundOffAmt = sHelpers.roundOff(olDetail.roundOffType, totalNetAmount, olDetail.roundOffValue);
            let rndOffAmt = roundOffAmt - totalNetAmount;
            totalNetAmount = roundOffAmt;
            // roundOff = roundOffAmt - totalNetAmount;
            ////console.log(roundOff.toFixed(2));

            this.setState({
                totaltaxAmount: totaltaxAmount,
                totalChargesAmount: totalChargesAmount,
                totalItemAmount: totalItemAmount,
                totalNetAmount: totalNetAmount,
                totalItems: cartItemQty,
                modifierItemList: modifierCartList,
                cartItemList: cartList.itemList,
                roundOff: rndOffAmt
            });
        }
        if (totalItems === 0) {
            this.setState({ loading: true });
            setTimeout(() => {
                sessionStorage.removeItem("cartList");
                sessionStorage.removeItem("taxCartList");
                sessionStorage.removeItem("modifierCartList");
                sessionStorage.removeItem("chargeCartList");
                sessionStorage.removeItem("paymentInitiatedId");
                sessionStorage.removeItem("paymentStatus");
                this.setState({ loading: false });
                this.props.history.push('/items' + urlPattern);
            }, Config.minMsgTimeout);
        }
    };

    goBack() {
        //this.props.history.goBack();
        this.props.history.push('/items' + urlPattern);
    };

    goPayments(checkoutTypeId) {

        this.setState({ loading: true });

        if (this.state.totalNetAmount > 0) {
            this.handleValidateOrder();
            // setTimeout(() => {
            //     this.setState({ loading: false });
            //     sessionStorage.setItem("order-instruction", this.state.OrderInstruction);
            //     this.props.history.push('/payment', { totalNetAmount: this.state.totalNetAmount, totalItems: this.state.totalItems });
            // }, 2000);
        }
        else {
            this.showMessage("Item details are required", "danger");
            this.setState({ loading: true });
            setTimeout(() => {
                this.setState({ loading: false });
                this.props.history.push('/items' + urlPattern);
            }, Config.minMsgTimeout);
        }
    }

    handleQtyIncDec(itmId, val, rate, itmName, itemId, groupId, foodType, isModifier, uid, taxId, chargeId) {
        if (isModifier === 0) {
            let qty = document.getElementById("" + uid + "").innerHTML === "" ? 0 : JSON.parse(document.getElementById("" + uid + "").innerHTML);
            let _qtyVal = val === 1 ? qty += 1 : qty -= 1;
            if (qty >= 0) {
                this.setState({ qty: _qtyVal });
                document.getElementById("" + uid + "").innerHTML = _qtyVal;
                totalItems = val === 1 ? totalItems += 1 : totalItems -= 1;
                totalItemAmount = val === 1 ? totalItemAmount += rate : totalItemAmount -= rate;
            }
            //let _amtVal = _qtyVal * rate;
            // totalAmount += _amtVal;        

            this.setState({ totalItems: totalItems });
            this.setState({ totalItemAmount: totalItemAmount });

            cartList.totalItems = totalItems;
            cartList.totalAmount = totalItemAmount;

            let index = cartList.itemList.findIndex(x => x.uid === uid);
            // if (index < 0) {
            //     cartList.itemList.push({ id: "" + itmId + "", itemId: itemId, groupId: groupId, foodType: foodType, qty: _qtyVal, rate: rate, itemName: itmName, isModifier: 0, uid: "" });
            // }
            if (index > -1) {
                if (_qtyVal > 0) {

                    // tax
                    let exTax = taxList.filter(x => x.uid === cartList.itemList[index].uid);
                    let txidx = -1;
                    Array.prototype.forEach.call(exTax, tx => {
                        txidx = taxList.findIndex(x => x.serialNo === tx.serialNo && x.uid === tx.uid);
                        taxList.splice(txidx, 1);
                    });

                    // charge
                    let exCharge = chargeList.filter(x => x.uid === cartList.itemList[index].uid);
                    let chgidx = -1;
                    Array.prototype.forEach.call(exCharge, chg => {
                        chgidx = chargeList.findIndex(x => x.uid === chg.uid);
                        chargeList.splice(chgidx, 1);
                    });

                    cartList.itemList[index].qty = _qtyVal;

                    sessionStorage.setItem("cartList", JSON.stringify(cartList));
                    sessionStorage.setItem("taxCartList", JSON.stringify(taxList));
                    sessionStorage.setItem("chargeCartList", JSON.stringify(chargeList));
                    CartCalCulation.itemTaxCartCalculation(taxId, rate, _qtyVal, itemId, cartList.itemList[index].uid);
                    CartCalCulation.itemChargeCartCalculation(chargeId, rate, _qtyVal, itemId, cartList.itemList[index].uid);
                    this.handleCartRecalculate();
                }
                else {

                    // tax
                    let exTax = taxList.filter(x => x.uid === cartList.itemList[index].uid);
                    let txidx = -1;
                    Array.prototype.forEach.call(exTax, tx => {
                        txidx = taxList.findIndex(x => x.serialNo === tx.serialNo && x.uid === tx.uid);
                        taxList.splice(txidx, 1);
                    });

                    // charge
                    let exCharge = chargeList.filter(x => x.uid === cartList.itemList[index].uid);
                    let chgidx = -1;
                    Array.prototype.forEach.call(exCharge, chg => {
                        chgidx = chargeList.findIndex(x => x.uid === chg.uid);
                        chargeList.splice(chgidx, 1);
                    });

                    cartList.itemList.splice(index, 1);

                    sessionStorage.setItem("cartList", JSON.stringify(cartList));
                    sessionStorage.setItem("taxCartList", JSON.stringify(taxList));
                    sessionStorage.setItem("chargeCartList", JSON.stringify(chargeList));
                    this.handleCartRecalculate();
                }

            }


        }
        else { // modifier Items

            // cart
            let index = cartList.itemList.findIndex(x => x.uid === "" + uid + "");

            if (index > -1)
            //  cartList.itemList.push({ id: "" + itmId + "", itemId: itemId, groupId: groupId, foodType: foodType, qty: _qtyVal, rate: rate, itemName: itmName, isModifier: 0, uid: "" });
            {

                let qty = document.getElementById("" + uid + "").innerHTML === "" ? 0 : JSON.parse(document.getElementById("" + uid + "").innerHTML);
                let _qtyVal = val === 1 ? qty += 1 : qty -= 1;

                if (_qtyVal > 0) {
                    this.setState({ qty: _qtyVal });
                    document.getElementById("" + uid + "").innerHTML = _qtyVal;
                    totalItems = val === 1 ? totalItems += 1 : totalItems -= 1;
                    totalItemAmount = val === 1 ? totalItemAmount += rate : totalItemAmount -= rate;

                    this.setState({ totalItems: totalItems });
                    this.setState({ totalItemAmount: totalItemAmount });

                    cartList.totalItems = totalItems;
                    cartList.totalAmount = totalItemAmount;

                    // tax
                    let exTax = taxList.filter(x => x.uid === cartList.itemList[index].uid);
                    let txidx = -1;
                    Array.prototype.forEach.call(exTax, tx => {
                        txidx = taxList.findIndex(x => x.serialNo === tx.serialNo && x.uid === tx.uid);
                        taxList.splice(txidx, 1);
                    })

                    // charge
                    let exCharge = chargeList.filter(x => x.uid === cartList.itemList[index].uid);
                    let chgidx = -1;
                    Array.prototype.forEach.call(exCharge, chg => {
                        chgidx = chargeList.findIndex(x => x.uid === chg.uid);
                        chargeList.splice(chgidx, 1);
                    });

                    // modifier
                    // charge
                    let exmodifierList = modifierCartList.filter(x => x.uid === cartList.itemList[index].uid);
                    Array.prototype.forEach.call(exmodifierList, md => {
                        let txList = CartCalCulation.modifierItemTaxCartCalculation(md.taxId, md.rate, _qtyVal, md.itemId, md.uid);
                        let txAmount = txList.reduce((prev, next) => prev + next.taxAmount, 0);
                        let chgList = CartCalCulation.modifierItemChargeCartCalculation(md.chargeIds, md.rate, _qtyVal, md.itemId, md.uid);
                        let chgAmount = chgList.reduce((prev, next) => prev + next.taxAmount, 0);
                        md.taxes = txList;
                        md.charges = chgList;
                        md.totalTaxAmount = txAmount;
                        md.totalChargeAmount = chgAmount;

                    });

                    cartList.itemList[index].qty = _qtyVal;

                    sessionStorage.setItem("cartList", JSON.stringify(cartList));
                    sessionStorage.setItem("taxCartList", JSON.stringify(taxList));
                    sessionStorage.setItem("chargeCartList", JSON.stringify(chargeList));
                    sessionStorage.setItem("modifierCartList", JSON.stringify(modifierCartList));

                    CartCalCulation.itemTaxCartCalculation(taxId, rate, _qtyVal, itemId, cartList.itemList[index].uid);
                    CartCalCulation.itemChargeCartCalculation(chargeId, rate, _qtyVal, itemId, cartList.itemList[index].uid);
                    this.handleCartRecalculate();
                }
                else {
                    // tax
                    let exTax = taxList.filter(x => x.uid === cartList.itemList[index].uid);
                    let txidx = -1;
                    Array.prototype.forEach.call(exTax, tx => {
                        txidx = taxList.findIndex(x => x.serialNo === tx.serialNo && x.uid === tx.uid);
                        taxList.splice(txidx, 1);
                    })

                    // charge
                    let exCharge = chargeList.filter(x => x.uid === cartList.itemList[index].uid);
                    let chgidx = -1;
                    Array.prototype.forEach.call(exCharge, chg => {
                        chgidx = chargeList.findIndex(x => x.uid === chg.uid);
                        // //console.log(chgidx);
                        chargeList.splice(chgidx, 1);
                    });

                    // modifier
                    // charge
                    let exmodifierList = modifierCartList.filter(x => x.uid === cartList.itemList[index].uid);
                    let mdidx = -1;
                    Array.prototype.forEach.call(exmodifierList, md => {
                        mdidx = modifierCartList.findIndex(x => x.uid === md.uid);
                        modifierCartList.splice(mdidx, 1);
                    });

                    cartList.itemList.splice(index, 1);
                    sessionStorage.setItem("cartList", JSON.stringify(cartList));
                    sessionStorage.setItem("taxCartList", JSON.stringify(taxList));
                    sessionStorage.setItem("chargeCartList", JSON.stringify(chargeList));
                    sessionStorage.setItem("modifierCartList", JSON.stringify(modifierCartList));
                    this.handleCartRecalculate();

                }
            }
        }
    };

    handleInstrucChange = event => {
        const value = event.target.value;
        this.setState({ orderInstruction: value });
        //  //console.log(value);
    };

    guestTableCheck(typeId) {
        this.setState({ loading: true });

        // Check Loggin Process
        sessionStorage.setItem('main-guest-no', mobileNo);
        mobileNo = sessionStorage.getItem('main-guest-no');

        let postObj = {
            OutletId: propertyId + outletCode,
            GuestMobileCountryCode: "91",
          //  GuestMobileNumber: (mainGuestMobileNo === '' || mainGuestMobileNo === "0000000000") ? mobileNo : mainGuestMobileNo, // added by vijay on 04-11-2023 
            GuestMobileNumber: mobileNo,
            TableNumber: serviceType !== "FINE-DINE" ? '' : tableNo
        }

        //  //console.log(postObj);

        fetch(Config.basePOSUrl + 'GetGuestTableStatus', {
            method: 'POST',
            headers: {
                Authorization: "Bearer " + token,
                'content-type': 'application/json'
            },
            body: JSON.stringify(postObj)
        })
            .then(res => res.json())
            .then(response => {
                if (response.errorCode === 0) {
                    if (!response.response.guestTableStatus.isCheckedIn) {
                        //console.log(response);
                        // Commended  by vijay on 01-02-2024 -Jira id -2078 -Testing Poits -Switch based 
                        // if (olDetail.autoCheckInWhenGuestContinueOrderInSameTable) {
                        //     let postObj = {
                        //         OutletId: propertyId + outletCode,
                        //         GuestName: sessionStorage.getItem("guest-name") === null ? ' ' : sessionStorage.getItem("guest-name"),
                        //         MobileNo: mobileNo,
                        //         TableNumber: tableNo,
                        //         GroupCode: groupPin
                        //     }
                        //     //console.log(postObj)
                        //     fetch(Config.basePOSUrl + 'GuestCheckIn', {
                        //         method: 'POST',
                        //         headers: {
                        //             Authorization: "Bearer " + token,
                        //             'content-type': 'application/json'
                        //         },
                        //         body: JSON.stringify(postObj)
                        //     })
                        //         .then(res => res.json())
                        //         .then(response => {
                        //             if (response.errorCode === 0) {
                        //                 //console.log("response2wwww");
                        //                 //console.log(response);
                        //                 sessionStorage.setItem('main-guest-no', mobileNo);
                        //                 mainGuestMobileNo = sessionStorage.getItem('main-guest-no');
                        //                 // group pin commented by daspraksh on 04-10-2023 jira-1490
                        //                 // sessionStorage.setItem('group-pin', response.response.groupCode);
                        //                 // group pin added by daspraksh on 04-10-2023 jira-1490
                        //                 sessionStorage.setItem('group-pin', response.response !== null ? response.response.groupCode : "");
                        //                 groupPin = sessionStorage.getItem('group-pin');

                        //                 sessionStorage.setItem('main-guest-name', sessionStorage.getItem("guest-name") === null ? ' ' : sessionStorage.getItem("guest-name"));
                        //                 sessionStorage.setItem('group-session', false);

                        //                 sessionStorage.setItem('main-guest-mask-no', '');
                        //                 sessionStorage.setItem('ex-main-guest-no', mobileNo);

                        //                 setTimeout(() => {
                        //                     this.handleValidateOrder(typeId, true);
                        //                 }, 500);
                        //             }
                        //             else {
                        //                 this.showMessage("Your selected table has been checked out.", "danger");
                        //                 setTimeout(() => {
                        //                     this.setState({ loading: false });
                        //                     this.props.history.push('/logout');
                        //                 }, 3000);
                        //             }
                        //         })
                        //         .catch(err => {
                        //             sessionStorage.removeItem("user-token");
                        //             sessionStorage.removeItem("user-mobileno");
                        //             this.showMessage('Guest Check IN  - Failed to fetch - ' + err, "danger");
                        //             //console.log(err)
                        //         });
                        // }                        
                        // else {
                        //     this.showMessage('Your table has been Closed! , Please scan and login once again', "danger");
                        //     this.setState({ loading: true });
                        //     setTimeout(() => {
                        //         this.setState({ loading: false });
                        //         this.props.history.push('/');
                        //     }, 3000);
                        // }

                        // Added by vijay on 01-02-2024 -Jira id -2078 -Testing Poits 
                        if (olDetail.checkInWithTablePin) {
                            sessionStorage.setItem("login-page-session", 3); // Cart Session
                            sessionStorage.setItem("on-click-mode", typeId); // Cart Session
                            setTimeout(() => {
                                this.setState({ loading: false });
                                this.props.history.push('/loginverification' + urlPattern,  // set valid OTP= false due to show only table pin mode in the loginverification                              
                                    { mobileNo: mobileNo, validateOTP: false, validatePIN: olDetail.checkInWithTablePin });
                                // { mobileNo: mobileNo, validatePIN: validatePIN });  // Added by vijay Testing Points
                            }, Config.minMsgTimeout);
                        }

                        else {
                            let postObj = {
                                OutletId: propertyId + outletCode,
                                GuestName: sessionStorage.getItem("guest-name") === null ? ' ' : sessionStorage.getItem("guest-name"),
                                MobileNo: mobileNo,
                                TableNumber: tableNo,
                                GroupCode: groupPin
                            }
                            //  console.log( "GuestCheckIn before",postObj)
                            fetch(Config.basePOSUrl + 'GuestCheckIn', {
                                method: 'POST',
                                headers: {
                                    Authorization: "Bearer " + token,
                                    'content-type': 'application/json'
                                },
                                body: JSON.stringify(postObj)
                            })
                                .then(res => res.json())
                                .then(response => {
                                    console.log("GuestCheckIn API response", response);
                                    if (response.errorCode === 0) {
                                        sessionStorage.setItem('main-guest-no', mobileNo);
                                        mainGuestMobileNo = sessionStorage.getItem('main-guest-no');
                                        // group pin commented by daspraksh on 04-10-2023 jira-1490
                                        // sessionStorage.setItem('group-pin', response.response.groupCode);
                                        // group pin added by daspraksh on 04-10-2023 jira-1490
                                        sessionStorage.setItem('group-pin', response.response !== null ? response.response.groupCode : "");
                                        groupPin = sessionStorage.getItem('group-pin');

                                        sessionStorage.setItem('main-guest-name', sessionStorage.getItem("guest-name") === null ? ' ' : sessionStorage.getItem("guest-name"));
                                        sessionStorage.setItem('group-session', false);

                                        sessionStorage.setItem('main-guest-mask-no', '');
                                        sessionStorage.setItem('ex-main-guest-no', mobileNo);

                                        setTimeout(() => {
                                            this.handleValidateOrder(typeId, true);
                                        }, Config.minMsgTimeout);
                                    }
                                    else {
                                        // added by dasprakash on 21-10-2023 jira-1644
                                        this.setState({ errorMessage: "Your selected table has been checked out." })
                                        //this.showMessage("Your selected table has been checked out.", "danger");
                                        setTimeout(() => {
                                            this.setState({ loading: false });
                                            this.props.history.push('/logout' + urlPattern);
                                        }, Config.minMsgTimeout);
                                    }
                                })
                                .catch(err => {
                                    sessionStorage.removeItem("user-token");
                                    sessionStorage.removeItem("user-mobileno");
                                    // added by dasprakash on 21-10-2023 jira-1644
                                    this.setState({ errorMessage: 'Guest Check IN  - Failed to fetch - ' + err })
                                    //this.showMessage('Guest Check IN  - Failed to fetch - ' + err, "danger");
                                    //console.log(err)
                                });

                        }
                    }



                    else {
                        if (mobileNo !== mainGuestMobileNo) { //if not main guest
                            sessionStorage.setItem('main-guest-mask-no', response.response.guestTableStatus.maskMobileNo);
                            sessionStorage.setItem('main-guest-no', response.response.guestTableStatus.mainGuestMobileNo);
                            sessionStorage.setItem('main-guest-name', response.response.guestTableStatus.mainGuestName);
                            sessionStorage.setItem('ex-main-guest-no', response.response.guestTableStatus.mainGuestMobileNo);
                            mainGuestMobileNo = response.response.guestTableStatus.mainGuestMobileNo;
                        }

                        if (tableNo !== response.response.guestTableStatus.currentTableNumber) {
                            this.showMessage("Your table has been transfer from " + tableNo + " to " + response.response.guestTableStatus.currentTableNumber, "danger");
                            sessionStorage.setItem('user-tableno', response.response.guestTableStatus.currentTableNumber);
                            tableNo = sessionStorage.getItem('user-tableno');
                        }
                        setTimeout(() => {
                            this.handleValidateOrder(typeId, true);
                        }, Config.minMsgTimeout);
                    }
                }
                else {
                    this.setState({ loading: false });
                    this.showMessage(response.message, "danger");

                }
            })
            .catch(err => {
                this.setState({ loading: false });
                this.showMessage(err, "danger");
            });
    }

    handleValidateOrder(typeId, tableCheck) {

        /// user Session Validation        
        if (!sHelpers.userSessionValidation(userSessionId)) {
            this.showMessage("Your session has been expired, please try again", "danger");
            setTimeout(() => {
                window.location.reload(true);
            }, Config.minMsgTimeout);
            return false;
        }


        //console.log("handleValidateOrder")
        let IsAddressChange = false;
        let addressObj = null;
        // Special Instruction Saved 
        sessionStorage.setItem("order-instruction", this.state.orderInstruction !== '' ? this.state.orderInstruction : sessionStorage.getItem('order-instruction') !== null ? sessionStorage.getItem('order-instruction') : '');
        orderChargeList = sessionStorage.getItem("order-charge-List") === null ? [] : JSON.parse(sessionStorage.getItem("order-charge-List"));

        if (guestLoggedIn === "false") {
            this.setState({ loading: true });
            sessionStorage.setItem("login-page-session", 3); // Cart Session
            sessionStorage.setItem("on-click-mode", typeId); // Cart Session
            setTimeout(() => {
                this.setState({ loading: false });
                this.props.history.push('/login' + urlPattern);
            }, Config.minMsgTimeout);
            return false;
        }
        else {
            sessionStorage.removeItem("login-page-session"); // Cart Session
            sessionStorage.removeItem("on-click-mode"); // Cart Session
        }

        // Delivery Mode
        if (serviceType === Config.serviceType.DELIVERY) {

            if (guestCurrentAddress === null) {
                sessionStorage.setItem('user-addressId', 0);
                this.showMessage('Address not found, Please change the valid address', "danger");
                IsAddressChange = true;
            }
            else {
                sessionStorage.setItem('user-addressId', parseInt(guestCurrentAddress.addressId));
                if (guestCurrentAddress.addressLine1.trim() === '') {
                    if (guestCurrentAddress.addressLine2.trim() === '') {
                        if (guestCurrentAddress.addressLine3.trim() === '') {
                            this.showMessage('Address not found, Please change the valid address', "danger");
                            IsAddressChange = true;
                        }
                    }
                }

                if (guestCurrentAddress.zipCode.trim() === '') {
                    this.showMessage('Invalid zipcode, Please change the valid address', "danger");
                    IsAddressChange = true;
                }
                if (guestCurrentAddress.city.trim() === '') {
                    this.showMessage('Invalid city name, Please change the valid address', "danger");
                    IsAddressChange = true;
                }
                if (guestCurrentAddress.area.trim() === '') {
                    this.showMessage('Invalid area name, Please change the valid address', "danger");
                    IsAddressChange = true;
                }
            }

            if (IsAddressChange) {
                this.setState({ loading: true });
                setTimeout(() => {
                    this.setState({ loading: false });
                    this.props.history.push('/chooseaddress' + urlPattern);
                }, Config.minMsgTimeout);
                return false;
            }

            // Get Address 
            addressObj = {
                AddressId: guestCurrentAddress.addressId,
                AddressLine1: guestCurrentAddress.addressLine1,
                AddressLine2: guestCurrentAddress.addressLine2,
                AddressLine3: guestCurrentAddress.addressLine3,
                Area: guestCurrentAddress.area,
                City: guestCurrentAddress.city,
                State: guestCurrentAddress.addressId,
                Landmark: guestCurrentAddress.landmark,
                ZipCode: guestCurrentAddress.zipCode,
                AddressType: guestCurrentAddress.addressType
            }
        }

        // Guest Table Check - CheckIn / Check Out / Table Transfer - to Get latest Table Detail
        if (serviceType === "FINE-DINE" && tableCheck === false) {
            this.guestTableCheck(typeId);
            return false;
        }

        this.setState({ loading: true });
        let postItemList = [];
        Array.prototype.forEach.call(cartList.itemList, itm => {
            let postTaxes = [];
            let postModifiers = [];
            let postCharges = [];
            vailduniquesGroups = [];

            // tax
            let ctax = taxList.filter(x => x.uid === itm.uid);
            ctax.map(tx =>
                postTaxes.push({ TaxCode: tx.taxCode, TaxValueType: tx.taxType, TaxValue: tx.taxValue, TaxAmount: tx.taxAmount })
            )

            //modifier
            let cmodifer = modifierCartList.filter(x => x.uid === itm.uid);
            // //console.log(cmodifer);
            var _taxItmFound = {};
            for (var md = 0, lenc = cmodifer.length; md <= lenc - 1; md++) {
                var stringified = JSON.stringify(cmodifer[md].modifierGroupId);
                if (_taxItmFound[stringified]) { continue; }
                vailduniquesGroups.push({ groupId: cmodifer[md].modifierGroupId, groupName: cmodifer[md].modifierGroupName });
                _taxItmFound[stringified] = true;
            };
            // //console.log(vailduniquesGroups);
            // Modifier
            Array.prototype.forEach.call(vailduniquesGroups, mdg => {
                var modifr = [];
                let mdlist = cmodifer.filter(x => x.modifierGroupId === mdg.groupId);
                Array.prototype.forEach.call(mdlist, md => {
                    let modifiertax = [];
                    let modifierCharge = [];

                    //tax
                    Array.prototype.forEach.call(md.taxes, x => {
                        modifiertax.push({ TaxCode: x.taxCode, TaxValueType: x.taxType, TaxValue: x.taxValue, TaxAmount: x.taxAmount })
                    });

                    //charge
                    Array.prototype.forEach.call(md.charges, chg => {
                        let chargetax = [];

                        //tax
                        Array.prototype.forEach.call(chg.taxes, x => {
                            chargetax.push({ TaxCode: x.taxCode, TaxValueType: x.taxType, TaxValue: x.taxValue, TaxAmount: x.taxAmount })
                        })

                        // IsMerchantCharge is hardcorded 
                        modifierCharge.push({
                            ChargeId: chg.chargeId, ChargeName: chg.chargeName,
                            ChargeType: chg.chargeType, IsMerchantCharge: false, ChargeValueType: chg.chargeValueType,
                            ChargeValue: chg.chargeValue, ChargeAmount: chg.chargeAmount,
                            Taxes: chargetax
                        })
                    });

                    modifr.push({ ModfierId: md.modifierId, ModifierName: md.modifierName, Rate: md.rate, Quantity: itm.qty, Amount: md.rate * itm.qty, Taxes: modifiertax, Charges: modifierCharge })
                })
                postModifiers.push({ ModifierGroupId: mdg.groupId, GroupName: mdg.groupName, Modifiers: modifr });
            });
            // //console.log(postModifiers);

            // charges           
            let ccharge = chargeList.filter(x => x.uid === itm.uid);

            Array.prototype.forEach.call(ccharge, chg => {
                let chargetax = [];

                //tax
                Array.prototype.forEach.call(chg.taxes, x => {
                    chargetax.push({ TaxCode: x.taxCode, TaxValueType: x.taxType, TaxValue: x.taxValue, TaxAmount: x.taxAmount })
                })

                // IsMerchantCharge is hardcorded 
                postCharges.push({
                    ChargeId: chg.chargeId, ChargeName: chg.chargeName,
                    ChargeType: chg.chargeType, IsMerchantCharge: false, ChargeValueType: chg.chargeValueType,
                    ChargeValue: chg.chargeValue, ChargeAmount: chg.chargeAmount,
                    Taxes: chargetax
                })
            });
            // //console.log(postCharges);

            postItemList.push({
                ItemId: itm.itemId,
                ItemName: itm.itemName, Rate: itm.rate,
                Quantity: itm.qty, Amount: (itm.qty * itm.rate),
                Instruction: itm.specInsc === undefined ? "" : itm.specInsc,
                Taxes: postTaxes,
                ModifierGroups: postModifiers,
                Charges: postCharges
            });

        });
        let orderdate = new Date();
        let mseconds = orderdate.getTime() //1440516958
        let deliveryTime = orderdate.getTime() + (olDetail.minimumDeliveryTime * 60000); //1440516958
        // //console.log(mseconds);

        //  this.handleCartRecalculate();

        // paymentDetails.push({
        //     PayMode: "ONLP", // Online Pay 
        //     PaymentAmount: this.state.totalNetAmount.toFixed(2),
        //     TransactionRefId: '',
        //     PaymentVendorId: '', // Vendor Id
        //     PaymentVendorName: ''
        // });
        //this.setState({vendorOrderId: uuid()});
      //  sessionStorage.setItem('user-vendorOrderId', uuid());
        ///  Get QR Location 
        let specialInstu = '';

        if (olDetail.orderInstructionIncludeQRCodeLocation === true) {
            // //console.log(olDetail.orderInstructionIncludeQRCodeLocation);
            specialInstu = sessionStorage.getItem('url-remarks') === null ? '' : sessionStorage.getItem('url-remarks') !== '' ? sessionStorage.getItem('url-remarks') : '';
            // //console.log(specialInstu);
        }

        /// Order Charges
        let orderCharges = [];
        Array.prototype.forEach.call(orderChargeList, ochg => {
            let chargetax = [];
            //tax
            Array.prototype.forEach.call(ochg.taxes, x => {
                chargetax.push({ TaxCode: x.taxCode, TaxValueType: x.taxType, TaxValue: x.taxValue, TaxAmount: x.taxAmount })
            })

            // IsMerchantCharge is hardcorded 
            orderCharges.push({
                ChargeId: ochg.chargeId, ChargeName: ochg.chargeName,
                ChargeType: ochg.chargeType, IsMerchantCharge: false, ChargeValueType: ochg.chargeValueType,
                ChargeValue: ochg.chargeValue, ChargeAmount: ochg.chargeAmount,
                Taxes: chargetax
            });
        });

        // //console.log(orderCharges);
        mainGuestMobileNo = sessionStorage.getItem('main-guest-no');
        let postObj = {
            OutletId: propertyId + outletCode,
            ServiceType: serviceType,
            GuestDetails: {
                // group pin commented by dasprakash on 11-10-2023 jira-1490
                // MobileNo: mainGuestMobileNo,
                // group pin added by dasprakash on 11-10-2023 jira-1490
                // MobileNo: mobileNo,
                //Commended by vijay on Jira id - 06-11-2023
                // MobileNo: mainGuestMobileNo === "" ? mobileNo : mainGuestMobileNo,
                //Added by vijay on 06-11-2023 Jira id -
                MobileNo: (mainGuestMobileNo === '' || mainGuestMobileNo === "0000000000") ? mobileNo : mainGuestMobileNo,

                OrderedGuestMobileNo: mobileNo,
                OrderedGuestName: sessionStorage.getItem("guest-name") === null ? ' ' : sessionStorage.getItem("guest-name"), GuestName: sessionStorage.getItem("main-guest-name") === null ? (sessionStorage.getItem("guest-name") === null ? ' ' : sessionStorage.getItem("guest-name")) : sessionStorage.getItem("main-guest-name"),
                Address: addressObj === null ? {} : addressObj
            },
            OrderDetails: {
                VendorOrderId: sessionStorage.getItem('user-vendorOrderId'),
                OrderDateTime: mseconds,
                DeliveryDateTime: serviceType === Config.serviceType.DELIVERY ? deliveryTime : null,
                TableNumber: serviceType !== "FINE-DINE" ? '' : tableNo,
                OrderStatus: "placed",
                // Instruction: (specialInstu !== '' ? specialInstu + ' - ' : '') + (this.state.orderInstruction === null ? '' : this.state.orderInstruction),
                Instruction: (specialInstu !== '' ? specialInstu : '') + (this.state.orderInstruction === null ? '' : (specialInstu !== '' && this.state.orderInstruction !== '') ? ' - ' + this.state.orderInstruction : this.state.orderInstruction),
                Items: postItemList,
                // TotalItemValue: this.state.totalItemAmount.toFixed(2),
                // TotalItemTaxes: this.state.totaltaxAmount.toFixed(2),
                // TotalItemCharges: this.state.totalChargesAmount.toFixed(2),
                TotalTaxes: this.state.totaltaxAmount.toFixed(2),
                TotalCharges: totalChargesAmount,
                TotalAmount: this.state.totalNetAmount.toFixed(2),
                IsPaid: false,
                Charges: orderCharges
            },
            PaymentDetails: []
        }
       

        //console.log("postObj");
        //console.log(postObj);
        //console.log(JSON.stringify(postObj));
        // //console.log(Config.basePOSUrl + 'ValidateOrder');

        fetch(Config.basePOSUrl + 'ValidateOrder', {
            method: 'POST',
            headers: {
                Authorization: "Bearer " + token,
                'content-type': 'application/json'
            },
            body: JSON.stringify(postObj)
        })
            .then(res => res.json())
            .then(response => {
                // console.log("response34");
                //    console.log(response);
                //   console.log(postObj);
                if (response.errorCode === 0) {
                    if (typeId === 1) // Place Order
                    {
                        fetch(Config.basePOSUrl + 'PlaceOrder', {
                            method: 'POST',
                            headers: {
                                Authorization: "Bearer " + token,
                                'content-type': 'application/json'
                            },
                            body: JSON.stringify(postObj)
                        })
                            .then(res => res.json())
                            .then(response => {
                                //console.log(new Date());

                                //  //console.log(response);
                                if (response.errorCode === 0) {
                                    setTimeout(() => {
                                        this.setState({ loading: true });
                                        sessionStorage.removeItem("paymentInitiatedId");
                                        sessionStorage.removeItem("vendorTransactionId");
                                        sessionStorage.removeItem("vendorId");
                                        sessionStorage.removeItem("vendorName");
                                        sessionStorage.removeItem("paymentStatus");
                                        sessionStorage.removeItem("order-instruction");
                                        sessionStorage.removeItem("user-vendorOrderId");
                                        //console.log(new Date());

                                        this.props.history.push('/ordersuccess' + urlPattern,
                                            {
                                                // orderId: response.response.orderId
                                                orderId: postObj.OrderDetails.VendorOrderId,
                                                orderNo: response.response != null ? response.response.orderNo : ''
                                            });
                                    }, Config.minMsgTimeout);
                                }
                                else {
                                    //  //console.log(response.message);
                                    this.setState({ loading: true });
                                    this.showMessage('Error Order was not placed', "danger");
                                    sessionStorage.removeItem("user-vendorOrderId");
                                }
                            })
                            .catch(err => {
                                //   //console.log(err);
                                this.setState({ loading: true });
                                this.showMessage('Error Order was not placed', "danger");
                                sessionStorage.removeItem("user-vendorOrderId");
                            });
                    }
                    else {
                        setTimeout(() => {
                            this.setState({ loading: true });
                            sessionStorage.setItem("order-instruction", this.state.orderInstruction);
                            this.props.history.push('/payment' + urlPattern, { totalNetAmount: this.state.totalNetAmount, totalItems: this.state.totalItems });
                        }, Config.minMsgTimeout);
                    }
                }
                else if (response.errorCode === 3) {  /// Check in table              
                    if (serviceType === "FINE-DINE") { // Table Check In                       
                        if (olDetail.checkInWithTablePin) {
                            setTimeout(() => {
                                this.setState({ loading: false });
                                this.props.history.push('/tableverification' + urlPattern, { redirectCart: true });
                            }, Config.minMsgTimeout);
                            return false
                        }
                        let postTableObj = {
                            OutletId: propertyId + outletCode,
                            GuestName: sessionStorage.getItem("guest-name") === null ? ' ' : sessionStorage.getItem("guest-name"),
                            MobileNo: mobileNo,
                            TableNumber: tableNo,
                            GroupCode: groupPin
                        }
                        fetch(Config.basePOSUrl + 'GuestCheckIn', {
                            method: 'POST',
                            headers: {
                                Authorization: "Bearer " + token,
                                'content-type': 'application/json'
                            },
                            body: JSON.stringify(postTableObj)
                        })
                            .then(res => res.json())
                            .then(response => {
                                //console.log(postTableObj);
                                if (response.errorCode === 0) {
                                    sessionStorage.setItem('user-table-verified', true);
                                    if (typeId === 1) // Place Order
                                    {
                                        fetch(Config.basePOSUrl + 'PlaceOrder', {
                                            method: 'POST',
                                            headers: {
                                                Authorization: "Bearer " + token,
                                                'content-type': 'application/json'
                                            },
                                            body: JSON.stringify(postObj)
                                        })
                                            .then(res => res.json())
                                            .then(response => {
                                                //console.log(new Date());

                                                if (response.errorCode === 0) {
                                                    setTimeout(() => {
                                                      //  this.setState({ loading: false });
                                                        sessionStorage.removeItem("paymentInitiatedId");
                                                        sessionStorage.removeItem("vendorTransactionId");
                                                        sessionStorage.removeItem("vendorId");
                                                        sessionStorage.removeItem("vendorName");
                                                        sessionStorage.removeItem("paymentStatus");
                                                        sessionStorage.removeItem("order-instruction");
                                                        sessionStorage.removeItem("user-vendorOrderId");
                                                        //console.log(new Date());

                                                        this.props.history.push('/ordersuccess' + urlPattern,
                                                            {
                                                                // orderId: response.response.orderId
                                                                orderId: postObj.OrderDetails.VendorOrderId,
                                                                orderNo: response.response.orderNo
                                                            });
                                                    }, Config.minMsgTimeout);
                                                }
                                                else {
                                                    //  //console.log(response.message);
                                                    this.setState({ loading: true });
                                                    this.showMessage('Error Order was not placed', "danger");
                                                    sessionStorage.removeItem("user-vendorOrderId");
                                                }
                                            })
                                            .catch(err => {
                                                //   //console.log(err);
                                                this.setState({ loading: true });
                                                this.showMessage('Error Order was not placed', "danger");
                                                sessionStorage.removeItem("user-vendorOrderId");
                                            });
                                    }
                                    else {
                                        setTimeout(() => {
                                           // this.setState({ loading: false });
                                            sessionStorage.setItem("order-instruction", this.state.orderInstruction);
                                            this.props.history.push('/payment' + urlPattern, { totalNetAmount: this.state.totalNetAmount, totalItems: this.state.totalItems });
                                        }, Config.minMsgTimeout);
                                    }
                                }
                                else {
                                    this.showMessage(response.message, "danger");
                                    this.setState({ loading: true });
                                    setTimeout(() => {
                                        this.setState({ loading: false });
                                        this.props.history.push('/qrcoderror' + urlPattern);
                                    }, Config.minMsgTimeout);
                                }
                            })
                            .catch(err => {
                                this.showMessage('Guest Check IN  - Failed to fetch - ' + err, "danger");
                            });

                    } // End Service Type - Fine Dine
                    else {
                        sessionStorage.setItem('user-table-verified', true);
                        setTimeout(() => {
                            this.setState({ loading: false });
                            this.props.history.push('/home' + urlPattern);
                        }, Config.minMsgTimeout);
                    }
                }
                else if (response.errorCode === 4) { // Addded by vijay - duplicate Order handled on 11-10-2024 - jira id -3924
					this.setState({ loading: true });
					setTimeout(() => {
                        sessionStorage.removeItem("paymentInitiatedId");
                        sessionStorage.removeItem("vendorTransactionId");
                        sessionStorage.removeItem("vendorId");
                        sessionStorage.removeItem("vendorName");
                        sessionStorage.removeItem("paymentStatus");
                        sessionStorage.removeItem("order-instruction");
                        sessionStorage.removeItem("user-vendorOrderId");
                        //console.log(new Date());

                        this.props.history.push(
                            "/ordersuccess" + urlPattern,
                            {
                                orderId: postObj.OrderDetails.VendorOrderId,
                                orderNo: response.response.orderNo,
                            }
                        );
                    }, 200);
				}
                else {
                    //console.log("response.message");
                    //console.log(response.message);
                    this.setState({ loading: false });
                    this.showMessage(response.message, "danger");

                }
            })
            .catch(err => {
                //console.log(err.stack);
                this.setState({ loading: false });
                this.showMessage(err.message, "danger");
            }); // End Validate Order

    };

    handleSpecialInstruction = (itmName, uid, specInsc) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='special-insc'>
                        <h5>Special Instruction</h5>
                        <span className="itm-name">{itmName}</span>
                        <textarea id={"spec-insc-" + uid} rows="2" cols="30" maxLength="100" placeholder="write here.." autoFocus>{specInsc}</textarea>
                        <button onClick={onClose} className="btn-left">Cancel</button>
                        <button
                            onClick={() => {
                                this.handleUpdateSpecialInstruction(uid, false);
                                onClose();
                            }}
                            className="btn-right">
                            Done
                        </button>
                    </div>
                );
            }
        });
    };

    handleUpdateSpecialInstruction(uid, remove) {
        let index = cartList.itemList.findIndex(x => x.uid === uid);
        let specInscVal = '';
        if (index > -1) {
            if (!remove) {
                specInscVal = document.getElementById('spec-insc-' + uid).value;
                if (cartList.itemList[index].specInsc === undefined)
                    cartList.itemList.map(x => Object.assign({}, cartList.itemList, { "specInsc": specInscVal }));
                else
                    cartList.itemList[index].specInsc = specInscVal;
            }

            cartList.itemList[index].specInsc = specInscVal;
            this.setState({ cartItemList: cartList.itemList });
            sessionStorage.setItem("cartList", JSON.stringify(cartList));
        }

    };

    gotoAddressChoose(addressId) {
        // Special Instruction Saved 
        sessionStorage.setItem("order-instruction", this.state.orderInstruction !== '' ? this.state.orderInstruction : sessionStorage.getItem('order-instruction') !== null ? sessionStorage.getItem('order-instruction') : '');

        this.setState({ loading: true });
        setTimeout(() => {
            this.setState({ loading: false });
            sessionStorage.setItem('user-addressId', addressId)
            this.props.history.push('/chooseaddress' + urlPattern);
        }, Config.minMsgTimeout);
    };

    handleChooseAddrss() {
        // Special Instruction Saved 
        sessionStorage.setItem("order-instruction", this.state.orderInstruction !== '' ? this.state.orderInstruction : sessionStorage.getItem('order-instruction') !== null ? sessionStorage.getItem('order-instruction') : '');

        if (guestLoggedIn === "false") {
            this.setState({ loading: true });
            sessionStorage.setItem("login-page-session", 9); // Address Session            
            setTimeout(() => {
                this.setState({ loading: false });
                this.props.history.push('/login' + urlPattern);
            }, Config.minMsgTimeout);
            return false;
        }
        this.gotoAddressChoose(0);
    };

    showMessage(msg, type) {

        window.scrollTo(0, 0);
        this.setState({ msgShow: true });
        this.setState({ msgType: type });
        this.setState({ alertMessage: msg });
        this.setState({ loading: false });
        setTimeout(() => {
            this.setState({ msgShow: false });
        }, Config.minMsgTimeout);
    };

    // componentDidCatch(error, info) {
    //     // Display fallback UI
    //     this.setState({ hasError: true });
    //     this.showMessage(error, "danger");
    //     //console.log(error)
    //     // You can also log the error to an error reporting service
    //     //logErrorToMyService(error, info);
    // }

    render() {

        // totaltaxAmount = this.state.cartItemList.length > 0 ? taxList.reduce((prev, next) => prev + next.taxAmount, 0) : 0;
        // totaltaxAmount += this.state.cartItemList.length > 0 ? chargeList.reduce((prev, next) => prev + next.totalTaxAmount, 0) : 0;
        // totaltaxAmount += this.state.cartItemList.length > 0 ? modifierCartList.reduce((prev, next) => prev + next.totalTaxAmount, 0) : 0;
        // totalChargesAmount = this.state.cartItemList.length > 0 ? chargeList.reduce((prev, next) => prev + next.chargeAmount, 0) : 0;
        // totalChargesAmount += this.state.cartItemList.length > 0 ? modifierCartList.reduce((prev, next) => prev + next.totalChargeAmount, 0) : 0;
        // totalAmount = totalItemAmount + totaltaxAmount + totalChargesAmount;

        return (

            <div>
                {this.state.loading && <LoadingAnimation />}
                <div id="content" className="top">
                    {this.state.msgShow && <ErrorAlert alertMsg={this.state.alertMessage} msgType={this.state.msgType} msgShow={this.state.msgShow} />}
                    <div className="content-warp">
                        <div className="section-cart">
                            <div className="heading-page" ref="recommended">
                                Checkout
                                <div className="back-btn">
                                    <div className="icon-back" onClick={this.goBack}><i className="fa fa-angle-left" ></i></div>
                                </div>
                            </div>
                            <div className="content-from">
                                <div className="itm-heading">{sessionStorage.getItem("user-propertyname")}
                                    <span className="city-txt">{sessionStorage.getItem("user-outletname")} - {sessionStorage.getItem("user-servicetype") === "FINE-DINE" ? 'Table Service' : sessionStorage.getItem("user-servicetype")}</span>
                                </div>
                                {serviceType === Config.serviceType.DELIVERY &&
                                    <>
                                        {(guestLoggedIn === "true" && guestCurrentAddress !== null) &&
                                            <div className="content delivery">
                                                <div className="addr-heading">Delivery To <span className="addr-type">{guestCurrentAddress.addressType}</span></div>
                                                <button className="theme-button btn change-btn" onClick={this.gotoAddressChoose.bind(this, guestCurrentAddress.addressId)}>Change</button>
                                                <div className="address">
                                                    {guestName === '' && guestDetails.guestName}
                                                    {guestName !== '' && guestName}
                                                    <br />
                                                    {guestCurrentAddress.addressLine1} {guestCurrentAddress.addressLine2} {guestCurrentAddress.addressLine3}
                                                    <br />
                                                    {guestCurrentAddress.area}
                                                    <br />
                                                    {guestCurrentAddress.city} - {guestCurrentAddress.zipCode}<br />
                                                    {guestCurrentAddress.landmark.trim() !== '' && 'Landmark: ' + guestCurrentAddress.landmark}
                                                </div>
                                            </div>
                                        }
                                        {(guestLoggedIn === "false") &&
                                            <div className="content delivery">
                                                <div className="not-login-msg">
                                                    We couldn't find your address. Please login and continue. <span className="login" onClick={this.handleChooseAddrss.bind(this)}>Login</span>
                                                    {/* <button className="theme-button btn choose-btn" onClick={this.handleChooseAddrss.bind(this)}>Login</button> */}
                                                </div>

                                            </div>
                                        }
                                        {(guestLoggedIn === "true" && guestCurrentAddress === null) &&
                                            <div className="content delivery">
                                                <div className="not-login-msg">
                                                    <span className="login" onClick={this.handleChooseAddrss.bind(this)}>Choose Address</span>
                                                    {/* <button className="theme-button btn choose-btn" onClick={this.handleChooseAddrss.bind(this)}>Login</button> */}
                                                </div>

                                            </div>
                                        }
                                    </>
                                }

                                <div className="wrap-list-rc regular-item" id="item-list">
                                    {
                                        this.state.cartItemList.map(itm =>

                                            <div className="lrc-item" key={"mnu-cart-" + itm.groupId + "-" + itm.itemId + "-" + itm.uid}>
                                                <div className="lrc-content">
                                                    <div className="lrc-desc">
                                                        {/* <span className="itm-delete" onClick={this.handleDetletItem.bind(this,itm.id,itm.rate,itm.qty)}><i className="fas fa-trash-alt"></i></span> */}
                                                        <span className="price-detail">₹{itm.rate.toFixed(2)}</span>
                                                        <div className="qty-detail">
                                                            <div className="">
                                                                <span className="minus" onClick={this.handleQtyIncDec.bind(this, itm.id, 0, itm.rate, itm.itemName, itm.itemId, itm.groupId, itm.foodType, itm.isModifier, itm.uid, itm.taxId, itm.chargeIds)}><i className="fa fa-minus" aria-hidden="true"></i></span>
                                                                {/* {itm.isModifier === 1 && <span className="minus" onClick={this.handleQtyIncDec.bind(this, itm.id, 0, itm.rate, itm.itemName, itm.itemId, itm.groupId, itm.foodType, itm.isModifier, itm.uid)}><i className="fa fa-minus" aria-hidden="true"></i></span>} */}
                                                                <span className="qty" id={itm.uid}>{itm.qty}</span>
                                                                <span className="plus" onClick={this.handleQtyIncDec.bind(this, itm.id, 1, itm.rate, itm.itemName, itm.itemId, itm.groupId, itm.foodType, itm.isModifier, itm.uid, itm.taxId, itm.chargeIds)}><i className="fa fa-plus" aria-hidden="true"></i></span>
                                                            </div>
                                                            <div>
                                                                {/* <span style={{ color: "#28a745", fontSize: "14px", fontWeight: "600" }}>ADD</span> */}
                                                            </div>
                                                        </div>
                                                        <div className="lrc-title">
                                                            {itm.foodType === 1 && <img className="food-type-img" src={"/img/f-t-veg.png"} alt="item" />}
                                                            {itm.foodType === 2 && <img className="food-type-img" src={"/img/f-t-nonveg.png"} alt="item" />}
                                                            {itm.itemName.toLowerCase()}
                                                        </div>

                                                        {/* <div className="qty-detail">
                                                        <span className="minus"><i className="fa fa-minus" aria-hidden="true"></i></span> <span className="qty">10</span> <span className="plus"><i className="fa fa-plus" aria-hidden="true"></i></span>
                                                    </div> */}

                                                        {/* <div className="itm-modifier">
                                                        <span>Dark Chocolate Chunk</span>
                                                        </div> */}
                                                    </div>
                                                </div>
                                                <div key={"mnu-cart-modifier-" + itm.groupId + "-" + itm.itemId + "-" + itm.uid}>
                                                    {itm.isModifier === 1 &&
                                                        this.state.modifierItemList.filter(x => x.uid === itm.uid).map((mitm, idx) =>
                                                            <div key={"md-item-" + mitm.modifierId + "-" + itm.itemId + "-" + itm.uid + "-" + idx}>
                                                                <div className="lrc-item modifier-item" key={"mnu-cart-modifier-" + mitm.modifierId + "-" + itm.itemId + "-" + itm.uid}>
                                                                    <div className="lrc-content">
                                                                        <div className="lrc-desc">
                                                                            <span className="price-detail modifier-price">₹{mitm.rate.toFixed(2)}</span>

                                                                            <div className="lrc-title">
                                                                                {mitm.foodType === 1 && <img className="food-type-img" src={"/img/f-t-veg.png"} alt="item" />}
                                                                                {mitm.foodType === 2 && <img className="food-type-img" src={"/img/f-t-nonveg.png"} alt="item" />}
                                                                                {mitm.modifierName}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                    {(olDetail.enableSpecialInstruction) &&
                                                        <div>
                                                            {(itm.specInsc === undefined || itm.specInsc === "") &&
                                                                <div className="spcecial-instc" id={"add-insc-" + itm.uid} onClick={this.handleSpecialInstruction.bind(this, itm.itemName.toLowerCase(), itm.uid, "")}>Add Special Instruction</div>
                                                            }
                                                            {(itm.specInsc !== "" && itm.specInsc !== undefined) &&
                                                                <div className="special-insc-main">  <div className="special-insc-desc"> <span onClick={this.handleSpecialInstruction.bind(this, itm.itemName.toLowerCase(), itm.uid, itm.specInsc)}>{itm.specInsc}</span> <div> <input id="close-special-insc" type="button" value="X" onClick={this.handleUpdateSpecialInstruction.bind(this, itm.uid, true)} /></div></div></div>
                                                            }
                                                        </div>
                                                    }
                                                </div>

                                            </div>


                                        )
                                    }
                                </div>
                                {(olDetail.enableSpecialInstruction) &&
                                    <div className="spec-info">
                                        <input type="text" onChange={(e) => this.handleInstrucChange(e)} className="instc" id="input-bill-instruction" placeholder="Write Suggestions to Restaurant?" autoComplete="off" value={this.state.orderInstruction} />
                                    </div>
                                }
                                <div className="bill-details">
                                    <div className="heading">Bill Details</div>
                                    <div className="content"><span>Item Total</span><span className="price">₹{this.state.totalItemAmount.toFixed(2)}</span></div>
                                    {/* <div className="content"><span>Charges </span><i className="fa fa-info-circle" aria-hidden="true"></i><span className="price">₹27.00</span></div> */}
                                    {/* Charges condtion Added by vijay on 04-02-2024 JIra id -2078 -Testing Points */}
                                    {this.state.totalChargesAmount !== 0 ? (
                                        <div className="content"><span>Charges </span><span className="price">₹{this.state.totalChargesAmount.toFixed(2)}</span></div>
                                    ) : null}
                                    <div className="content tax"><span>Tax</span><span className="price">₹{this.state.totaltaxAmount.toFixed(2)}</span>
                                        {this.state.roundOff !== 0 && <div className="content" style={{ lineHeight: "26px" }}><span>RoundOff </span><span className="price">₹{this.state.roundOff.toFixed(2)}</span></div>}
                                    </div>



                                    <div className="content total"><span>To Pay</span><span className="price">₹{this.state.totalNetAmount.toFixed(2)}</span></div>

                                </div>

                                {/* <div className="pay-div" onClick={this.goPayments}>
                                    <div className="c-right">
                                        <span>
                                            <img className="rupee" src="../assets/theme1/img/rupee.png" /></span>
                                           Pay & Place Order
                                        </div>
                                </div> */}

                                <div className="pay-div">
                                    {orderCheckoutType === enmConfig.orderCheckOutType.placeOrder &&

                                        <div onClick={this.handleValidateOrder.bind(this, 1, false)}>
                                            <span>
                                                <img className="rupee" src="/img/place-order.png" alt="lucid-place-order" /></span>
                                            Place Order
                                        </div>
                                    }
                                    {orderCheckoutType === enmConfig.orderCheckOutType.paynPlaceOrder &&

                                        <div onClick={this.handleValidateOrder.bind(this, 2, false)}>
                                            <span>
                                                <img className="rupee" src="/img/rupee.png" alt="lucid-rupee" /></span>
                                            Pay & Place Order
                                        </div>
                                    }

                                    {orderCheckoutType === enmConfig.orderCheckOutType.both &&
                                        <div>
                                            <div className="c-left" onClick={this.handleValidateOrder.bind(this, 1, false)}>
                                                <span>
                                                    <img className="rupee" src="/img/place-order.png" alt="lucid-place-order" /></span>
                                                Place Order
                                            </div>

                                            <div className="c-right" onClick={this.handleValidateOrder.bind(this, 2, false)}>
                                                <span>
                                                    <img className="rupee" src="/img/rupee.png" alt="lucid-rupee" /></span>

                                                Pay & Place Order
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    };
}